export const NonWorkEmailProviders: string[] = [
    'afghan.biz',
    'afghan.co.uk',
    'afghan.de',
    'afghan.info',
    'afghan.net',
    'afghan.org',
    'afghan.us',
    'afghanistan.com',
    'albania.com',
    'albanianworld.net',
    'albaniaonline.net',
    'albmail.com',
    'albnet.net',
    'albweb.com',
    'algeria.biz',
    'algeria.com',
    'algeria.net',
    'algeria.org',
    'algeria.us',
    'algeriaonline.net',
    'algerie.com',
    'dz.com',
    'angolamail.com',
    'angolaweb.com',
    'arnet.com.ar',
    'ciudad.com.ar',
    'fibertel.com.ar',
    'sinectis.com.ar',
    'speedy.com.ar',
    'telecentro.net.ar',
    'terra.com.ar',
    'list.am',
    'mail.am',
    'mail.beeline.am',
    'mail.itex.am',
    'mail.netsys.am',
    'mail.ucom.am',
    'aapt.net.au',
    'bigpond.com',
    'iinet.net.au',
    'internode.on.net',
    'netspace.net.au',
    'ozemail.com.au',
    'telstra.com',
    'tpg.com.au',
    'westnet.com.au',
    'fastmail.com',
    'aon.at',
    'chello.at',
    'gmx.at',
    'inode.at',
    'kabsi.at',
    'mur.at',
    'vol.at',
    'wien.at',
    'azdata.az',
    'azerbaijan.az',
    'azeronline.az',
    'baknet.net',
    'day.az',
    'mail.az',
    'bahamas.bs',
    'bahamas.com',
    'bahamas.net',
    'bahamas.net.bs',
    'bahamaslocal.com',
    'bahamaspages.com',
    'bahrain.bh',
    'bahrain.com',
    'bahrain.net',
    'bahrainonline.net',
    'bahraintelecom.com',
    'bh.net',
    'bd.biz',
    'bd.co',
    'bd.co.uk',
    'bd.org',
    'bdmail.com',
    'gazeta.by',
    'mail.by',
    'tut.ru',
    'yandex.by',
    'belgacom.be',
    'orange.be',
    'proximus.be',
    'skynet.be',
    'telenet.be',
    'uclouvain.be',
    'ugent.be',
    'ulb.ac.be',
    'voo.be',
    'vub.ac.be',
    'mail.be',
    'outlook.be',
    'pandora.be',
    'scarlet.be',
    'belize.com',
    'belize.net',
    'belize.org',
    'belizecity.com',
    'belizecity.net',
    'belizecity.org',
    'belizehost.com',
    'belizeit.com',
    'benin.biz',
    'benin.com',
    'benin.me',
    'benin.name',
    'benin.pro',
    'benin.tv',
    'bhutanpost.bt',
    'druknet.bt',
    'bolivia.com',
    'entelnet.bo',
    'tigo.com.bo',
    'unitel.bo',
    'viva.bo',
    'bhtelecom.ba',
    'bih.net.ba',
    'botsnet.bw',
    'info.bw',
    'bol.com.br',
    'globo.com',
    'ig.com.br',
    'terra.com.br',
    'uol.com.br',
    'yahoo.com.br',
    'bn.net',
    'brunei-online.com',
    'brunei.biz',
    'brunei.info',
    'brunei.me',
    'brunet.bn',
    'abv.bg',
    'bg-mail.com',
    'bg-net.net',
    'bgnet.bg',
    'dir.bg',
    'gbg.bg',
    'mail.bg',
    'burkina.net',
    'burundi.bi',
    'yahoo.bi',
    'abidjan.net',
    'africamail.net',
    'africamail.org',
    'ivoiremail.ci',
    'laposte.ci',
    'mail.ci',
    'orange.ci',
    'cabonet.cv',
    'cv.pt',
    'sapo.cv',
    'cambodia.biz',
    'cambodia.com',
    'cambodia.name',
    'cambodia.net',
    'cambodia.org',
    'camnet.com.kh',
    'khmeronline.com',
    'camnet.cm',
    'camtel.cm',
    'cnps.cm',
    'yacast.net',
    'bell.net',
    'cogeco.ca',
    'mymts.net',
    'rogers.com',
    'shaw.ca',
    'sympatico.ca',
    'telus.net',
    'fido.ca',
    'videotron.ca',
    'cafonline.org',
    'caribsurf.com',
    'tdnet.com',
    'adinet.com.uy',
    '123.cl',
    'cox.cl',
    'gtdinternet.com',
    'intercom.cl',
    'terra.cl',
    'vtr.cl',
    '126.com',
    '163.com',
    '189.cn',
    '21cn.com',
    'qq.com',
    'sina.com',
    'sohu.com',
    'tom.com',
    'yeah.net',
    'foxmail.com',
    'colombia.co',
    'colombia.com',
    'colombia.es',
    'colombia.me',
    'colombia.net',
    'colombia.org',
    'colombia.tv',
    'colombiamail.com',
    'congo.cd',
    'congolink.com',
    'itcr.ac.cr',
    'racsa.co.cr',
    'ucenfotec.ac.cr',
    'ucimed.ac.cr',
    'ucr.ac.cr',
    'ulacit.ac.cr',
    'una.ac.cr',
    'iskon.hr',
    'mail.hr',
    'net.hr',
    't-com.hr',
    'vip.hr',
    'cubava.cu',
    'infomed.sld.cu',
    'ucab.edu.ve',
    'uci.cu',
    'ula.ve',
    'cnet.com.cy',
    'cwcyprus.com',
    'cyta.com.cy',
    'cytanet.com.cy',
    'primetel.com.cy',
    'atlas.cz',
    'azet.cz',
    'centrum.cz',
    'email.cz',
    'post.cz',
    'seznam.cz',
    'tiscali.cz',
    'volny.cz',
    'dk-hostmaster.dk',
    'get2net.dk',
    'jubii.dk',
    'mail.dk',
    'ofir.dk',
    'post.tele.dk',
    'priv.dk',
    'sol.dk',
    'webmail.dk',
    'youmail.dk',
    'gmail.dk',
    'hotmail.dk',
    'orange.dj',
    'yahoo.dj',
    'claro.com.do',
    'codetel.net.do',
    'one.com.do',
    'tricom.net',
    'verizon.net.do',
    'viva.com.do',
    'windtelecom.com.do',
    'congopage.com',
    'voila.cd',
    'ecuadormail.com',
    'ecuadortimes.net',
    'ecuamail.com',
    'ecuamail.net',
    'elcable.net',
    'eluniverso.com',
    'expreso.ec',
    'egmail.com',
    'egyptmail.com',
    'egyptmail.net',
    'elsalvador.com',
    'elsalvador.gob.sv',
    'salvador.com',
    'svnet.org',
    'eg.com',
    'equatorialguinea.com',
    'gq.com',
    'gu.com',
    'guineeequatoriale.com',
    'eritel.net',
    'eritrea.co.uk',
    'eritrea.co.za',
    'eritrea.com',
    'eritrea.info',
    'eritrea.net',
    'eritrea.org',
    'edu.ee',
    'ee.ee',
    'eesti.ee',
    'neti.ee',
    'online.ee',
    'postimees.ee',
    'riigikantselei.ee',
    'zen.ee',
    'mail.ee',
    'eswatini.info',
    'eswatini.org',
    'eswatini.ws',
    'ethionet.et',
    'etnet.et',
    'connect.com.fj',
    'fijimail.com',
    'fj.mail.outlook.com',
    'aalto.fi',
    'aland.net',
    'helsinki.fi',
    'jyu.fi',
    'kolumbus.fi',
    'lut.fi',
    'oulu.fi',
    'suomi24.fi',
    'tampere.fi',
    'turku.fi',
    'gmail.fi',
    'luukku.com',
    'saunalahti.fi',
    'edu.hel.fi',
    'luukku.fi',
    'netti.fi',
    'kotinet.com',
    'wippies.com',
    'aliceadsl.fr',
    'free.fr',
    'laposte.net',
    'numericable.fr',
    'orange.fr',
    'sfr.fr',
    'wanadoo.fr',
    'neuf.fr',
    'aol.fr',
    'hotmail.fr',
    'voila.fr',
    'gabononline.ga',
    'gm.biz',
    'gm.co.uk',
    'gm.co.za',
    'gm.com',
    'gm.org',
    'qanet.gm',
    'gldani.com',
    'gldani.info',
    'mail.ge',
    'web.de',
    '1und1.de',
    'arcor.de',
    'freenet.de',
    'gmx.de',
    'mail.de',
    'outlook.de',
    'posteo.de',
    't-online.de',
    'gmx.com',
    'gmx.net',
    'hotmail.de',
    'mailbox.org',
    'ghanamail.com',
    'freemail.gr',
    'greekmail.com',
    'in.gr',
    'mail.gr',
    'mail.ntua.gr',
    'mail.uoa.gr',
    'otenet.gr',
    'ovh.net',
    'gandi.net',
    'netcourrier.com',
    'gt.biz',
    'gt.com',
    'gt.edu',
    'gt.net',
    'gt.org',
    'gt.pl',
    'guatemala.com',
    'guinee.net',
    'guinee.org',
    'bissau.com',
    'gb.net',
    'gb.org',
    'guineabissau.com',
    'guineabissau.info',
    'guineabissau.net',
    'guineabissau.org',
    'guyanamail.com',
    'haiti-liberte.com',
    'haiti-reference.com',
    'honduras.com',
    'honduras.hn',
    'hondutel.hn',
    'laprensa.hn',
    'latribuna.hn',
    'hkbn.net',
    'hknet.com',
    'hkstar.com',
    'netvigator.com',
    'citromail.hu',
    'freemail.hu',
    'indamail.hu',
    'invitel.hu',
    'mailbox.hu',
    't-online.hu',
    'vipmail.hu',
    '365.is',
    'islandia.is',
    'lhi.is',
    'nes.is',
    'ruv.is',
    'simnet.is',
    'tt.is',
    'vodafone.is',
    'sify.com',
    'aol.in',
    'indiatimes.com',
    'live.in',
    'yahoo.co.in',
    'zoho.com',
    'ymail.com',
    'biznetnetworks.com',
    'plasa.com',
    'telkom.net.id',
    'telkomsel.com',
    'yahoo.co.id',
    'rediffmail.com',
    'gmail.com',
    'hotmail.com',
    'mail.com',
    'outlook.com',
    'asia.com',
    'email.com',
    'hush.com',
    'hushmail.com',
    'ovi.com',
    'protonmail.com',
    'tutanota.com',
    'tutanota.de',
    'yopmail.com',
    'iranmail.com',
    'iranmail.net',
    'mail.ir',
    'post.ir',
    'mail.uobabylon.edu.iq',
    'mail.uokufa.edu.iq',
    'blueyonder.co.uk',
    'eircom.net',
    'esatclear.ie',
    'iol.ie',
    'vodafone.ie',
    '011.net.il',
    '012.net.il',
    '013.net',
    '014.net.il',
    'barak.net.il',
    'bezeqint.net',
    'netvision.net.il',
    'walla.co.il',
    'alice.it',
    'aruba.it',
    'email.it',
    'libero.it',
    'pec.it',
    'poste.it',
    'tin.it',
    'tiscali.it',
    'virgilio.it',
    'hotmail.it',
    'terra.it',
    'tim.it',
    'vodafone.it',
    'jamaicagleaner.com',
    'jamaicamail.com',
    'jamaicantimes.com',
    'jamaicapost.gov.jm',
    'jamaicatradeandinvest.org',
    'aol.jp',
    'biglobe.ne.jp',
    'docomo.ne.jp',
    'ezweb.ne.jp',
    'inter7.jp',
    'nifty.com',
    'so-net.ne.jp',
    'hotmail.co.jp',
    'jo.biz',
    'jo.co',
    'jo.com',
    'jo.me',
    'jo.org',
    'joemail.com',
    'mail.kz',
    'mail.kz.net',
    'mail15.com',
    'yandex.kz',
    'ke.co.ke',
    'kenya-airways.com',
    'kenya-today.co.ke',
    'kenyaweb.com',
    'kplc.co.ke',
    'orange.co.ke',
    'safaricom.co.ke',
    'telkom.co.ke',
    'kw.biz',
    'kw.co',
    'kw.com',
    'kw.net',
    'kw.org',
    'kw.tv',
    'kwmail.com',
    'mail.kg',
    'lao.co',
    'lao.com',
    'lao.info',
    'lao.me',
    'lao.net',
    'lao.org',
    'lao.tv',
    'laos.net',
    'apollo.lv',
    'balticom.lv',
    'draugiem.lv',
    'inbox.lv',
    'latnet.lv',
    'latvija.lv',
    'lv.lv',
    'mail.lv',
    'microsoft.lv',
    'lb.com',
    'leb.org',
    'lebanon.com',
    'lebanon.net',
    'lebanon.org',
    'lebanonmail.com',
    'lebmail.com',
    'lesotho.co.ls',
    'lesotho.net',
    'lesotho.org',
    'liberia.com',
    'liberian.com',
    'liberian.net',
    'liberian.org',
    'liberiaonline.net',
    'libyamail.com',
    'balticum.lt',
    'inbox.lt',
    'lt.tc',
    'mail.lt',
    'lu.biz',
    'lu.com',
    'lu.net',
    'lu.org',
    'luxtrust.com',
    'luxtrust.lu',
    'pt.lu',
    'vdl.lu',
    'macau.com',
    'macau.ctm.net',
    'macau.org',
    'umac.mo',
    'gov.mg',
    'malawi.biz',
    'malawi.co.uk',
    'malawi.co.za',
    'malawi.com',
    'malawi.net',
    'malawi.org',
    'myjaring.com',
    'mykad.com.my',
    'maldives.com',
    'maldives.com.mv',
    'maldives.net',
    'maldives.net.mv',
    'mv.net',
    'mali-online.net',
    'maliweb.ml',
    'orange.ml',
    'di-ve.com',
    'go.com.mt',
    'maltanet.net',
    'martinique.com',
    'martinique.fr',
    'martinique.org',
    'orange.mq',
    'intnet.mu',
    'mauritius.com',
    'mauritius.net',
    'mauritius.org',
    'itam.mx',
    'live.com.mx',
    'msn.com.mx',
    'outlook.com.mx',
    'prodigy.net.mx',
    'telmex.net.mx',
    'terra.com.mx',
    'unam.mx',
    'mail.fm',
    'micronesia.fm',
    'ws.net',
    'yap.fm',
    'md.biz',
    'md.co.uk',
    'md.net',
    'md.org',
    'md.us.com',
    'mongol.info',
    'mongol.net',
    'mongolpost.mn',
    'me.net',
    'mt.com',
    'mt.net',
    'mt.org',
    'yu.com',
    'yu.net',
    'yu.org',
    'iam.ma',
    'inwi.ma',
    'maktoob.com',
    'maroc.ma',
    'meditel.ma',
    'menara.ma',
    'orange.ma',
    'topnet.ma',
    'wana.ma',
    'tvcabo.co.mz',
    'uem.mz',
    'myanmar.co',
    'myanmar.com',
    'myanmar.me',
    'myanmar.net',
    'myanmar.org',
    'myanmar.us',
    'namibian.com',
    'namibian.com.na',
    'namibian.info',
    'namibian.net',
    'namibian.org',
    'namibweb.com',
    'webmail.iway.na',
    'info.com.np',
    'mail.com.np',
    'mail.mos.com.np',
    'mos.com.np',
    'nepal.com',
    'wlink.com.np',
    'home.nl',
    'kpnmail.nl',
    'live.nl',
    'planet.nl',
    'quicknet.nl',
    'telfort.nl',
    'vodafone.nl',
    'xs4all.nl',
    'ziggo.nl',
    'orange.nl',
    'xtra.co.nz',
    '2degreesmobile.co.nz',
    'actrix.co.nz',
    'clear.net.nz',
    'orcon.net.nz',
    'paradise.net.nz',
    'slingshot.co.nz',
    'vodafone.co.nz',
    'wave.co.nz',
    'nic.ni',
    'unanleon.edu.ni',
    'mail.ng',
    'naij.com',
    'yandex.com',
    'koreadmz.com',
    'koreamail.com',
    'macedonia.com.mk',
    'mail.mk',
    'mt.com.mk',
    'on.net.mk',
    't-home.mk',
    'vip.mk',
    'broadpark.no',
    'epost.no',
    'getmail.no',
    'inbox.no',
    'nextgentel.com',
    'online.no',
    'post.no',
    'posten.no',
    'webmail.no',
    'runbox.com',
    'start.no',
    'telenor.no',
    'hotmail.no',
    'omanair.om',
    'omanmobile.net',
    'omanmobile.om',
    'omanpost.om',
    'yahoo.com.pk',
    'cableonda.net',
    'cwp.net.pa',
    'png.com',
    'png.net',
    'png.org',
    'paraguay.com',
    'paraguay.gov.py',
    'paraguay.net',
    'paraguay.org',
    'inbox.com.pe',
    'mail.pe',
    'terra.com.pe',
    'mail2world.com',
    'mymail.ph',
    'outlook.ph',
    'ph.mg4.mail.yahoo.com',
    'ph.mg5.mail.yahoo.com',
    'ph.mg6.mail.yahoo.com',
    'ph.yahoo.com',
    'gazeta.pl',
    'home.pl',
    'interia.pl',
    'o2.pl',
    'poczta.fm',
    'poczta.onet.pl',
    'poczta.pl',
    'tlen.pl',
    'wp.pl',
    'interia.eu',
    'onet.pl',
    'mail.cm-santarem.pt',
    'mail.cm-setubal.pt',
    'mail.pt',
    'mail.telepac.pt',
    'netcabo.pt',
    'olx.pt',
    'pt.vu',
    'sapo.pt',
    'borinquen.com',
    'coqui.net',
    'prcc.net',
    'prtc.net',
    'prtel.com',
    'prwifi.com',
    'qatar.biz',
    'qatar.co',
    'qatar.im',
    'qatar.me',
    'qatar.net',
    'qatar.org',
    'qatar.ws',
    'club-internet.fr',
    'mail.ro',
    'mail.yahoo.com',
    'rdsnet.ro',
    'romania.ro',
    'webmail.ro',
    'ymail.ro',
    'akm.ru',
    'bk.ru',
    'mail.ru',
    'inbox.ru',
    'list.ru',
    'yandex.ru',
    'rambler.ru',
    'ya.ru',
    'rw.rwandatel.com',
    'rw.rwandatoday.com',
    'rw.rwandaweb.com',
    'saudia.net',
    'saudimail.com',
    'orange.sn',
    'senelec.sn',
    'snds.sn',
    'yubc.net',
    'beotel.net',
    'open.rs',
    'sbb.rs',
    'verat.net',
    'sierratel.net',
    'sierratel.sl',
    'slnet.org',
    'asiaonline.net',
    'myrepublic.net',
    'pacific.net.sg',
    'singnet.com.sg',
    'singtel.com',
    'starhub.com',
    'vgs.sg',
    'centrum.sk',
    'atlas.sk',
    'azet.sk',
    'orange.sk',
    'pobox.sk',
    'post.sk',
    'slovanet.sk',
    't-zones.sk',
    'zoznam.sk',
    'inmail.sk',
    'a1.net',
    's5.net',
    'siol.net',
    't-2.net',
    'volja.net',
    'golis.net',
    'so.com',
    'som.so',
    'somnet.net',
    'somtel.com',
    'somtel.net',
    'telesom.net',
    'web.so',
    'lantic.net',
    'afrihost.co.za',
    'axxess.co.za',
    'cybersmart.co.za',
    'mweb.co.za',
    'telkom.net',
    'telkomsa.net',
    'vodamail.co.za',
    'webmail.co.za',
    'chol.com',
    'daum.net',
    'dreamwiz.com',
    'empal.com',
    'freechal.com',
    'hanmail.net',
    'hitel.net',
    'lycos.co.kr',
    'naver.com',
    'paran.com',
    'sudanese.com.au',
    'sudansite.net',
    'euskalnet.net',
    'terra.es',
    'uca.es',
    'ugr.es',
    'unizar.es',
    'uvigo.es',
    'orange.es',
    'lankamail.net',
    'alquds.edu',
    'paltel.ps',
    'sudanese.com',
    'sudanese.info',
    'sudanese.net',
    'sudanese.org',
    'sudan.net',
    'sudanese.co',
    'sudanmail.com',
    'sr.biz',
    'sr.co',
    'sr.com',
    'sr.net',
    'sr.org',
    'sr.ws',
    'alice.se',
    'bahnhof.se',
    'bredband.net',
    'comhem.se',
    'ownit.se',
    'passagen.se',
    'spray.se',
    'tele2.se',
    'telia.com',
    'tre.se',
    'home.se',
    'hotmail.se',
    'yahoo.se',
    'bredband2.com',
    'halebop.se',
    'bluewin.ch',
    'gmx.ch',
    'hispeed.ch',
    'mail.ch',
    'posteo.ch',
    'sunrise.ch',
    'swissonline.ch',
    'syriamail.com',
    'hinet.net',
    'mail2000.com.tw',
    'ms3.hinet.net',
    'pchome.com.tw',
    'seed.net.tw',
    'so-net.net.tw',
    'tpts5.seed.net.tw',
    'mail.tj',
    'tj.am',
    'tj.by',
    'tj.kz',
    'tj.ru',
    'tj.su',
    'tj.ua',
    'tz.biz',
    'tz.org',
    'thaimail.com',
    'thaimail.info',
    'thaimail.org',
    'timor-leste.org',
    'tl.net',
    'tl.tl',
    'togolese.com',
    'trinidad.co',
    'trinidad.com',
    'trinidad.info',
    'trinidad.net',
    'trinidad.org',
    'trinidadexpress.com',
    'ttconnect.gov.tt',
    'gnet.tn',
    'orange.tn',
    'planet.tn',
    'tlm.tn',
    'topnet.tn',
    'tt.tn',
    'tunet.tn',
    'kablonet.com.tr',
    'mynet.com',
    'netone.com.tr',
    'superonline.net',
    'turknet.net',
    'metu.edu.tr',
    'mail.tm',
    'online.tm',
    'tm.biz.tm',
    'tm.info.tm',
    'tm.name.tm',
    'tm.us.tm',
    'africamail.com',
    'africa2trust.com',
    'rwanda1.com',
    'ugandamail.com',
    'ugandanet.com',
    'ugandaonline.net',
    'bigmir.net',
    'i.ua',
    'meta.ua',
    'rambler.ua',
    'ukr.net',
    'ae.msn.com',
    'ntlworld.com',
    'talktalk.net',
    'aol.co.uk',
    'btinternet.com',
    'live.co.uk',
    'sky.com',
    'virginmedia.com',
    'gmx.co.uk',
    'hotmail.co.uk',
    'mail.co.uk',
    'tiscali.co.uk',
    'yahoo.co.uk',
    'icloud.com',
    'aol.com',
    'live.com',
    'me.com',
    'comcast.net',
    'verizon.net',
    'yahoo.com',
    'aim.com',
    'ameritech.net',
    'att.com',
    'att.net',
    'bellsouth.net',
    'centurylink.net',
    'cfl.rr.com',
    'charter.net',
    'cox.com',
    'cox.net',
    'cs.com',
    'earthlink.net',
    'embarqmail.com',
    'excite.com',
    'frontier.com',
    'frontiernet.net',
    'fuse.net',
    'hughes.net',
    'insightbb.com',
    'juno.com',
    'lycos.com',
    'mailchimp.com',
    'mailinator.com',
    'netscape.net',
    'pacbell.net',
    'peoplepc.com',
    'q.com',
    'rcn.com',
    'rocketmail.com',
    'snet.net',
    'windstream.net',
    'montevideo.com.uy',
    'uruguay.net',
    'uruguay.org',
    'uruguay.org.uy',
    'uy.com',
    'uy.net',
    'uymail.com',
    'mail.uz',
    'uzinfocom.uz',
    'uzland.uz',
    'uzps.uz',
    'uzsci.net',
    'uztelecom.uz',
    'cantv.net',
    'correo.com.ve',
    'hotmail.es',
    'yahoo.es',
    'fpt.vn',
    'vietnamairlines.vn',
    'vietnamnet.vn',
    'viettel.vn',
    'vinaphone.vn',
    'vnn.vn',
    'vnpt.vn',
    'sahara.com',
    'saharamail.com',
    'saharanet.com',
    'yemen.com.ye',
    'zamnet.co.zm',
    'zamnet.com',
    'zamnet.net',
    'zamnet.zm',
    'zamtel.com',
    'zamtel.zm',
    'africaonline.co.zw',
    'zim.co.zw',
    'zimhost.co.zw',
    'zimpost.co.zw',
    'zol.co.zw',
    '020.co.uk',
    '123box.net',
    '123india.com',
    '123mail.org',
    '138mail.com',
    '141.ro',
    '150mail.com',
    '150ml.com',
    '16mail.com',
    '1963chevrolet.com',
    '1963pontiac.com',
    '1st-website.com',
    '2-mail.com',
    '20after4.com',
    '24h.co.jp',
    '24horas.com',
    '271soundview.com',
    '2die4.com',
    '2mydns.com',
    '2net.us',
    '3000.it',
    '3ammagazine.com',
    '3email.com',
    '3xl.net',
    '444.net',
    '4email.net',
    '50mail.com',
    '55mail.cc',
    '5fm.za.com',
    '6sens.com',
    '702mail.co.za',
    '7110.hu',
    '8848.net',
    '8m.net',
    '8u8.com',
    'about.com',
    'acceso.or.cr',
    'access4less.net',
    'accessgcc.com',
    'acmemail.net',
    'adiga.com',
    'adres.nl',
    'advalvas.be',
    'aeiou.pt',
    'afrik.com',
    'afropoets.com',
    'aggies.com',
    'ahaa.dk',
    'aichi.com',
    'airpost.net',
    'aiutamici.com',
    'aklan.com',
    'aknet.kg',
    'alavatotal.com',
    'albafind.com',
    'albawaba.com',
    'alburaq.net',
    'aliyun.com',
    'alexandria.cc',
    'allmail.net',
    'altavista.se',
    'alternativagratis.com',
    'alum.com',
    'alunos.unipar.br',
    'alvilag.hu',
    'amenworld.com',
    'america.hm',
    'americamail.com',
    'amorous.com',
    'ananzi.co.za',
    'anfmail.com',
    'angelfire.com',
    'animail.net',
    'aniverse.com',
    'another.com',
    'antwerpen.com',
    'anunciador.net',
    'anytimenow.com',
    'apexmail.com',
    'approvers.net',
    'aprava.com',
    'apropo.ro',
    'argentina.com',
    'army.com',
    'aroma.com',
    'arrl.net',
    'asheville.com',
    'asia-links.com',
    'asiamail.com',
    'assala.com',
    'assamesemail.com',
    'asurfer.com',
    'atl.lv',
    'atozasia.com',
    'au.ru',
    'aubenin.com',
    'aus-city.com',
    'aussiemail.com.au',
    'avasmail.com.mv',
    'axarnet.com',
    'ayna.com',
    'babbalu.com',
    'badgers.com',
    'bakpaka.com',
    'baluch.com',
    'bama-fan.com',
    'bancora.net',
    'bankersmail.com',
    'beeebank.com',
    'beehive.org',
    'been-there.com',
    'beirut.com',
    'belizehome.com',
    'belizemail.net',
    'berlin.de',
    'bestmail.us',
    'bflomail.com',
    'bgnmail.com',
    'big-orange.com',
    'bigboss.cz',
    'bigfoot.com',
    'bigger.com',
    'bigmailbox.com',
    'bigstring.com',
    'bip.net',
    'biz.by',
    'bizhosting.com',
    'blink182.net',
    'blue.devils.com',
    'blvds.com',
    'bolando.com',
    'bollywood2000.com',
    'bollywoodz.com',
    'bonbon.net',
    'boom.com',
    'bootmail.com',
    'bostonoffice.com',
    'box.az',
    'boxbg.com',
    'boxemail.com',
    'bravanese.com',
    'brazilmail.com.br',
    'breathe.com',
    'brestonline.com',
    'brfree.com.br',
    'brujula.net',
    'btcc.org',
    'buffaloes.com',
    'bulgaria.com',
    'bulldogs.com',
    'bumerang.ro',
    'burntmail.com',
    'butch-femme.net',
    'buzy.com',
    'c-box.cz',
    'c3.hu',
    'c4.com',
    'cadinfo.net',
    'calcfacil.com.br',
    'calcware.org',
    'camaroclubsweden.com',
    'canada.com',
    'canal21.com',
    'canoemail.com',
    'caramail.com',
    'cardblvd.com',
    'care-mail.com',
    'care2.com',
    'caress.com',
    'cashette.com',
    'casino.com',
    'casinomail.com',
    'cataloniamail.com',
    'catalunyamail.com',
    'cataz.com',
    'catcha.com',
    'catholic.org',
    'caths.co.uk',
    'cc.lv',
    'cemelli.com',
    'centoper.it',
    'cercaziende.it',
    'cgac.es',
    'chaiyo.com',
    'chance2mail.com',
    'channelonetv.com',
    'chattown.com',
    'chelny.com',
    'cheshiremail.com',
    'chillimail.com',
    'china.com',
    'christianmail.org',
    'cine.com',
    'ciphercom.net',
    'citynetusa.com',
    'classicmail.co.za',
    'cliffhanger.com',
    'cluemail.com',
    'clujnapoca.ro',
    'collegeclub.com',
    'comfortable.com',
    'compaqnet.fr',
    'compuserve.com',
    'computer.net',
    'computhouse.com',
    'conevyt.org.mx',
    'coolgoose.com',
    'coolkiwi.com',
    'coolmail.com',
    'coolmail.net',
    'coolsend.com',
    'cooltoad.com',
    'cooperation.net',
    'copacabana.com',
    'copticmail.com',
    'corporateattorneys.com',
    'correomagico.com',
    'cosmo.com',
    'cougars.com',
    'count.com',
    'couple.com',
    'critterpost.com',
    'crosspaths.net',
    'crosswinds.net',
    'csucsposta.hu',
    'cumbriamail.com',
    'curio-city.com',
    'custmail.com',
    'cww.de',
    'cyberaccess.com.pk',
    'cybergirls.dk',
    'cybernet.it',
    'cymail.net',
    'dada.net',
    'dailypioneer.com',
    'dansegulvet.com',
    'dayzers.com',
    'dcemail.com',
    'dcsi.net',
    'deacons.com',
    'deal-maker.com',
    'degoo.com',
    'delfi.lv',
    'delhimail.com',
    'desertonline.com',
    'desidrivers.com',
    'despammed.com',
    'detik.com',
    'devils.com',
    'dexara.net',
    'dhmail.net',
    'didamail.com',
    'digitaltrue.com',
    'direccion.com',
    'director-general.com',
    'diri.com',
    'discardmail.com',
    'discoverymail.net',
    'disinfo.net',
    'djmillenium.com',
    'dmailman.com',
    'dnsmadeeasy.com',
    'do.net.ar',
    'dodgeit.com',
    'doityourself.com',
    'domaindiscover.com',
    'domainmanager.com',
    'doneasy.com',
    'dontexist.org',
    'dores.com',
    'dostmail.com',
    'dot5hosting.com',
    'dotcom.fr',
    'dotnow.com',
    'dott.it',
    'doubt.com',
    'dragoncon.net',
    'dragonfans.com',
    'dropzone.com',
    'dserver.org',
    'dubaiwebcity.com',
    'dublin.ie',
    'dustdevil.com',
    'dynamitemail.com',
    'dyndns.org',
    'e-apollo.lv',
    'e-mail.cz',
    'e-mail.ph',
    'e-milio.com',
    'e-webtec.com',
    'earthling.net',
    'eastmail.com',
    'eastrolog.com',
    'easy-pages.com',
    'easy.com',
    'easyinfomail.co.za',
    'easypeasy.com',
    'echina.com',
    'ecn.org',
    'ecplaza.net',
    'edsamail.com.ph',
    'edumail.co.za',
    'eeism.com',
    'elitemail.org',
    'elsitio.com',
    'eltimon.com',
    'elvis.com',
    'email.com.br',
    'email.lu',
    'email.lviv.ua',
    'email.nu',
    'email.ro',
    'email2me.com',
    'emailacc.com',
    'emailaccount.com',
    'emailaddresses.com',
    'emailchoice.com',
    'emailcorner.net',
    'emailn.de',
    'emailengine.net',
    'emailengine.org',
    'emailgroups.net',
    'emailpinoy.com',
    'emailplanet.com',
    'emailplus.org',
    'emailuser.net',
    'ematic.com',
    'embroideryforums.com',
    'eml.cc',
    'emptymail.com',
    'enel.net',
    'enelpunto.net',
    'england.com',
    'enterate.com.ar',
    'entusiastisk.com',
    'enusmail.com',
    'epatra.com',
    'epix.net',
    'epomail.com',
    'epost.de',
    'eprompter.com',
    'eqqu.com',
    'eresmas.com',
    'eriga.lv',
    'ertelecom.ru',
    'esfera.cl',
    'estadao.com.br',
    'etllao.com',
    'euromail.net',
    'euroseek.com',
    'euskalmail.com',
    'evafan.com',
    'everyday.com.kh',
    'everyone.net',
    'execs2k.com',
    'executivemail.co.za',
    'expn.com',
    'ezilon.com',
    'f-m.fm',
    'facilmail.com',
    'faithhighway.com',
    'familyroll.com',
    'familysafeweb.net',
    'fan.net',
    'faroweb.com',
    'fast-email.com',
    'fast-mail.org',
    'fastem.com',
    'fastemail.us',
    'fastemailer.com',
    'fastermail.com',
    'fastest.cc',
    'fastimap.com',
    'fastmailbox.net',
    'fastmessaging.com',
    'fawz.net',
    'fea.st',
    'federalcontractors.com',
    'fedxmail.com',
    'feelings.com',
    'female.ru',
    'fepg.net',
    'ffanet.com',
    'fiberia.com',
    'filipinolinks.com',
    'financesource.com',
    'findmail.com',
    'fiscal.net',
    'flashmail.com',
    'flipcode.com',
    'floridagators.com',
    'fmail.co.uk',
    'fmailbox.com',
    'fmgirl.com',
    'fmguy.com',
    'fnmail.com',
    'footballer.com',
    'forsythmissouri.org',
    'fortuncity.com',
    'forum.dk',
    'free.com.pe',
    'free.net.nz',
    'freeghana.com',
    'freehosting.nl',
    'freemuslim.net',
    'freenet.kg',
    'freeola.net',
    'freepgs.com',
    'freeservers.com',
    'freestart.hu',
    'freeuk.com',
    'freeuk.net',
    'freeweb.it',
    'freewebemail.com',
    'freeyellow.com',
    'frisurf.no',
    'ftml.net',
    'fuelie.org',
    'fun-greetings-jokes.com',
    'fusemail.com',
    'gala.net',
    'galmail.co.za',
    'gamebox.net',
    'gamecocks.com',
    'gay.com',
    'gaymailbox.com',
    'gaza.net',
    'gci.net',
    'gdi.net',
    'geeklife.com',
    'gemari.or.id',
    'genxemail.com',
    'geopia.com',
    'ggaweb.ch',
    'gjk.dk',
    'glay.org',
    'glendale.net',
    'globalfree.it',
    'globomail.com',
    'globalpinoy.com',
    'globalsite.com.br',
    'globalum.com',
    'globetrotter.net',
    'go-bama.com',
    'go-cavs.com',
    'go-chargers.com',
    'go-dawgs.com',
    'go-gators.com',
    'go-hogs.com',
    'go-irish.com',
    'go-spartans.com',
    'go-tigers.com',
    'go.aggies.com',
    'go.badgers.com',
    'go.big-orange.com',
    'go.blue.devils.com',
    'go.buffaloes.com',
    'go.bulldogs.com',
    'go.com',
    'go.dores.com',
    'go.gamecocks.com',
    'go.huskies.com',
    'go.longhorns.com',
    'go.mustangs.com',
    'go.ro',
    'go.ru',
    'go.terrapins.com',
    'go.wildcats.com',
    'go.wolverines.com',
    'go.yellow-jackets.com',
    'go4.it',
    'gofree.co.uk',
    'golfemail.com',
    'goliadtexas.com',
    'gonowmail.com',
    'gonuts4free.com',
    'google.com',
    'googlemail.com',
    'goplay.com',
    'gorontalo.net',
    'gotmail.com',
    'gotomy.com',
    'govzone.com',
    'grad.com',
    'graffiti.net',
    'gtechnics.com',
    'guate.net',
    'guessmail.com',
    'gwalla.com',
    'h-mail.us',
    'haberx.com',
    'hailmail.net',
    'handbag.com',
    'happemail.com',
    'happycounsel.com',
    'hawaii.com',
    'heesun.net',
    'heremail.com',
    'hetnet.nl',
    'highveldmail.co.za',
    'hildebrands.de',
    'hingis.org',
    'hispavista.com',
    'hitmanrecords.com',
    'hockeyghiaccio.com',
    'hockeymail.com',
    'holapuravida.com',
    'home.no.net',
    'home.ro',
    'homelocator.com',
    'homemail.co.za',
    'homenetmail.com',
    'homestead.com',
    'homosexual.net',
    'hongkong.com',
    'hopthu.com',
    'hosanna.net',
    'hotbot.com',
    'hotdak.com',
    'hotfire.net',
    'hotinbox.com',
    'hotpop.com',
    'hour.com',
    'howling.com',
    'huhmail.com',
    'humour.com',
    'hurra.de',
    'huskies.com',
    'hutchcity.com',
    'i-france.com',
    'i12.com',
    'i2828.com',
    'ibatam.com',
    'ibest.com.br',
    'ibizdns.com',
    'icafe.com',
    'ice.is',
    'icestorm.com',
    'icq.com',
    'icqmail.com',
    'icrazy.com',
    'idirect.com',
    'idncafe.com',
    'ieg.com.br',
    'iespana.es',
    'ignazio.it',
    'ilse.net',
    'ilse.nl',
    'imail.ru',
    'imailbox.com',
    'imap-mail.com',
    'imap.cc',
    'imapmail.org',
    'imel.org',
    'inbox.com',
    'inbox.net',
    'in.com',
    'incamail.com',
    'indexa.fr',
    'india.com',
    'indiamail.com',
    'inet.com.ua',
    'infinito.it',
    'infoapex.com',
    'infohq.com',
    'infomart.or.jp',
    'infosat.net',
    'inicia.es',
    'inmail24.com',
    'inoutbox.com',
    'intelnet.net.gt',
    'intelnett.com',
    'interblod.com',
    'interfree.it',
    'interlap.com.ar',
    'intermail.hu',
    'internet-e-mail.com',
    'internet-mail.org',
    'internet.lu',
    'internetegypt.com',
    'internetemails.net',
    'internetmailing.net',
    'inwind.it',
    'iol.it',
    'iol.pt',
    'ipermitmail.com',
    'iqemail.com',
    'iquebec.com',
    'irangate.net',
    'iscool.net',
    'islandmama.com',
    'isonfire.com',
    'ispey.com',
    'itelgua.com',
    'itloox.com',
    'itmom.com',
    'ivenus.com',
    'iwan-fals.com',
    'iwon.com',
    'ixp.net',
    'japan.com',
    'jedrzejow.pl',
    'jetemail.net',
    'jmail.co.za',
    'jojomail.com',
    'joymail.com',
    'jubiipost.dk',
    'justemail.net',
    'justmailz.com',
    'kaazoo.com',
    'kabissa.org',
    'kaixo.com',
    'kalpoint.com',
    'katamail.com',
    'kataweb.it',
    'kayafmmail.co.za',
    'keptprivate.com',
    'kiwitown.com',
    'klik.it',
    'klikni.cz',
    'kmtn.ru',
    'koko.com',
    'kolozsvar.ro',
    'kombud.com',
    'koreanmail.com',
    'krunis.com',
    'kuronowish.com',
    'kyokodate.com',
    'lagoon.nc',
    'lahaonline.com',
    'lamalla.net',
    'land.ru',
    'lawyer.com',
    'lawyersmail.com',
    'lawyerzone.com',
    'leehom.net',
    'leonardo.it',
    'leonlai.net',
    'letsjam.com',
    'letterbox.org',
    'letterboxes.org',
    'levele.com',
    'lexpress.net',
    'liberomail.com',
    'libre.net',
    'linuxfreemail.com',
    'livedoor.com',
    'llandudno.com',
    'llangollen.com',
    'lolnetwork.net',
    'london.com',
    'longhorns.com',
    'look.com',
    'looksmart.co.uk',
    'looksmart.com',
    'looksmart.com.au',
    'loteria.net',
    'lotonazo.com',
    'louiskoo.com',
    'loveable.com',
    'lovemail.com',
    'lovingjesus.com',
    'lpemail.com',
    'lusoweb.pt',
    'lycosmail.com',
    'mac.com',
    'machinecandy.com',
    'macmail.com',
    'madcrazy.com',
    'madonno.com',
    'madrid.com',
    'mag2.com',
    'magicmail.co.za',
    'magik-net.com',
    'mail-atlas.net',
    'mail-awu.de',
    'mail-center.com',
    'mail-central.com',
    'mail-online.dk',
    'mail-page.com',
    'mail-x-change.com',
    'mail.co.za',
    'mail.goo.ne.jp',
    'mail.lawguru.com',
    'mail.mn',
    'mail.org',
    'mail.yahoo.co.jp',
    'mail3000.com',
    'mail333.com',
    'mailandftp.com',
    'mailandnews.com',
    'mailas.com',
    'mailasia.com',
    'mailbg.com',
    'mailblocks.com',
    'mailbolt.com',
    'mailbox.as',
    'mailbox.co.za',
    'mailbox.gr',
    'mailbox.sk',
    'mailc.net',
    'mailcan.com',
    'mailcircuit.com',
    'mailclub.fr',
    'mailclub.net',
    'mailfly.com',
    'mailforce.net',
    'mailftp.com',
    'mailhaven.com',
    'mailingaddress.org',
    'mailingweb.com',
    'mailite.com',
    'mailme.dk',
    'mailmight.com',
    'mailmij.nl',
    'mailnew.com',
    'mailops.com',
    'mailpanda.com',
    'mailpersonal.com',
    'mailroom.com',
    'mailru.com',
    'mails.de',
    'mailsent.net',
    'mailserver.dk',
    'mailservice.ms',
    'mailsnare.net',
    'mailsurf.com',
    'mailup.net',
    'mailworks.org',
    'malayalamtelevision.net',
    'malayalapathram.com',
    'male.ru',
    'manager.de',
    'manlymail.net',
    'mantrafreenet.com',
    'mantramail.com',
    'marihuana.ro',
    'marketweighton.com',
    'masrawy.com',
    'mauimail.com',
    'mbox.com.au',
    'mcrmail.com',
    'me.by',
    'medicinatv.com',
    'meetingmall.com',
    'merseymail.com',
    'messagez.com',
    'metacrawler.com',
    'mexico.com',
    'micro2media.com',
    'miesto.sk',
    'mighty.co.za',
    'milacamn.net',
    'milmail.com',
    'mindless.com',
    'mindviz.com',
    'mixmail.com',
    'ml1.net',
    'mlanime.com',
    'mm.st',
    'mmail.com',
    'mobsters.com',
    'mobstop.com',
    'modemnet.net',
    'modomail.com',
    'moldova.com',
    'moldovacc.com',
    'monarchy.com',
    'moomia.com',
    'moose-mail.com',
    'mosaicfx.com',
    'motormania.com',
    'movemail.com',
    'mrspender.com',
    'mscold.com',
    'msn.com',
    'msn.co.uk',
    'mundo-r.com',
    'muslimsonline.com',
    'mustangs.com',
    'mxs.de',
    'myblue.cc',
    'mycabin.com',
    'mycity.com',
    'mydomain.com',
    'myeweb.com',
    'myfastmail.com',
    'myfunnymail.com',
    'mygrande.net',
    'mykolab.com',
    'myiris.com',
    'myjazzmail.com',
    'mymacmail.com',
    'mymail.dk',
    'mynet.com.tr',
    'myopera.com',
    'myownemail.com',
    'mypersonalemail.com',
    'myrealbox.com',
    'myspace.com',
    'myt.mu',
    'myway.com',
    'n2.com',
    'n2business.com',
    'n2mail.com',
    'nabble.com',
    'name.com',
    'nameplanet.com',
    'nandomail.com',
    'naseej.com',
    'nastything.com',
    'national-champs.com',
    'nativeweb.net',
    'narod.ru',
    'nate.com',
    'naveganas.com',
    'nenter.com',
    'nerdshack.com',
    'nervhq.org',
    'net4you.at',
    'netbounce.com',
    'netcape.net',
    'netexecutive.com',
    'netfirms.com',
    'netkushi.com',
    'netmongol.com',
    'netpiper.com',
    'netposta.net',
    'netscape.com',
    'netscapeonline.co.uk',
    'netsquare.com',
    'nettaxi.com',
    'networld.com',
    'netzero.com',
    'netzero.net',
    'neustreet.com',
    'newmail.com',
    'newmail.net',
    'newyork.com',
    'newyorkcity.com',
    'nfmail.com',
    'nicegal.com',
    'nightimeuk.com',
    'nightly.com',
    'nightmail.com',
    'nightmail.ru',
    'noavar.com',
    'noemail.com',
    'nonomail.com',
    'nokiamail.com',
    'noolhar.com',
    'nospammail.net',
    'nowzer.com',
    'ny.com',
    'nyc.com',
    'nzoomail.com',
    'ocsnet.net',
    'odeon.pl',
    'odmail.com',
    'offshorewebmail.com',
    'oicexchange.com',
    'ok.ru',
    'olympist.net',
    'omaninfo.com',
    'onatoo.com',
    'ondikoi.com',
    'onebox.com',
    'onenet.com.ar',
    'ongc.net',
    'oninet.pt',
    'online.ie',
    'online.ru',
    'onlinewiz.com',
    'open.by',
    'openbg.com',
    'openforyou.com',
    'operamail.com',
    'orangehome.co.uk',
    'orange.jo',
    'orange.pl',
    'organizer.net',
    'orthodox.com',
    'osite.com.br',
    'ourbrisbane.com',
    'ournet.md',
    'ourprofile.net',
    'ourwest.com',
    'outgun.com',
    'ownmail.net',
    'oxfoot.com',
    'ozu.es',
    'pacer.com',
    'paginasamarillas.com',
    'pakistanmail.com',
    'pandawa.com',
    'pando.com',
    'paris.com',
    'parsimail.com',
    'parspage.com',
    'patmail.com',
    'pattayacitythailand.com',
    'pcpostal.com',
    'penguinmaster.com',
    'peopleweb.com',
    'personales.com',
    'peru.com',
    'petml.com',
    'phreaker.net',
    'pilu.com',
    'pimagop.com',
    'pinoymail.com',
    'pipni.cz',
    'pisem.net',
    'planet-school.de',
    'planetaccess.com',
    'planetout.com',
    'playersodds.com',
    'playful.com',
    'pluno.com',
    'plusmail.com.br',
    'pmail.net',
    'pobox.ru',
    'pochta.ru',
    'poetic.com',
    'pogowave.com',
    'popsmail.com',
    'popstar.com',
    'portaldosalunos.com',
    'portugalmail.com',
    'portugalmail.pt',
    'post.pl',
    'postinbox.com',
    'postino.ch',
    'postino.it',
    'postmaster.co.uk',
    'postpro.net',
    'praize.com',
    'press.co.jp',
    'primposta.com',
    'printesamargareta.ro',
    'probemail.com',
    'profesional.com',
    'proinbox.com',
    'promessage.com',
    'prontomail.com',
    'protonmail.ch',
    'provincial.net',
    'publicaccounting.com',
    'punkass.com',
    'puppy.com.my',
    'qlmail.com',
    'qrio.com',
    'qsl.net',
    'qudsmail.com',
    'queerplaces.com',
    'quickwebmail.com',
    'r-o-o-t.com',
    'raakim.com',
    'rbcmail.ru',
    'racingseat.com',
    'radicalz.com',
    'ragingbull.com',
    'raisingadaughter.com',
    'ranmamail.com',
    'ravemail.co.za',
    'razormail.com',
    'real.ro',
    'realemail.net',
    'reallyfast.biz',
    'reallyfast.info',
    'rebels.com',
    'recme.net',
    'rediffmailpro.com',
    'redseven.de',
    'redwhitearmy.com',
    'revenue.com',
    'rexian.com',
    'ritmes.net',
    'rn.com',
    'roanokemail.com',
    'rock.com',
    'rockfan.com',
    'rojname.com',
    'rol.ro',
    'rollin.com',
    'rome.com',
    'royal.net',
    'rpharmacist.com',
    'rt.nl',
    'ru.ru',
    'rushpost.com',
    'russiamail.com',
    'rxpost.net',
    's-mail.com',
    'saabnet.com',
    'sacbeemail.com',
    'sacmail.com',
    'safe-mail.net',
    'safe-mailbox.com',
    'saint-mike.org',
    'sandiego.com',
    'sanook.com',
    'sanriotown.com',
    'sapibon.com',
    'saturnfans.com',
    'scfn.net',
    'schweiz.org',
    'sciaga.pl',
    'scrapbookscrapbook.com',
    'seapole.com',
    'seark.com',
    'secretservices.net',
    'seductive.com',
    'sendmail.ru',
    'sendme.cz',
    'sent.as',
    'sent.at',
    'sent.com',
    'serga.com.ar',
    'server4free.de',
    'sesmail.com',
    'sexmagnet.com',
    'shadango.com',
    'she.com',
    'shuf.com',
    'sinamail.com',
    'singapore.com',
    'singmail.com',
    'sirindia.com',
    'sirunet.com',
    'sister.com',
    'sina.cn',
    'sinanail.com',
    'sistersbrothers.com',
    'sizzling.com',
    'slamdunkfan.com',
    'slickriffs.co.uk',
    'slingshot.com',
    'smartemail.co.uk',
    'snail-mail.net',
    'sneakemail.com',
    'snoopymail.com',
    'snowboarding.com',
    'softhome.net',
    'soon.com',
    'sos.lv',
    'soundvillage.org',
    'space.com',
    'spacetowns.com',
    'spamex.com',
    'speed-racer.com',
    'speedpost.net',
    'speedymail.org',
    'spils.com',
    'spinfinder.com',
    'sportemail.com',
    'spray.no',
    'srbbs.com',
    'srilankan.net',
    'ssan.com',
    'ssl-mail.com',
    'stade.fr',
    'starmail.com',
    'starmail.org',
    'starmedia.com',
    'starspath.com',
    'start.com.au',
    'student.com',
    'student.ednet.ns.ca',
    'studmail.com',
    'suisse.org',
    'sunpoint.net',
    'superdada.it',
    'supereva.com',
    'supereva.it',
    'supermailbox.com',
    'surf3.net',
    'surfassistant.com',
    'surfy.net',
    'surimail.com',
    'surnet.cl',
    'sverige.nu',
    'svizzera.org',
    'sweb.cz',
    'swift-mail.com',
    'swissinfo.org',
    'switzerland.org',
    'syom.com',
    't-net.net.ve',
    't2mail.com',
    'tabasheer.com',
    'talkcity.com',
    'tatanova.com',
    'techemail.com',
    'technisamail.co.za',
    'teenmail.co.za',
    'tejary.com',
    'telebot.com',
    'telegraf.by',
    'teleline.es',
    'telinco.net',
    'telpage.net',
    'telusplanet.net',
    'tempting.com',
    'tenchiclub.com',
    'terrapins.com',
    'texascrossroads.com',
    'tfz.net',
    'the-fastest.net',
    'the-quickest.com',
    'thegame.com',
    'theinternetemail.com',
    'theracetrack.com',
    'thewatercooler.com',
    'thewebpros.co.uk',
    'thinkpost.net',
    'thirdage.com',
    'thundermail.com',
    'timemail.com',
    'tinati.net',
    'tiscalinet.it',
    'tjohoo.se',
    'tkcity.com',
    'tlcfan.com',
    'tmicha.net',
    'todito.com',
    'todoperros.com',
    'tokyo.com',
    'topchat.com',
    'topmail.com.ar',
    'topmail.co.in',
    'topmail.co.nz',
    'topmail.co.uk',
    'topmail.co.za',
    'topsurf.com',
    'toquedequeda.com',
    'torba.com',
    'torchmail.com',
    'totalmail.com',
    'totalsurf.com',
    'tough.com',
    'toughguy.net',
    'trav.se',
    'trevas.net',
    'tripod-mail.com',
    'triton.net',
    'tsamail.co.za',
    'turbonett.com',
    'turkey.com',
    'tvnet.lv',
    'twc.com',
    'typemail.com',
    'u2club.com',
    'ubbi.com',
    'ubbi.com.br',
    'uboot.com',
    'ugeek.com',
    'uk2.net',
    'uk2net.com',
    'ukrpost.net',
    'ukrpost.ua',
    'uku.co.uk',
    'ulimit.com',
    'ummah.org',
    'unicum.de',
    'universia.cl',
    'universia.es',
    'universia.net.mx',
    'universia.pt',
    'universiabrasil.net',
    'uol.com.ar',
    'uole.com',
    'uomail.com',
    'urbi.com.br',
    'ureach.com',
    'userbeam.com',
    'uyuyuy.com',
    'vegetarisme.be',
    'velnet.com',
    'velocall.com',
    'vercorreo.com',
    'verizonmail.com',
    'verticalheaven.com',
    'veryfast.biz',
    'veryspeedy.net',
    'vfemail.net',
    'vietmedia.com',
    'vip.gr',
    'visitmail.com',
    'vivelared.com',
    'vjtimail.com',
    'vsnl.com',
    'vsnl.net',
    'volkermord.com',
    'vosforums.com',
    'walla.com',
    'wallet.com',
    'wam.co.za',
    'wanex.ge',
    'wap.hu',
    'wapda.com',
    'wapicode.com',
    'warpmail.net',
    'wassup.com',
    'waterloo.com',
    'waumail.com',
    'wearab.net',
    'web.nl',
    'web2mail.com',
    'webaddressbook.com',
    'webbworks.com',
    'webcity.ca',
    'webdream.com',
    'webemaillist.com',
    'webindia123.com',
    'webinfo.fi',
    'webjump.com',
    'webl-3.br.inter.net',
    'webmails.com',
    'webmailv.com',
    'webspawner.com',
    'websurfer.co.za',
    'webtv.net',
    'weedmail.com',
    'weekonline.com',
    'weirdness.com',
    'whale-mail.com',
    'whipmail.com',
    'who.net',
    'whoever.com',
    'wildcats.com',
    'wildmail.com',
    'winning.com',
    'winningteam.com',
    'winwinhosting.com',
    'witelcom.com',
    'witty.com',
    'wolverines.com',
    'workmail.co.za',
    'worldcrossing.com',
    'worldemail.com',
    'worldmedic.com',
    'worldonline.de',
    'wowmail.com',
    'wprost.pl',
    'wrongmail.com',
    'wtonetwork.com',
    'wurtele.net',
    'www.com',
    'www.consulcredit.it',
    'x-mail.net',
    'xasa.com',
    'xfreehosting.com',
    'xmail.net',
    'xmsg.com',
    'xoom.com',
    'xuite.net',
    'xpectmore.com',
    'xrea.com',
    'xsmail.com',
    'xzapmail.com',
    'y7mail.com',
    'yahala.co.il',
    'yaho.com',
    'ya.com',
    'yahoomail.com',
    'yapost.com',
    'yehey.com',
    'yellow-jackets.com',
    'yellowstone.net',
    'yenimail.com',
    'yepmail.net',
    'yifan.net',
    'your-mail.com',
    'yours.com',
    'yourwap.com',
    'yyhmail.com',
    'z11.com',
    'z6.com',
    'zednet.co.uk',
    'zeeman.nl',
    'ziplip.com',
    'zipmail.com.br',
    'zipmax.com',
    'zmail.ru',
    'zona-andina.net',
    'zonai.com',
    'zonnet.nl',
    'zoomshare.com',
    'zubee.com',
    'zuvio.com',
    'zwallet.com',
    'zworg.com',
    'zybermail.com',
    'zzn.com',
    '188.com',
    '263.net',
    '9.cn',
    'vip.126.com',
    'vip.163.com',
    'vip.188.com',
    'vip.sina.com',
    'vip.sohu.com',
    'vip.sohu.net',
    'vip.tom.com',
    'vip.qq.com',
    'expressmail.dk',
    '4xn.de',
    '5x2.de',
    '5x2.me',
    'aufdrogen.de',
    'besser-als-du.de',
    'brainsurfer.de',
    'chillaxer.de',
    'cyberkriminell.de',
    'danneben.so',
    'freemailen.de',
    'freemailn.de',
    'ist-der-mann.de',
    'ist-der-wahnsinn.de',
    'ist-echt.so',
    'istecht.so',
    'ist-genialer.de',
    'ist-schlauer.de',
    'ist-supersexy.de',
    'kann.so',
    'mag-spam.net',
    'mega-schlau.de',
    'muss.so',
    'nerd4life.de',
    'ohne-drogen-gehts.net',
    'on-steroids.de',
    'scheint.so',
    'staatsterrorist.de',
    'super-gerissen.de',
    'unendlich-schlau.de',
    'vip-client.de',
    'will-keinen-spam.de',
    'zu-geil.de',
    'rbox.me',
    'rbox.co',
    'tunome.com',
    'acatperson.com',
    'adogperson.com',
    'allsportsrock.com',
    'alwaysgrilling.com',
    'alwaysinthekitchen.com',
    'alwayswatchingmovies.com',
    'alwayswatchingtv.com',
    'basketball-email.com',
    'beabookworm.com',
    'beagolfer.com',
    'beahealthnut.com',
    'believeinliberty.com',
    'bestcoolcars.com',
    'bestjobcandidate.com',
    'besure2vote.com',
    'blackvoices.com',
    'capsfanatic.com',
    'capshockeyfan.com',
    'capsred.com',
    'car-nut.net',
    'cat-person.com',
    'catpeoplerule.com',
    'chat-with-me.com',
    'cheatasrule.com',
    'crazy4baseball.com',
    'crazy4homeimprovement.com',
    'crazy4mail.com',
    'crazyaboutfilms.net',
    'crazycarfan.com',
    'crazyforemail.com',
    'crazymoviefan.com',
    'descriptivemail.com',
    'differentmail.com',
    'dog-person.com',
    'dogpeoplerule.com',
    'easydoesit.com',
    'expertrenovator.com',
    'expressivemail.com',
    'fanaticos.com',
    'fanofbooks.com',
    'fanofcomputers.com',
    'fanofcooking.com',
    'fanoftheweb.com',
    'fieldmail.com',
    'fleetmail.com',
    'focusedonprofits.com',
    'focusedonreturns.com',
    'futboladdict.com',
    'games.com',
    'getintobooks.com',
    'hail2theskins.com',
    'hitthepuck.com',
    'i-dig-movies.com',
    'i-love-restaurants.com',
    'idigcomputers.com',
    'idigelectronics.com',
    'idigvideos.com',
    'ilike2helpothers.com',
    'ilike2invest.com',
    'ilike2workout.com',
    'ilikeelectronics.com',
    'ilikeworkingout.com',
    'ilovehomeprojects.com',
    'iloveourteam.com',
    'iloveworkingout.com',
    'in2autos.net',
    'interestedinthejob.com',
    'intomotors.com',
    'iwatchrealitytv.com',
    'lemondrop.com',
    'love2exercise.com',
    'love2workout.com',
    'lovefantasysports.com',
    'lovetoexercise.com',
    'luvfishing.com',
    'luvgolfing.com',
    'mail4me.com',
    'majorgolfer.com',
    'majorshopaholic.com',
    'majortechie.com',
    'mcom.com',
    'motor-nut.com',
    'moviefan.com',
    'mycapitalsmail.com',
    'mycatiscool.com',
    'myfantasyteamrules.com',
    'myteamisbest.com',
    'news-fanatic.com',
    'newspaperfan.com',
    'onlinevideosrock.com',
    'realbookfan.com',
    'realhealthnut.com',
    'realitytvaddict.net',
    'realitytvnut.com',
    'reallyintomusic.com',
    'realtravelfan.com',
    'redskinsfamily.com',
    'redskinsfancentral.com',
    'redskinsrule.com',
    'redskinsultimatefan.com',
    'scoutmail.com',
    'skins4life.com',
    'stargate2.com',
    'stargateatlantis.com',
    'stargatefanclub.com',
    'stargatesg1.com',
    'stargateu.com',
    'switched.com',
    'thegamefanatic.com',
    'total-techie.com',
    'totalfoodnut.com',
    'totally-into-cooking.com',
    'totallyintobaseball.com',
    'totallyintobasketball.com',
    'totallyintocooking.com',
    'totallyintofootball.com',
    'totallyintogolf.com',
    'totallyintohockey.com',
    'totallyintomusic.com',
    'totallyintoreading.com',
    'totallyintosports.com',
    'totallyintotravel.com',
    'totalmoviefan.com',
    'travel2newplaces.com',
    'tvchannelsurfer.com',
    'videogamesrock.com',
    'volunteeringisawesome.com',
    'wayintocomputers.com',
    'whatmail.com',
    'when.com',
    'wild4music.com',
    'wildaboutelectronics.com',
    'workingaroundthehouse.com',
    'workingonthehouse.com',
    'writesoon.com',
    'xmasmail.com',
    'arab.ir',
    'denmark.ir',
    'egypt.ir',
    'icq.ir',
    'ir.ae',
    'ire.ir',
    'ireland.ir',
    'irr.ir',
    'jpg.ir',
    'ksa.ir',
    'kuwait.ir',
    'london.ir',
    'paltalk.ir',
    'sweden.ir',
    'tokyo.ir',
    '111mail.com',
    '123iran.com',
    '37.com',
    '420email.com',
    '4-music-today.com',
    'actingbiz.com',
    'allhiphop.com',
    'anatomicrock.com',
    'animeone.com',
    'asiancutes.com',
    'a-teens.net',
    'ausi.com',
    'autoindia.com',
    'autopm.com',
    'barriolife.com',
    'b-boy.com',
    'beautifulboy.com',
    'bgay.com',
    'bicycledata.com',
    'bicycling.com',
    'bigheavyworld.com',
    'bigmailbox.net',
    'bikerheaven.net',
    'bikermail.com',
    'billssite.com',
    'blackandchristian.com',
    'blackcity.net',
    'blackvault.com',
    'bmxtrix.com',
    'boarderzone.com',
    'boatnerd.com',
    'bolbox.com',
    'bongmail.com',
    'bowl.com',
    'byke.com',
    'calle22.com',
    'cannabismail.com',
    'catlovers.com',
    'certifiedbitches.com',
    'championboxing.com',
    'chillymail.com',
    'classycouples.com',
    'congiu.net',
    'coolshit.com',
    'corpusmail.com',
    'cyberunlimited.org',
    'cycledata.com',
    'darkfear.com',
    'darkforces.com',
    'dirtythird.com',
    'dopefiends.com',
    'draac.com',
    'drakmail.net',
    'dr-dre.com',
    'dreamstop.com',
    'egypt.net',
    'emailfast.com',
    'envirocitizen.com',
    'escapeartist.com',
    'ezsweeps.com',
    'farts.com',
    'feelingnaughty.com',
    'firemyst.com',
    'freeonline.com',
    'fudge.com',
    'funkytimes.com',
    'gamerssolution.com',
    'gazabo.net',
    'glittergrrrls.com',
    'goatrance.com',
    'goddess.com',
    'gohip.com',
    'gospelcity.com',
    'greatautos.org',
    'guy.com',
    'haitisurf.com',
    'happyhippo.com',
    'hateinthebox.com',
    'houseofhorrors.com',
    'hugkiss.com',
    'hullnumber.com',
    'idunno4recipes.com',
    'intimatefire.com',
    'irow.com',
    'jazzemail.com',
    'juanitabynum.com',
    'kanoodle.com',
    'kickboxing.com',
    'kidrock.com',
    'kinkyemail.com',
    'latinabarbie.com',
    'latinogreeks.com',
    'leesville.com',
    'lowrider.com',
    'madeniggaz.net',
    'mailbomb.com',
    'marillion.net',
    'megarave.com',
    'mofa.com',
    'motley.com',
    'music.com',
    'musicsites.com',
    'netbroadcaster.com',
    'netfingers.com',
    'nocharge.com',
    'operationivy.com',
    'paidoffers.net',
    'pcbee.com',
    'persian.com',
    'petrofind.com',
    'pinkcity.net',
    'planetsmeg.com',
    'poop.com',
    'potsmokersnet.com',
    'primetap.com',
    'project420.com',
    'puppetweb.com',
    'rapstar.com',
    'rapworld.com',
    'rastamall.com',
    'ratedx.net',
    'relapsecult.com',
    'remixer.com',
    'romance106fm.com',
    'singalongcenter.com',
    'slayerized.com',
    'specialoperations.com',
    'speedymail.net',
    'spells.com',
    'streetracing.com',
    'subspacemail.com',
    'sugarray.com',
    'superintendents.net',
    'surfguiden.com',
    'sweetwishes.com',
    'tattoodesign.com',
    'teamster.net',
    'teenchatnow.com',
    'the5thquarter.com',
    'theblackmarket.com',
    'troamail.org',
    'u2tours.com',
    'vitalogy.org',
    'whatisthis.com',
    'wrestlezone.com',
    'agadir.cc',
    'ahsa.ws',
    'ajman.cc',
    'ajman.us',
    'ajman.ws',
    'algerie.cc',
    'cameroon.cc',
    'dhahran.cc',
    'djibouti.cc',
    'eritrea.cc',
    'fujairah.cc',
    'fujairah.us',
    'fujairah.ws',
    'giza.cc',
    'hebron.tv',
    'homs.cc',
    'ibra.cc',
    'irbid.ws',
    'kyrgyzstan.cc',
    'lubnan.ws',
    'madinah.cc',
    'mansoura.tv',
    'marrakesh.cc',
    'mascara.ws',
    'muscat.tv',
    'muscat.ws',
    'nabeul.info',
    'nablus.cc',
    'nador.cc',
    'oued.org',
    'pakistani.ws',
    'palmyra.ws',
    'quds.cc',
    'safat.ws',
    'sanaa.cc',
    'sfax.ws',
    'sinai.cc',
    'tajikistan.cc',
    'timor.cc',
    'zambia.cc',
    'a.org.ua',
    'bmx.lv',
    'coolmail.ru',
    'dino.lv',
    'eclub.lv',
    'e-mail.am',
    'hacker.am',
    'human.lv',
    'latchess.com',
    'loveis.lv',
    'pookmail.com',
    'sexriga.lv',
    'sbcglobal.net',
    'sina.com.cn',
    'rakuten.co.jp',
    'yahoo.de',
    'yahoo.it',
    'yahoo.fr',
    'yahoo.ca',
    'yahoo.com.ar',
    'yahoo.com.mx',
    'yahoo.com.sg',
    'yahoo.com.ph',
    'yahoo.com.tw',
    'yahoo.com.my',
    'yahoo.com.vn',
    'yahoo.com.co',
    'yahoo.co.za',
    'yahoo.com.tr',
    'yahoo.com.pe',
    'yahoo.com.ec',
    'yahoo.com.ve',
    'yahoo.com.do',
    'yahoo.com.cl',
    'yahoo.com.pa',
    'yahoo.com.gt',
    'yahoo.com.cu',
    'yahoo.com.bo',
    'yahoo.com.ni',
    'yahoo.com.pr',
    'yahoo.com.py',
    'yahoo.com.hn',
    'yahoo.com.sv',
    'yahoo.com.jm',
    'yahoo.com.bm',
    'yahoo.com.tt',
    'yahoo.com.gy',
    'yahoo.co.jp',
    'yahoo.com.au',
    'yahoo.com.ca',
    'yahoo.com.cn',
    'yahoo.com.hk',
    'yahoo.nl',
    'yahoo.pl',
    'yahoo.com.ua',
    'yahoo.com.gr',
    'yahoo.com.ru',
    'yandex.ua',
    'ro.ru',
    'nm.ru',
    'yandex.com.tr',
    'yandex.pl',
    'yandex.it',
    'yandex.de',
    'yandex.fr',
    'yandex.am',
    'yandex.az',
    'yandex.ee',
    'yandex.ge',
    'yandex.lt',
    'yandex.lv',
    'yandex.tj',
    'yandex.tm',
    'yandex.uz',
    'yandex.kg',
    'yandex.md',
    'yandex.mn',
];

export function isWorkEmail(email: string) {
    if (!email || email === '') return false;
    for (const nonWorkEmail of NonWorkEmailProviders) {
        if (email.includes(nonWorkEmail)) {
            return false;
        }
    }
    return true;
}
