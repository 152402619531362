<div class="wrapper" [@fadeInOut]>
  <div class="loader">
    <ng-lottie #loading
               [options]="{ path: 'assets/animations/loading.json' }"></ng-lottie>
  </div>

  <h4>{{text}}</h4>


</div>
