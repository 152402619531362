import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { PrompterMessage } from '../models/defines';
import { EventbusService, EventType, IEventType } from './show/eventbus.service';

@Injectable({
  providedIn: 'root',
})
export class PrompterSyncronizerService {
  publishPrompterText = new BehaviorSubject<{ version: string; text: string }>(
    null,
  );
  grabPrompterText = new BehaviorSubject<{ version: string; text: string }>(
    null,
  );

  private _currentPrompterText$ = new BehaviorSubject<string>('');
  public currentPrompterText$ = this._currentPrompterText$.asObservable();

  private _requestToStartTeleprompt$ = new BehaviorSubject<boolean>(false);
  public requestToStartTeleprompt =
    this._requestToStartTeleprompt$.asObservable();

  private _recordButtonClicked$ = new BehaviorSubject<boolean>(false);
  public recordButtonClicked = this._recordButtonClicked$.asObservable();
  bc: BroadcastChannel;
  public masterUp = new BehaviorSubject<boolean>(false);

  private _specificSceneClicked$ = new BehaviorSubject<void>(undefined);
  public specificSceneClicked =
    this._specificSceneClicked$.asObservable();


  constructor(private eventBus: EventbusService) {
    // console.log('Do you see this?');

    this.connect();
  }

  public setCurrentPromptText(text: string) {
    // if the scene has no text, set to empty text
    text = text ?? '';

    this._currentPrompterText$.next(text);
  }

  public setRequestToStartTeleprompt(toStart: boolean) {
    this._requestToStartTeleprompt$.next(toStart);
  }

  public setRecordButtonClicked(isRecording: boolean) {
    this._recordButtonClicked$.next(isRecording);
  }

  public setSpecificSceneClicked() {
    this._specificSceneClicked$.next();
  }

  connect() {
    this.bc = new BroadcastChannel('shuffll-prompters');

    this.bc.onmessage = (messageEvent) => {
      console.log(messageEvent);
      // If our broadcast message is 'update_title' then get the new title from localStorage
      let data: PrompterMessage = messageEvent?.data;

      if (data) {
        if (data.type === 'publish') {
          console.log(messageEvent);
          this.grabPrompterText.next(messageEvent?.data?.payload);
        }
        if (data.type === 'masterUp') {
          console.log(messageEvent);
          this.masterUp.next(true);
        }
        if (data.type === 'masterDown') {
          console.log(messageEvent);
          this.masterUp.next(false);
        }
      }
    };

    /// Register to prompter updates
    this.eventBus.show$.subscribe((event: IEventType) => {
      if (!event) {
        return;
      }
      if (event.type === EventType.prompter_update) {
        console.log('Got a new prompter event', event.data);
        let message = {
          type: 'publish',
          from: 'me',
          payload: { version: event.data?.version, text: event.data?.text },
        };
        this.bc.postMessage(message);
      }
    });
  }

  publish(prompterId: string, version: string, text: string) {
    let message = {
      type: 'publish',
      from: prompterId,
      payload: { version: version, text: text },
    };
    this.bc.postMessage(message);
  }

  connectSlave(prompterId: string) {
    let message: PrompterMessage = {
      type: 'slaveUp',
      from: prompterId,
      payload: true,
    };
    this.bc.postMessage(message);
  }

  disconnectMaster(peerId: string) {
    let message: PrompterMessage = {
      type: 'masterDown',
      from: peerId,
      payload: false,
    };
    this.bc.postMessage(message);
  }

  /// For the master to publish it's up
  connectMaster(peerId: string) {
    let message: PrompterMessage = {
      type: 'masterUp',
      from: peerId,
      payload: true,
    };
    this.bc.postMessage(message);

    /// Master listeners
    this.bc.onmessage = (messageEvent) => {
      // If our broadcast message is 'update_title' then get the new title from localStorage
      let data: PrompterMessage = messageEvent?.data;

      if (data) {
        if (data.type === 'publish') {
          console.log(messageEvent);
          this.publishPrompterText.next(data.payload);
        }
        if (data.type === 'slaveUp') {
          this.connectMaster(peerId);
        }
        if (data.type === 'masterUp') {
          console.log(messageEvent);
        }
      }
    };
  }
}
