import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration.service';

declare var fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  constructor(private configService: ConfigurationService) {}

  trackCustomEvent(eventName: string, params?: object) {
    if (this.configService.trackUserEvents) {
      fbq('track', eventName, params); // Track custom events
    }
  }
}
