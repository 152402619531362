import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Location} from '@angular/common';
import {IntercomService} from 'src/app/services/intercom.service';

@Component({
    selector: 'app-connection-error-page',
    templateUrl: './connection-error-page.component.html',
    styleUrls: ['./connection-error-page.component.scss'],
})
export class ConnectionErrorPageComponent implements OnInit {
    private readonly canGoBack: boolean;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly location: Location,
        private intercomService: IntercomService
    ) {
        // This is where the check is done. Make sure to do this
        // here in the constructor, otherwise `getCurrentNavigation()`
        // will return null.

        this.canGoBack = !!this.router.getCurrentNavigation()?.previousNavigation;
    }

    goToHost(): void {
    }

    goBack(): void {
        if (this.canGoBack) {
            // We can safely go back to the previous location as
            // we know it's within our app.
            this.location.back();
        } else {
            // There's no previous navigation.
            // Here we decide where to go. For example, let's say the
            // upper level is the index page, so we go up one level.
            this.router.navigate(['/dashboard'], {});
        }
    }

    ngOnInit(): void {
        this.intercomService.shutdown();

        setInterval(() => {
        }, 3000);
        // this.spinner.show()
    }
}
