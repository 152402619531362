<full-screen-loading
        [text]="'Loading...'"
        *ngIf="this.loading"></full-screen-loading>

<div id="main-page-wrapper">
    <mat-sidenav-container>
        <mat-sidenav
            #sidenav
            mode="over"
            [opened]="false"
            class="side-nav"
            [fixedInViewport]="true"
            [fixedTopGap]="options.value.top"
            [fixedBottomGap]="options.value.bottom">
         
        </mat-sidenav>

        <!--Page content starts here-->
        <mat-sidenav-content [formGroup]="options" id="page-container">
            <div id="main-pane">
                <!--end navbar-->
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
