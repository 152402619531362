import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecordingProgressService {
  private isRecordingInProgressSubject = new BehaviorSubject<boolean>(false);
  public isRecordingInProgress$: Observable<boolean> =
    this.isRecordingInProgressSubject.asObservable();

  private _recordingTimeCounter$ = new BehaviorSubject<number>(0);
  public recordingProgress$ = this._recordingTimeCounter$.asObservable();

  constructor() {}

  setRecordingInProgress(isRecording: boolean): void {
    this.isRecordingInProgressSubject.next(isRecording);
  }

  get RecordingTimeCounterValue(): number {
    return this._recordingTimeCounter$.value;
  }
  set RecordingTimeCounterValue(time: number) {
    this._recordingTimeCounter$.next(time);
  }

  get isRecordingInProgress(): boolean {
    return this.isRecordingInProgressSubject.value;
  }
}
