import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { BehaviorSubject } from 'rxjs';
import { IAsset, IPathAndDash } from 'src/app/models/defines';
import { AssetsComponent } from 'src/app/pages/private/dashboard/assets/assets.component';

@Component({
  selector: 'app-video-selector',
  templateUrl: './video-selector.component.html',
  styleUrls: ['./video-selector.component.scss'],
})
export class VideoSelectorComponent implements OnInit, OnChanges {
  vgApi: VgApiService;

  @Output()
  onVideosSelected = new EventEmitter<IAsset>(null);

  @Output()
  selectorOpen = new EventEmitter<boolean>(false);

  @Input()
  selectedVideoPath?: IPathAndDash;

  @Input('dummy') dummy = false;

  assetBasePath: string;

  videoPath$ = new BehaviorSubject<IPathAndDash>(null);

  constructor(private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.selectedVideoPath && !this.dummy) {
    //   this.updateVideoPath();
    // }
  }

  ngOnInit(): void {
    if (!this.dummy) this.updateVideoPath();
  }

  updateVideoPath() {
    if (this.selectedVideoPath) {
      console.log('selected video path', this.selectedVideoPath);
      this.videoPath$.next(this.selectedVideoPath);
    }
  }

  openVideoSelectorDialog() {
    const videoSelectorDialog = this.dialog.open(AssetsComponent, {
      disableClose: false,
      panelClass: 'padding-modal',
      minWidth: '90vw',
      data: {
        hosted: true,
        dialogType: 'video',
      },
    });
    videoSelectorDialog.afterClosed().subscribe((result: IAsset) => {
      this.selectorOpen.next(false);
      if (result?.uploadPath || result?.dashPath) {
        this.onVideosSelected.next(result);
      }
    });

    videoSelectorDialog.afterOpened().subscribe(() => {
      this.selectorOpen.next(true);
    });
  }

  onPlayerReady(api: VgApiService) {
    this.vgApi = api;
  }
}
