import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from 'src/app/models/dialog-wrapper.model';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
    message = null;
    title = null;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
        // let data = data as unknown as {new: true, oldName: null};
        this.message = data['message'];
        this.title = data['title'];
    }
}
