import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/models/user';
import { ConfigurationService } from '../configuration.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureApiService {
  path = `${this.config.apiIP}/auth/features`;
  redirectPath = `${this.config.apiIP}`;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {}

  updateUserFeatureGroup(user: User) {
    return this.http.put<User>(this.path + `/featureGroup`, {
      user,
    });
  }
}
