<div id="right-pane">
  <div id="rightpane-logo-overlay"></div>
  <div class="panel-title">
    Epic Moments
  </div>
  <div class="feed-moment">
    <vg-player (onPlayerReady)="onPlayerReady($event)" class="rounded">
      <video style="min-height: auto!important;" *ngIf="videoSrc" dashUrlResolver
             [videoSource]="videoSrc" controls autoplay class="full-width-height selected-video"
             preload="auto"
             #media [vgMedia]="media" id="singleVideo">
        <track src="assets/data/cue-points.vtt" kind="metadata" label="Cue Points" default #metadataTrack vgCuePoints>
      </video>
    </vg-player>
    <div class="moments-list">
      <div class="moment-box" [ngClass]="{'active': item.active}" *ngFor="let item of moments; let i = index">

        <div class="moment-body">
          <div class="moment-title">
            <mat-icon>{{item.type}}</mat-icon>
            {{item.text}}
          </div>
          <div class="moment-reactions">
            <div class="moment-time">
              <mat-icon>av_timer</mat-icon>
              <div>02:45</div>
            </div>
            <div class="moment-claps">

            </div>
          </div>
        </div>
        <div class="moment-thumb" [ngStyle]="{'background-image': 'url(' + item.image + ')'}">

        </div>
      </div>

    </div>
    <!--          <app-video-js urlVideo="http://localhost:4200/app/images/demo/rightpane-video.mp4" autoplay></app-video-js>-->
  </div>


  <div *ngIf="requestsToJoin != null">
    <div class="panel-title">
      Requests to join
    </div>
    <div *ngIf="requestsToJoin.length === 0">
      No requests right now
      {{requestsToJoin}}
    </div>

    <button mat-button *ngFor="let requestToJoin of requestsToJoin" (click)="allowJoin(requestToJoin)">
      {{requestToJoin.name}}
    </button>


  </div>
</div>
