import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SflPeer } from '../../../models/defines';

@Component({
  selector: 'app-replace-backstage-position',
  templateUrl: './replace-backstage-position.component.html',
  styleUrls: ['./replace-backstage-position.component.scss'],
})
export class ReplaceBackstagePositionComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { currentlyOccupiedBy: SflPeer; spotIndex: number }
  ) {}

  ngOnInit(): void {
    console.log(
      `DATAAA ${(this.data.currentlyOccupiedBy, this.data.spotIndex)}`
    );
  }
}
