<div class="dialog-wrapper" >
  <div class="lottie-wrapper">

    
  <ng-lottie 
    [options]="data.lottieOptions"
    (animationCreated)="animationCreated($event)"
  ></ng-lottie>
</div>
  <div mat-dialog-actions>
    <button
      class="middle"
      mat-button
      [mat-dialog-close]="true"
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
