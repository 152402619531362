import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard  {
    constructor(private auth: AuthenticationService, private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const currentUser = this.auth.getUser();
        console.log('current user in guard', currentUser);
        if (currentUser !== null) {
            const domain = this.auth.getDomainFromEmail(currentUser.email);
            if (domain === 'shuffll.com') return true;
        }
        this.router.navigate(['/']);
        return false;
    }
}
