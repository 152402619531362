import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeedbacksService {
  FEEDBACK_SUBMITED_LOCALSTORAGE_KEY = 'feedbackSubmited';

  constructor() {}

  setFeedbackSubmitedToLocalStorage(isSubmited: string) {
    localStorage.setItem(this.FEEDBACK_SUBMITED_LOCALSTORAGE_KEY, isSubmited);
  }

  getIsFeedbackSucmitedFromLocaStorage() {
    return localStorage.getItem(this.FEEDBACK_SUBMITED_LOCALSTORAGE_KEY);
  }
}
