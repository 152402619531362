import { ExportQualityEnum, IPathAndDash } from '../defines';
import { v4 as uuidv4 } from 'uuid';
import { User } from '../user';
import { ITake, ITakeWithLottieComposed } from '../project/take/take-model';
import {
  IAudioEditTake,
  ITrimLayers,
  IVideoEditTake,
} from '../project/edit/edit-model';

export enum EditJobType {
  DEFAULT = 'default',
  CLEAN = 'clean',
  CUSTOM = 'custom',
}

export enum JobStatus {
  DUMMY = 'dummy',
  STARTED = 'started',
  SEGMENTED = 'segmented',
  COMPOSED = 'composed',
  DASHED = 'dashed',
  GENERATED_THUMBNAILS = 'generated_thumbnails',
  TRIMMED = 'trimmed',
  FUSED = 'fused',
  AUDIO_CLEANED = 'audio_cleaned',
  MUXED = 'muxed',
  COMPLETED = 'completed',
}

export interface ISceneStyle {
  width?: string; // css string
}

export enum EditScenesTypes {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export interface IOLDBASICEDITSCENEZAIN {
  sceneId?: string;
  duration?: number;
  trimStart?: number;
  trimEnd?: number;
  title?: string;

  style?: ISceneStyle;
  durationOnTrack?: number; // Only for client
}

export class VideoEditTake implements IVideoEditTake {
  id: string = uuidv4();
  duration: number;
  durationOnTrack: number;
  isPlaying: boolean;
  trimStart: number;
  trimEnd: number;

  constructor(
    public take: ITakeWithLottieComposed,
    public style: ISceneStyle = {},
    public sceneId: string,
    public name: string,
    public trims: ITrimLayers
  ) {
    this.duration = Math.round(this.take.duration);
    this.updateTrims();
  }

  updateTrims() {
    // this.trimStart = this.trims.lottieTrims.start ?? 0;
    this.trimStart =
      this.trims.videoTrims?.start ?? this.trims.lottieTrims.start ?? 0;
    this.trimEnd =
      this.trims.videoTrims?.end ?? this.trims.lottieTrims.end ?? 0;

    this.durationOnTrack = Math.round(
      this.take.duration - this.trimEnd - this.trimStart
    );
    this.updateLayersTrim();
  }

  private updateLayersTrim() {
    for (const videoLottieConfig of this.take.lottieComposedConfigs
      .composeConfigs.videoLottieConfigs) {
      for (const lottieFromTake of this.take.lottieLayers) {
        const matchingLottieLayer =
          videoLottieConfig.basicLottieLayersOnTrack.find(
            (basicLottie) => basicLottie.lottieId === lottieFromTake.lottieId
          );
        if (!matchingLottieLayer) {
          continue;
        }

        matchingLottieLayer.trimStart = lottieFromTake.trimStart ?? 0;
        matchingLottieLayer.trimEnd = lottieFromTake.trimEnd ?? 0;
      }
      const trims = this.trims;
      for (const video of videoLottieConfig.videoLayers) {
        video.defaultTrimStart = trims.videoTrims.start;
        video.defaultTrimEnd = trims.videoTrims.end;
      }
      for (const lottie of videoLottieConfig.basicLottieLayersOnTrack) {
        lottie.trimStart = trims.lottieTrims.start;
        lottie.trimEnd = trims.lottieTrims.end;
      }
    }
  }
}

export enum ThumbnailType {
  CAMERA = 'camera',
  LOTTIE = 'lottie',
}

export interface IThumbnail {
  lottieThumbnailPath?: string;
  cameraThumbnailPath?: string;
  frame?: number;
}

export interface IEditVideoScene extends IOLDBASICEDITSCENEZAIN {
  takeId: string;
  baseVolume?: number;
  fadeType?: string;
  secondsToFadeInAudio?: number;
  secondsToFadeOutAudio?: number;
  transitionDurationVideo?: number;
  take?: ITake; // we dont send this
  calculatedThumbnails?: IThumbnail[];
  isPlaying?: boolean; // Only for client
}

export class EditAudioScene implements IAudioEditTake {
  id = uuidv4();
  trimEnd: number = 0;
  trimStart: number = 0;

  constructor(
    public audioPath: string,
    public fileName: string,
    public duration: number,
    public style: ISceneStyle = {}
  ) {}

  startTime: number;
  endTime: number;
  durationOnTrack: number;
  baseVolume: number;
  fadeType: string;
  secondsToFadeInAudio: number;
  secondsToFadeOutAudio: number;
  transitionDurationVideo: number;

  updateTrims() {}
}

export interface IEditAudioScene extends IOLDBASICEDITSCENEZAIN {
  audioPath: string; // path to the audio that the user upload/generated bg music :D
  fileName: string;
  startTime?: number; // getting it from the user
  endTime?: number; // getting it from the user ( we have duration so maybe this is not relevant )
}

export enum EditJobStatusEnum {
  DUMMY = 'dummy',
  STARTED = 'started', /// Does not in editor yet
  IN_PROGRESS = 'in_progress', /// Does in editor
  SEGMENTED = 'segmented',
  COMPOSED = 'composed',
  DASHED = 'dashed',
  GENERATED_THUMBNAILS = 'generated_thumbnails',
  TRIMMED = 'trimmed',
  FUSED = 'fused',
  AUDIO_CLEANED = 'audio_cleaned',
  MUXED = 'muxed',
  COMPLETED = 'completed',
  NO_VIDEO = 'no-video',
}

export interface IExportVideos {
  originalExported: IPathAndDash;
  exportedWithAudioClean: IPathAndDash;
}

export interface IEditJob {
  id?: string;
  streamId: string;
  editVideoScenes?: IEditVideoScene[];
  editAudioScenes?: IEditAudioScene[];
  exportQuality?: ExportQualityEnum;
  editJobStatus?: EditJobStatusEnum;
  createdAt?: Date;
  exportedVideos?: IExportVideos;
  createdby?: User;
  status?: JobStatus;

  title?: string;
  hostEmail?: string;
}
