<div class="title">Share these link with your team!</div>

<form>
  <div>

    <div class="field-title"><span><b>Direct Invite</b>- Automatically approved</span><span matTooltip="Immediate access (valid for 7 days)"><mat-icon class="info">info</mat-icon></span></div>
    <mat-form-field appearance="fill" *ngIf="directInviteUrl">
      <input matInput type="text" [(ngModel)]="directInviteUrl" name="inviteLink" readonly placeholder="Generating link">
      <span matSuffix><button mat-icon-button [cdkCopyToClipboard]='directInviteUrl' [disabled]="!directInviteUrl" (click)="notifyCopy('Immediate access')"><mat-icon>content_copy</mat-icon></button></span>
    </mat-form-field>
    <div  *ngIf="!directInviteUrl">
      Generating...
    </div>
    <div class="field-title"><b>Invite link</b><span matTooltip="We will ask your permission each time"><mat-icon class="info">info</mat-icon></span></div>

    <mat-form-field appearance="fill">
      <input matInput type="text" [(ngModel)]="inviteUrl" name="inviteLink" readonly>
      <span matSuffix><button mat-icon-button [cdkCopyToClipboard]='inviteUrl' (click)="notifyCopy('Guest')"><mat-icon>content_copy</mat-icon></button></span>
    </mat-form-field>

  </div>
  <!--  <div>-->
  <!--    <div>Join link</div>-->

  <!--    <mat-form-field appearance="fill">-->
  <!--    <input matInput formControlName="joinLink" readonly>-->
  <!--    <span matSuffix><button mat-icon-button [cdkCopyToClipboard]='joinUrl' (click)="notifyCopy('Join stream')"><mat-icon>content_copy</mat-icon></button></span>-->
  <!--  </mat-form-field>-->
  <!--  </div>-->
</form>
