import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appVerticalLine]'
})
export class VerticalLineDirective {
  private line: HTMLElement;


  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    // Get the x-coordinate of the mouse cursor relative to the div
    const x = event.clientX - this.elementRef.nativeElement.getBoundingClientRect().left;

    // Remove the previous line element (if any)
    if (this.line) {
      this.renderer.removeChild(this.elementRef.nativeElement, this.line);
    }

    // Create the vertical line element
    this.line = this.renderer.createElement('div');
    this.renderer.setStyle(this.line, 'width', '1px');
    this.renderer.setStyle(this.line, 'height', '100%');
    this.renderer.setStyle(this.line, 'background-color', 'black');
    this.renderer.setStyle(this.line, 'position', 'absolute');
    this.renderer.setStyle(this.line, 'left', `${x}px`);

    // // Add the cdkDrag directive to make the line draggable
    // const cdkDrag = new CdkDrag(this.elementRef, this.renderer);
    // cdkDrag.element.nativeElement = this.line;
    // cdkDrag.dropContainer = this.elementRef.nativeElement;
    // cdkDrag.lockAxis = 'x';
    //
    // // Add the cdkDropList directive to enable dropping of the vertical line
    // const cdkDropList = new CdkDropList(this.elementRef, this.renderer, null);
    // cdkDropList.id = 'line-drop-list';
    // cdkDropList.data = this.line;
    // cdkDropList.connectedTo = [cdkDropList];

    // Add the vertical line element as a child of the div
    this.renderer.appendChild(this.elementRef.nativeElement, this.line);
  }
}
