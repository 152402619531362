import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-loading',
  templateUrl: './simple-loading.component.html',
  styleUrls: ['./simple-loading.component.scss']
})
export class SimpleLoadingComponent implements OnInit {

  @Input() loadingText = "Loading...";
  constructor() { }

  ngOnInit(): void {
  }

}
