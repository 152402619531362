<div style="height: 10px">
    <mat-progress-bar mode="indeterminate" *ngIf="loadingData"></mat-progress-bar>
</div>

<div class="dashboard-page padded" [class.minimized]="!realPlatformMode" autosize *ngIf="!loadingData">
    <div class="top-section"></div>
  

    <div class="header">

        <h2 class="header__title no-margin">
            <app-sidenav-toggle title="branding"></app-sidenav-toggle>
        </h2>
        <button mat-icon-button class="small-icon-button" *ngIf="!realPlatformMode" (click)="defaultBranding()">
            <mat-icon>delete_outline</mat-icon>
        </button>
    </div>
    <div class="container">
        <h3 *ngIf="realPlatformMode">About</h3>
        <div class="row-centered">
            <mat-form-field class="example-form-field">
                <mat-label>{{realPlatformMode ? 'Brand Name' : 'Comapny Name'}}</mat-label>
                <input
                        matInput
                        type="text"
                        [(ngModel)]="companyName"
                        (keydown.enter)="saveCompanyName()"
                        (focusout)="saveCompanyName()"/>
                <button
                        *ngIf="companyName"
                        matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="companyName = ''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="row-centered" *ngIf="realPlatformMode">
            <mat-form-field class="brand-story">
                <mat-label>Brand Story</mat-label>
                <textarea
                        matInput
                        type="text"
                        [(ngModel)]="companyAbout"
                        (keydown.enter)="saveCompanyStory()"
                        (focusout)="saveCompanyStory()"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="7">
        </textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="container">
        <h3 *ngIf="realPlatformMode">Logo</h3>
        <div class="row">
            <div class="logo-col">
                <h4 *ngIf="realPlatformMode">Squared</h4>
                <div class="logo-wrapper transparent-grid">
                    <div
                            *ngIf="branding?.logo"
                            class="logo"
                            [ngStyle]="{
              'background-image':
                'url(\'' + this.assetBasePath + branding.logo + '\')'
            }"></div>
                </div>
                <app-file-drop
                        *ngIf="realPlatformMode"
                        accepts=".svg,.png"
                        [uploadAPI]="brandingAPI.uploadLogo"
                        (uploadFinished)="getBrandingFromApi(true)"></app-file-drop>
            </div>

            <div class="space-left logo-col" *ngIf="realPlatformMode">
                <h4>Horizontal</h4>
                <div class="logo-wrapper transparent-grid">
                    <div
                            *ngIf="branding?.logoW"
                            class="logo"
                            [ngStyle]="{
              'background-image':
                'url(\'' + this.assetBasePath + branding.logoW + '\')'
            }"></div>
                </div>
                <app-file-drop
                        accepts=".svg,.png"
                        [uploadAPI]="brandingAPI.uploadLogoW"
                        (uploadFinished)="getBrandingFromApi(false)"></app-file-drop>
            </div>
            <!--      <mat-slide-toggle color="primary" [(ngModel)]="displayWaterMark">-->
            <!--        Display: {{displayWaterMark ? 'On' : 'Off'}}</mat-slide-toggle>-->
        </div>
    </div>

    <!--  <div class="container">-->
    <!--    <h3>Media</h3>-->
    <!--    <div class="row-centered">-->
    <!--      <app-file-drop></app-file-drop>-->
    <!--    </div>-->
    <!--  </div>-->

    <div class="container colors">
        <h2 class="title" style="font-size: 15px !important" *ngIf="realPlatformMode">Colors</h2>
        <div class="row-centered">
            <div class="pickers row-centered" *ngIf="branding">
                <div
                        class="color-picker-container"
                        [(colorPicker)]="branding.colors.colorPrimary"
                        [style.background]="branding.colors.colorPrimary"
                        (colorPickerSelect)="singleColorChange($event)"
                        [cpDialogDisplay]="'popup'"></div>
                <div
                        class="color-picker-container"
                        [(colorPicker)]="branding.colors.colorSecondary"
                        (colorPickerSelect)="singleColorChange($event)"
                        [style.background]="branding.colors.colorSecondary"></div>
                <div
                        class="color-picker-container"
                        [(colorPicker)]="branding.colors.colorExtra"
                        (colorPickerSelect)="singleColorChange($event)"
                        [style.background]="branding.colors.colorExtra"></div>
            </div>
            <mat-form-field appearance="fill">
                <mat-label>Color Palette</mat-label>
                <mat-select
                        [formControl]="collorPalleteFormControl"
                        (selectionChange)="newColorSelected()">
                    <mat-option *ngIf="showCustomPallete" [value]="customPalette"
                    >Custom
                    </mat-option>
                    <!--Bsically does nothing -->
                    <mat-option *ngIf="branding?.colors" [value]="defaultPalette"
                    >Default
                    </mat-option>
                    <mat-option *ngIf="branding?.logoColors" [value]="logoColorPallete"
                    >From Logo
                    </mat-option>

                    <mat-option
                            *ngFor="let palette of colorPalettesService.palettes"
                            [value]="palette">
                        {{ palette.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!--  <div class="container">-->
    <!--    <h3>Style</h3>-->

    <!--    <div class="row-centered">-->
    <!--      <mat-form-field appearance="fill">-->
    <!--        <mat-label>Style</mat-label>-->
    <!--        <mat-select-->
    <!--          [formControl]="designFormControl"-->
    <!--          (selectionChange)="newDesignSelected()"-->
    <!--        >-->
    <!--          <mat-option [value]="'Proffessional'">Proffessional</mat-option>-->
    <!--          <mat-option [value]="'Gamified'">Gamified</mat-option>-->
    <!--          <mat-option [value]="'Minimalism'">Minimalism</mat-option>-->
    <!--          <mat-option [value]="'Typographic'">Typographic</mat-option>-->
    <!--          <mat-option [value]="'Abstract'">Abstract</mat-option>-->
    <!--          <mat-option [value]="'Retro'">Retro</mat-option>-->
    <!--        </mat-select>-->
    <!--      </mat-form-field>-->
    <!--    </div>-->
    <!--  </div>-->
</div>
<!--<div class="container">-->
<!--  <h3>-->
<!--    Typography-->
<!--  </h3>-->
<!--  <div class="row-centered">-->

<!--  </div>-->
<!--</div>-->

<!--<div class="container"></div>-->
