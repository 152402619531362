<h1 mat-dialog-title class="title">Choose what to do next</h1>

<div class="button-container">
  <div
    *ngFor="let command of this.commandOptions"
    mat-dialog-actions
    class="option-btn"
  >
    <button
      mat-button
      mat-raised-button
      color="primary"
      (click)="onSelectCommand(command.command)"
      cdkFocusInitial
    >
      {{ command.text }}
    </button>
  </div>
</div>
