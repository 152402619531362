<div class="loading-wrapper">
  <ng-lottie class="loading"
             [options]="{ path: 'assets/animations/loading.json'}"></ng-lottie>
</div>
<div id="loading-text-wrapper">
  <h4 class="loading-text" *ngFor="let step of [steps[currentStep]]"   [@fadeInUpOnEnter] [@fadeOutUpOnLeave]>
    {{step}}
  </h4>
</div>

