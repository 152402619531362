import { Injectable } from '@angular/core';
import { IConvertor } from '../../../../../models/project-model';
import {
  IEditPlugins,
  IEditPluginsInDTO,
  IEditPluginsOutDTO,
} from '../../../../../models/project/edit/subtitles/styles/edit-styles.model';
import { SubtitlesSettingsConverterService } from './subtitles-settings.converter.service';
import { EditSubtitlesConfigsConverterService } from './edit-subtitles-configs.converter.service';
import { ISubtitlesConfigOutDTO } from '../../../../../models/project/edit/subtitles/subtitles-configs';

@Injectable({
  providedIn: 'root',
})
export class EditStylesConverterService
  implements IConvertor<IEditPluginsInDTO, IEditPlugins, IEditPluginsOutDTO>
{
  constructor(
    private subtitlesConfigsConverter: EditSubtitlesConfigsConverterService
  ) {}

  inToLocal(input: IEditPluginsInDTO, ...args: any[]): IEditPlugins {
    const localSubtitlesConfigs = this.subtitlesConfigsConverter.inToLocal(
      input.subtitles
    );

    const localEditStyle: IEditPlugins = {
      subtitles: localSubtitlesConfigs,
    };
    return localEditStyle;
  }
  localToOut(client: IEditPlugins, ...args: any[]): IEditPluginsOutDTO {
    const outSubtitlesConfigs = this.subtitlesConfigsConverter.localToOut(
      client?.subtitles
    );

    const outEditStyle: IEditPluginsOutDTO = {
      subtitles: outSubtitlesConfigs,
    };
    return outEditStyle;
  }
}
