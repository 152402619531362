import { VideoLayerApiService } from 'src/app/services/api/auth/projects/video-layer-api.service';

import { MimeTypeEnum } from '../../defines';
import { ProfileService } from 'src/app/services/show/profile.service';
import { IVideoLayerInDTO } from '../take/layers/video-model';
import {
  IStagePositionsExtended,
  VIDEORESOLUTION,
} from 'src/app/models/defines';

export class TakeRecorderManager {
  private mediaRecorder: MediaRecorder;

  private readonly options: MediaRecorderOptions;

  baseMimetype: MimeTypeEnum;
  codecMimetype: string;
  constructor(
    private readonly projectId: string,
    private readonly sceneId: string,
    private readonly takeId: string,
    private readonly videoLayerApi: VideoLayerApiService,
    private readonly profileService: ProfileService,
    private readonly optionsForMediaRecorder?: MediaRecorderOptions
  ) {
    this.baseMimetype = this.profileService.baseMimetype;
    this.codecMimetype = this.profileService.codecMimetype;

    /// Ensuring we have mimetype, audio and video bits per second.
    /// We don't trust the mimtype from outside ;)
    this.options = {
      ...this.optionsForMediaRecorder,
      audioBitsPerSecond: this.options?.audioBitsPerSecond ?? 128000,
      videoBitsPerSecond: this.options?.videoBitsPerSecond ?? 2500000,
      mimeType: this.codecMimetype,
    };
  }

  public addVideoLayerToTakeAsync(videoPositionId: string) {
    const width =
      VIDEORESOLUTION[this.profileService.mainVideoResolution].width;
    const height =
      VIDEORESOLUTION[this.profileService.mainVideoResolution].height;
    return new Promise<IVideoLayerInDTO>((resolve, reject) => {
      this.videoLayerApi
        .postVideoLayer$(
          this.projectId,
          this.sceneId,
          this.takeId,
          this.baseMimetype,
          videoPositionId,
          width,
          height
        )
        .subscribe({
          next: (videoLayer) => {
            return resolve(videoLayer);
          },
          error: (error) => {
            console.error(
              `An error occurred while fetching video layer url: ${error}`
            );
            return reject(error);
          },
        });
    });
  }

  public appendChunkToVideoLayerAsync(chunk: Blob, videoLayerId: string) {
    return new Promise<void>((resolve, reject) => {
      this.videoLayerApi
        .appendChunkToVideoLayer$(
          this.projectId,
          this.sceneId,
          this.takeId,
          videoLayerId,
          chunk
        )
        .subscribe({
          next: (response) => {
            return resolve();
          },
          error: (error) => {
            console.error(
              `Error while trying to append chunk to video, error: ${error}`
            );

            return reject(error);
          },
        });
    });
  }

  public updateVideoLayerTrimsAsync(
    videoLayerId: string,
    trimStart: number | null,
    trimEnd: number | null
  ) {
    return new Promise<boolean>((resolve, reject) => {
      this.videoLayerApi
        .updateVideoLayerTrims$(
          this.projectId,
          this.sceneId,
          this.takeId,
          videoLayerId,
          trimStart,
          trimEnd
        )
        .subscribe({
          next: (response) => {
            return resolve(response);
          },
          error: (error) => {
            console.error(
              `Error while trying to update video layer trims, error: ${error}`
            );
            return reject(error);
          },
        });
    });
  }

  public setMediaRecorder(stream: MediaStream) {
    this.mediaRecorder = new MediaRecorder(stream, this.options);
    return this.mediaRecorder;
  }

  get mediaRecorderOptions() {
    return this.options;
  }

  get currentBaseMimetype() {
    return this.baseMimetype;
  }
}
