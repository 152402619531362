import { ConfigurationService } from '../../../../../services/configuration.service';
import { ExportStatus, ProjectStatusEnum } from '../../../../../models/defines';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/guards/authentication.service';

import { EditJobStatusEnum } from 'src/app/models/job/edit-job-schema';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { IProject, IProjectInDTO } from 'src/app/models/project-model';
import { ProfileService } from '../../../../../services/show/profile.service';
import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import { ArtDirectorService } from '../../../../../services/art-director.service';
import { ProjectGeneralRouterService } from '../../../../../services/project/routes/project-general-router.service';

export enum GroupByEnum {
  NONE = 'None',
  SERIES = 'Series',
}

@Component({
  selector: 'my-library',
  templateUrl: './my-library.component.html',
  styleUrls: ['./my-library.component.scss'],
})
export class MyLibraryComponent implements OnInit {
  groupByEnum = GroupByEnum;
  loadingData: boolean = false;
  projects: IProjectInDTO[] = [];
  selectedGroupByOption = GroupByEnum.NONE;
  groupOptions = [GroupByEnum.NONE, GroupByEnum.SERIES];
  userImg = this.authentication.getUser().profileImgUrl;
  baseCdn: string;
  personalizedDynamics: IDynamicLottieChange[];
  lottieOptions = {
    path: 'assets/animations/production/rocket.json',
    autoplay: true,
    loop: true,
  };
  dataInitialized = false;
  tabs = ['All'];
  projectsInStatuses: Record<'All' | ProjectStatusEnum, IProjectInDTO[]> = {
    All: [],
    [ProjectStatusEnum.PLANNING]: [],
    [ProjectStatusEnum.RECORDING]: [],
    [ProjectStatusEnum.EDITING]: [],
    [ProjectStatusEnum.EXPORTING]: [],
    [ProjectStatusEnum.DONE]: [],
  };

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    public authentication: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private config: ConfigurationService,
    public projectAuthApiService: ProjectAuthApiService,
    public profileService: ProfileService,
    private artDirector: ArtDirectorService,
    private projectGeneralRouterService: ProjectGeneralRouterService
  ) {
    this.baseCdn = this.config.baseCdnUrl;
  }

  ngOnInit(): void {
    this.projects.forEach((project) => {
    this.personalizedDynamics =
      this.artDirector.extractDynamicLottieByUserBranding(project);
    });
    this.initializeTabs();
    // this.demoService.getAllRecordings();
    this.getData();
    this.cdRef.detectChanges();
  }

  getProjectsByStatus(status: string): any[] {
    console.log('this.projects', this.projects);

    if (status === 'All') {
      return this.projects;
    }
    return this.projects.filter(
      (project) => project.statuses?.general === status
    );
  }

  initializeTabs() {
    this.tabs = [
      'All',
      ProjectStatusEnum.PLANNING,
      ProjectStatusEnum.RECORDING,
      ProjectStatusEnum.EDITING,
      ProjectStatusEnum.DONE,
    ];
  }

  createNewProduction(seriesId?: string) {
    this.router.navigate([{ outlets: { panel: ['new'] } }], {
      relativeTo: this.activeRoute.parent,
    });
  }
  goToProject(project: IProjectInDTO) {
    this.projectGeneralRouterService.goToAsync(
      project.statuses.general,
      project
    );
  }
  private async getData() {
    try {
      this.loadingData = true;
      // this.productions = await this.productionsApi.getAllProductions();

      this.projectAuthApiService.getAllProjects$().subscribe({
        next: (projects) => {
          if (!projects) {
            return;
          }
          this.projects = projects;
          /// Devide projects by status and fill projectsInStatuses

          this.projects.forEach((project) => {
            // Fill the array according to the status key
            if (!this.projectsInStatuses[project.statuses?.general]) {
              this.projectsInStatuses[project.statuses?.general] = [];
            }
            this.projectsInStatuses[project.statuses?.general].push(project);
            // Also add to all projects
          });
          this.projectsInStatuses['All'] = this.projects;

          console.log(this.projectsInStatuses);

          this.dataInitialized = true;
          this.loadingData = false;
          // console.log('projects', this.projects);
        },
        error: (error) => {
          `Could not get history recordings, error: ${error}`;
        },
      });
    } catch (ex) {
      console.error('Error ocoured while trying to get episodes');
      this.loadingData = false;
    } finally {
    }
  }
}
