<div class="container">
  <h2 mat-dialog-title>Hey there!🎥🎙️</h2>
  <div mat-dialog-content>
    Make sure your camera and mic aren't busy with another app.<br />
    If they are, kindly close those apps and give Shuffll the permissions.
  </div>
  <div mat-dialog-actions>
    <div id="actions">
      <button mat-button cdkFocusInitial>Cancel</button>
    </div>
  </div>
</div>
