<h1 mat-dialog-title>Delete Scene?</h1>
<div mat-dialog-content>
  Would you like to delete the scene?<br />
  You can always chose to <b>hide it</b> instead to avoid losing data.
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>

  <span style="flex: 1 1 auto"></span>
  <!-- This spacer will push the next buttons to the right -->
  <button
    mat-button
    [mat-dialog-close]="{ operation: DeleteSceneOperation.DELETE }"
    (click)="deleteScene()">
    Delete
  </button>
  <button
    mat-raised-button
    [mat-dialog-close]="{ operation: DeleteSceneOperation.HIDE }" (click)="hideScene()"
    color="primary">
    Hide Scene
  </button>
</div>
