<div
  class="sidenav-menu"
  [ngClass]="(_isOpen | async) ? 'menu-opened' : 'menu-closed'">
  <div class="sidenav-top">
    <div class="logo-and-collapse">
      <div class="sidenav-logo" *ngIf="_isOpen | async"></div>
      <button
        mat-icon-button
        [matTooltip]="
          (_isOpen | async) ? 'Click to minimize' : 'Click to expend'
        "
        (click)="toggleIsOpen()">
        <mat-icon [ngClass]="(_isOpen | async) ? 'icon-normal' : 'icon-rotated'"
        >arrow_left-icon
        </mat-icon>
      </button>
    </div>
    <!--
                <button
                  *ngIf="this.isShuffllUser"
                  mat-button
                  class="sidenav-menu-item"
                  routerLink="/host"
                  [routerLinkActive]="'active'"
                  style="background: grey; color: white">
                  <mat-icon>videocam</mat-icon>
                  <span *ngIf="_isOpen | async">Quick Production</span>
                </button> -->

    <button
      mat-button
      class="sidenav-menu-item"
      [routerLink]="[{ outlets: { panel: ['library'] } }]"
      [routerLinkActive]="'active'">
      <mat-icon>apps</mat-icon>
      <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>My Projects</span>
    </button>

    <mat-divider></mat-divider>

    <button
      mat-button
      class="sidenav-menu-item"
      [routerLink]="[{ outlets: { panel: ['assets'] } }]"
      routerLinkActive="active">
      <mat-icon>perm_media</mat-icon>
      <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>Assets</span>
    </button>

    <button
      mat-button
      class="sidenav-menu-item"
      [routerLink]="[{ outlets: { panel: ['branding'] } }]"
      routerLinkActive="active">
      <mat-icon>brush</mat-icon>
      <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>Branding</span>
    </button>

    <button
      *ngIf="isShuffllUser"
      mat-button
      class="sidenav-menu-item"
      [routerLink]="[{ outlets: { panel: ['launch-project'] } }]"
      routerLinkActive="active">
      <mat-icon>rocket_launch</mat-icon>
      <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>Launch Code</span>
    </button>
  </div>
  <span
    *ngIf="!this.config.isProduction"
    [matTooltip]="this.config.version"
    class="version">
    {{ this.config.environment }}
    v{{ this.config.version }}
  </span>

  <!--    <button-->
  <!--            mat-button-->
  <!--            class="sidenav-menu-item"-->
  <!--            [ngStyle]="{-->
  <!--      background: toOpenWalkthrough ? 'gray' : 'none',-->
  <!--      width: toOpenWalkthrough && !_isOpen ? '150px' : 'none'-->
  <!--    }"-->
  <!--            (click)="openWalkthrough()">-->
  <!--        <div class="walkthrough-side-nav">-->
  <!--            <img-->
  <!--                    class="rocket-icon"-->
  <!--                    src="\assets\images\icons\walkthrough\rocket.png"/>-->
  <!--            <span>Get Started</span>-->
  <!--        </div>-->
  <!--    </button>-->

  <div class="sidenav-bottom">
    <mat-divider></mat-divider>

    <button
      mat-button
      class="sidenav-menu-item active feedback-button"
      [routerLink]="[{ outlets: { panel: ['settings'] } }]"
    >
      <mat-icon>settings</mat-icon>
      <span *ngIf="_isOpen | async" class="margin-lable">Settings</span>
    </button>

    <button
      *ngIf="!this.isMobileUser"
      mat-button
      class="sidenav-menu-item active feedback-button"
      (click)="openFeedbackPopUp()"
      [routerLinkActive]="'active'">
      <mat-icon>rate_review</mat-icon>
      <span *ngIf="_isOpen | async" class="margin-lable">Feedback</span>
    </button>
    <button
      mat-button
      class="sidenav-menu-item active upgrade-button"
      [featureFlag]="{
        features: ['AB_mustpay_free'],
        featureFlagLogic: 'AND',
        featureFlagMode: 'ngIf',
        negativeLogic: true,
      }"
      (click)="openPaymentsDialog()">
      <mat-icon>bolt</mat-icon>
      <span *ngIf="_isOpen | async" class="margin-lable">Upgrade</span>
    </button>
    <button
      mat-button
      class="sidenav-menu-item flex row flex-button"
      [matMenuTriggerFor]="userMenu"
      style="min-height: 60px">
      <div
        id="nav-user"
        *ngIf="authentication.isAuthenticated$ | async"
        [ngStyle]="{
          'background-image':
            'url(\'' +
            pathHelperService.getUserProfileImage(authentication.getUser()) +
            '\')',
        }"></div>
      <div id="nav-name" *ngIf="authentication.isAuthenticated$ | async">
        <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>{{
            authentication.getUser().name
          }}</span>
      </div>
    </button>
    <mat-menu #userMenu="matMenu">
      <button
        mat-menu-item
        class="user-menu-item"
        (click)="goToAdmin()"
        [routerLinkActive]="'active'"
        *ngIf="isShuffllUser">
        <mat-icon>admin_panel_settings</mat-icon>
        Admin
      </button>
      <!-- <button
                    mat-menu-item
                    [routerLinkActive]="'active'"
                    (click)="openHelp()"
                    class="user-menu-item"
                  >
                    <mat-icon>help</mat-icon>
                    How To
                  </button> -->
      <button
        mat-menu-item
        class="user-menu-item"
        (click)="
          authentication.logout('/dashboard');
          gtmService.notifyEvent('Navbar - Log Out')
        "
        [routerLinkActive]="'active'">
        <mat-icon>power_settings_new</mat-icon>
        Log Out
      </button>
    </mat-menu>
  </div>
</div>
