import * as mediasoup from 'mediasoup-client';
import { LottieMarkerTypes } from './lottie/lottie-defines';
import {
  DynamicItemType,
  IDynamicIdentifier,
  IStagePosition,
} from 'lottie-json-helper/lib/types';
import { ICommonSceneData, IScene } from './project/scene-model';
import { IVideoEditTake } from './project/edit/edit-model';
import { VideoEditTake } from './job/edit-job-schema';

export enum MAT_ICON {
  DOWNLOAD = 'download',
  GRAPHIC_EQ = 'graphic_eq',
  COPY = 'content_copy',
}

export enum CreativeStatusEnum {
  STRUCTURE = 'STRUCTURE',
  DONE = 'DONE',
  GENERATING_IMAGES = 'GENERATING_IMAGES',
}

export enum TranscriptStatusEnum {
  NONE = 'NONE',
  GENERATING_SUBTITLES = 'GENERATING_SUBTITLES',
  DONE = 'DONE',
}

export interface ICreative {
  prompt: string;
  videoTagId: string;
  status?: CreativeStatusEnum;
}

export type KeysMatching<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never;
}[keyof T];

/**
 * Never change the ORDER of the enums!
 */
export enum ProjectStatusEnum {
  PLANNING = 'planning',
  RECORDING = 'recording',
  EDITING = 'editing',
  EXPORTING = 'exporting',
  DONE = 'done',
}

export enum Button_USE_CASE {
  DOWNLOAD = 'download',
  COPY = 'copy',
}

export enum ROLE {
  HOST = 'Host',
  AUDIENCE = 'Audience',
  PARTICIPANT = 'Participant',
  VIDEO = 'Video',
  RTMP = 'Rtmp',
  SERVER = 'Server',
}

export enum CONNECT_VIDEO_STATUS {
  Null = 'null',
  Requested = 'requested',
  Connected = 'connected',
}

export interface SerializedPeer {
  id: string;
  displayName: string;
  role: ROLE;
  picture?: string;
  userId?: string;
}

export interface StageOptions {
  stageCamEnabled: boolean;
  stageMicEnabled: boolean;
  softPosition: number;
  hardPosition: number;
  streamId?: string;
}

export interface IPathAndDashAndAutoplay {
  videoSource: IPathAndDash;
  autoPlay: boolean;
}

export class SflPeer {
  public id: string;
  public displayName: string;
  public role = ROLE.AUDIENCE;
  public platform: string;
  public picture: string;
  public connectVideoStatus? = CONNECT_VIDEO_STATUS.Null;
  public camStream?: SflMedia;
  public screenStream?: SflMedia;
  public mediaStream?: SflMedia;
  public videoAndAutoplay?: IPathAndDashAndAutoplay = {
    videoSource: { uploadPath: '', dashPath: '' },
    autoPlay: false,
  };
  public userId?: string;
  public userShuffllToken?: string;
  public participationToken?: string; // When asked to participate we can get a token from the host to aprove automatically
  public approvedByHost?: boolean;

  constructor(
    id: string = null,
    displayName: string = null,
    role: ROLE = null,
    platform: string = null,
    picture: string = null
  ) {
    this.id = id;
    this.displayName = displayName;
    this.role = role;
    this.platform = platform;
    this.picture = picture;
  }

  getSerializedPeer(): SerializedPeer {
    return {
      id: this.id,
      displayName: this.displayName,
      role: this.role,
      picture: this.picture,
      userId: this.userId,
    };
  }
}

export class MediaPositions {
  public id: string; //
  public mediaSource: SflMediaSource;
  public stageOptions: StageOptions = {
    stageCamEnabled: true,
    stageMicEnabled: true,
    softPosition: null,
    hardPosition: null,
  };

  getPosition() {
    let calculatedPosition =
      this.stageOptions.hardPosition ?? this.stageOptions.softPosition;
    return calculatedPosition + 1; // So it will be readable
  }

  createStageOptionsNotification(): StageOptions {
    return { ...this.stageOptions, streamId: this.id };
  }

  public mixStrings(string1: string, string2: string): string {
    const minLength = Math.min(string1.length, string2.length);
    let mixedString = '';

    for (let i = 0; i < minLength; i++) {
      mixedString += string1.charAt(i) + string2.charAt(i);
    }

    if (string1.length > string2.length) {
      mixedString += string1.slice(minLength);
    } else if (string2.length > string1.length) {
      mixedString += string2.slice(minLength);
    }

    return mixedString;
  }
}

export class SflPeerAndSource extends MediaPositions {
  public peer: SflPeer;

  constructor(peer: SflPeer, mediaSource: SflMediaSource) {
    super();
    this.peer = peer;
    this.mediaSource = mediaSource;
    this.id = this.mixStrings(peer.id, mediaSource);
  }
}

export class SflVideo extends SflPeerAndSource {
  public canBePlayed: boolean = false;

  constructor(peer: SflPeer, mediaSource: SflMediaSource) {
    super(peer, mediaSource);
  }
}

export enum DirectorCommand {
  NEXT = 'NextBit',
  SKIP_BACK = 'SkipBackBit',
  RESTART_CURRENT = 'RestartCurrentBit',
  RESTART_SHOW = 'RestartShow',
  PAUSE = 'PauseBit',
  STOP_PAUSE = 'stop_pause', ///When paused pressed again, but we open a dialog to choose what to do next
  GO_TO_SPECIFIC_SCENE = 'specific-scene',
  CONTINUE = 'continue', ///Continue current take . not working right now
}

export enum VideoLayoutTypes {
  Custom = 'custom',
}

export interface Asset {
  name?: string;
  type?: 'svg' | 'png' | 'json';
  loops?: number;
  forBitId?: string;
}

export interface ILayout {
  lottiePath: string;
  _id: string;
  customLayoutId?: string;
  dynamicClientLayerId?: string;
}

export class SflMessage {
  constructor(
    public id: string,
    public peer: SerializedPeer,
    public who: 'partner' | 'me',
    public message: string,
    public recvTime: Date,
    public sendStatus: string,
    public platform: 'Shuffll' | 'Youtube' | 'Twitch' = 'Shuffll'
  ) {}
}

export class SflFile {
  constructor(
    public name: string,
    public size: number,
    public blob: Blob
  ) {}
}

export class SflMedia extends MediaStream {
  public videoConsumer?: mediasoup.types.Consumer;
  public audioConsumer?: mediasoup.types.Consumer;
  public peer?: SflPeer;
  public source?: string;
  public volume?: number;
  public producerScore?: number[] = [];
  public consumerScore?: number[] = [];
  public scoreIndex?: string[] = [];
  public toggleSide = false;
  public size = 6;

  constructor();

  constructor(media: MediaStream);

  constructor(media?: MediaStream) {
    super();
  }
}

export enum RoomStatus {
  started = 'started',
  stopped = 'stopped',
}

export class Showroom {
  status: RoomStatus;
  startTime: number;
  stopTime: number;
  mutedAudio = false;
  mutedVideo = false;

  constructor() {}
}

export class SflRoomInfo {
  id: string;
  name: string;
  description;
  createTime;
  lastActiveTime;
  attendeePassword;
  speakerPassword;

  constructor() {}
}

export interface IAsset extends IPathAndDash {
  _id?: string;
  folder: string;
  displayName?: string;
  lazyLoad?: boolean;
  userEmail: string;
  type?: AssetType;
  mimetype?: Mimetype;
}

export interface IVideoAssetAndFlag {
  videoAsset: IAsset;
  autoPlay: boolean;
}

export interface EmojiDialogData {
  animal: string;
  name: string;
}

export const VIDEORESOLUTION = [
  {
    width: 1920,
    height: 1080,
  },
  {
    width: 1280,
    height: 720,
  },

  {
    width: 640,
    height: 480,
  },
];

export const SIMULCASTENCODING: RTCRtpEncodingParameters[] = [
  { maxBitrate: 100000, rid: 'idk' },
  { maxBitrate: 300000, rid: 'idk' },
  { maxBitrate: 900000, rid: 'idk' },
];

export const SCREENSHARE_CONSTRAINTS = {
  video: {
    cursor: 'always',
  },
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    sampleRate: 44100,
  },
};

export enum RequestMethod {
  iCanPlayVideo = 'iCanPlayVideo',
  iCantPlayVideo = 'iCantPlayVideo',
  videoPlayable = 'videoPlayable',
  videoNotPlayable = 'videoNotPlayable',
  getRouterRtpCapabilities = 'getRouterRtpCapabilities',
  join = 'join',
  createWebRtcTransport = 'createWebRtcTransport',
  connectWebRtcTransport = 'connectWebRtcTransport',
  restartIce = 'restartIce',
  produce = 'produce',
  closePeerProducers = 'closePeerProducers',
  closeProducer = 'closeProducer',
  pauseProducer = 'pauseProducer',
  resumeProducer = 'resumeProducer',
  pauseConsumer = 'pauseConsumer',
  resumeConsumer = 'resumeConsumer',
  requestConsumerKeyFrame = 'requestConsumerKeyFrame',
  getProducerStats = 'getProducerStats',
  getConsumerStats = 'getConsumerStats',
  getTransportStats = 'getTransportStats',
  changeDisplayName = 'changeDisplayName',
  changePicture = 'changePicture',
  changeRole = 'changeRole',
  chatMessage = 'chatMessage',
  closePeer = 'closePeer',
  prompterUpdate = 'prompterUpdate',

  syncDocInfo = 'syncDocInfo',

  showStart = 'showStart',
  showStop = 'showStop',
  endScene = 'endScene',
  notifyTake2 = 'notifyTake2',

  roomInfo = 'roomInfo',
  changeLogo = 'changeLogo',
  announcementText = 'announcementText',
  videoFilter = 'videoFilter',

  connectVideo = 'connectVideo',
  connectApproval = 'connectApproval',
  disconnectVideo = 'disconnectVideo',

  switchComponent = 'switchComponent',

  muted = 'muted',
  unmuted = 'unmuted',

  askToParticipate = 'askToParticipate',
  respondToParticipationRequest = 'respondToParticipationRequest',

  invokeControlAction = 'invokeControlAction',
  invokeBit = 'invokeBit',
  invokeAction = 'invokeAction',
  invokePositionUpdate = 'invokePositionUpdate',
  invokePositionStageOptionUpdate = 'invokePositionStageOptionUpdate',
  dynamicValuesChanged = 'dynamicValuesChanged',
  transitionModeChanged = 'transitionModeChanged',
  newCompositionInTimeline = 'newCompositionInTimeline',

  goToScene = 'goToScene',
  bitStartedLoading = 'bit_start_loading',
  bitFinishedLoading = 'bit_finished_loading',
}

export enum TakeStartEnd {
  START = 'start',
  END = 'end',
}

export enum SflBoardComp {
  video = 'video',
  welcome = 'welcome',
  document = 'document',
  whiteboard = 'whiteboard',
  sharescreen = 'sharescreen',
  sharemedia = 'sharemedia',
}

export enum SflMediaSource {
  cameramic = 'cameramic',
  screen = 'screen',
  media = 'media',
}

export enum SflColor {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  light = 'light',
  medium = 'medium',
  dark = 'dark',
}

export class SflDocument {
  public id: number;
  public roomId: string;
  public uploadTime: string;
  public opened = false;

  constructor(public fileName: string) {}
}

export const makeRandomString = (length: number): string => {
  let outString = '';
  const inOptions = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));
  }

  return outString;
};

export const getImageMeta = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
};

export enum ActionControl {
  PLAY,
  PAUSE,
  RESTART,
  FORWARD5,
  BACKWARD5,
  SHOW,
  HIDE,
  SPIN,
  FILL,
  SETUP,
}

export enum StreamStatus {
  CREATED = 'created',
  STARTING = 'starting',
  ON_AIR = 'on_air',
  DRY_RUN = 'dry_run',
  PAUSED = 'paused',
  ERROR = 'error',
  SCHEDULED = 'scheduled',
  FINISHED = 'finished',
}

export enum Backgrounds {
  REGULAR = 'regular',
  SPARKS = 'sparks',
  DATING = 'dating',
}

export interface DynamicValuesChangeMessage {
  dynamicClientLayerId: string;
  dynamicValues: IDynamicText[] | IDynamicIdentifier[];
}

export interface PeerAndSourcePosition {
  peerAndSourceId: string;
  softPosition: number;
  hardPosition: number;
}

export interface PositionUpdate {
  positions: PeerAndSourcePosition[];
  // screenShares: string[];
  // media: string[];
}

export enum ExportStatus {
  READY = 'Ready',
  NOT_READY = 'Not Ready',
}

export interface IExportVideos {
  originalExported: IPathAndDash;
  exportedWithAudioClean: IPathAndDash;
}

export interface IPathAndDash {
  dashPath?: string;
  uploadPath?: string;
}

export interface ILottieJsonMarker {
  cm: string;
  dr: number;
  tm: number;
}

export interface ILottieMarker {
  startTimeInFrames: number;
  durationInFrames: number;
  type: LottieMarkerTypes;
}

export interface IStartLoopMarkers {
  startMark?: ILottieMarker;
  loopMark?: ILottieMarker;
}

export enum UploadType {
  FLV = 'flv',
  AVI = 'avi',
  MP4 = 'mp4',
  MKV = 'mkv',
  FUSEDMKV = 'fused.mkv',
  FUSEDMP4 = 'fused.mp4',
  FUSED_WITH_BACKGROUND_MUSIC_MP4 = 'fusedWithBGmusic.mp4',
  CLEANED_WITH_BACKGROUND_MUSIC_MP4 = 'cleanedWithBGmusic.mp4',
  CLEANED = 'clean.mp4',
  SEGMENTS = 'segments',
  THUMBNAILS = 'thumbnails',
}

export interface ICustomLayout extends Document {
  name: string;
  stagePositions?: IStagePositionsExtended[];
}

export interface IStagePositionsExtended extends IStagePosition {
  id: string;
}

export interface IVideoThumbnails {
  thumbnailsPath: string;
  numberOfThumbnails: boolean;
}

export enum MimeTypeEnum {
  ApplicationJson = 'application/json',
  ApplicationPdf = 'application/pdf',
  ApplicationXml = 'application/xml',
  ApplicationZip = 'application/zip',
  AudioMpeg = 'audio/mpeg',
  AudioOgg = 'audio/ogg',
  ImageGif = 'image/gif',
  ImageJpeg = 'image/jpeg',
  ImagePng = 'image/png',
  TextCss = 'text/css',
  TextCsv = 'text/csv',
  TextHtml = 'text/html',
  TextPlain = 'text/plain',
  VideoMp4 = 'video/mp4',
  VideoOgg = 'video/ogg',
  VideoWebm = 'video/webm',
  AudioWebm = 'audio/webm',
  AudioMp4 = 'audio/mp4',
  OCTECT_STREAM = 'application/octet-stream',
}

export enum VideoRecommend {
  FORCE = 'force',
  POSSIBLE = 'possible',
}

export interface DynamicAsset {
  id: string;
  path: string;
  content?: string;
  width: number;
  height: number;
  dynamics: IDynamicIdentifier[];
}

export interface IDynamicText {
  id?: string;
  newText?: string | Array<number>; //either text nor array of numbers for rgb
  layerObject?: any;
  ai?: boolean;
  ai_type?: DynamicItemType;
  ai_index?: number;
}

//Because when a participant enter, he search for dynamic values the host gave him, those values will be applied to every
//Bit because the names are the same (sfl_gradient0), then he will search to see if this existed in the dynamicValuesForHost
//He will see it does and will apply this change -> even though it is from other scene
export interface IHostDynamicObject extends IDynamicText {
  dynamicText: IDynamicText[];
  forScene?: string;
}

export interface IBackgroundMusic {
  _id?: string;
  folder?: string;
  displayName: string;
  filePath: string;
  mimetype?: string;
  duration?: number;
}

export enum AssetType {
  FOLDER = 'folder',
  IMAGE = 'image',
  VIDEO = 'video',
  Audio = 'audio',
}

export enum Mimetype {
  VIDEO = 'video/mp4',
  PNG = 'image/png',
  JPG = 'image/jpg',
  SVG = 'image/svg+xml',
  JPEG = 'image/jpeg',
}

export interface IPostMessageData {
  identifier: string;
  eventType?: string;
  args?: any;
}

export enum GoalType {
  General = 'General',
  Awareness = 'Awareness',
  Advocacy = 'Advocacy',
  Conversion = 'Conversion',
  Engagement = 'Engagement',
  Educate = 'Educate',
}

export interface VideoCue extends TextTrackCue {
  left?: any;
  right?: any;
  type?: string;
}

declare var VideoCue: {
  prototype: VideoCue;
  new (startTime: number, endTime: number, text: string): VideoCue;
};

export interface PrompterMessage {
  type: string;
  from: string;
  payload: any;
}

export interface IPublication extends Document {
  publicationId: string;
  title: string;
  videos: { path: string; title?: string; thumbnail?: string }[];
  files?: { path: string; title: string }[];
}

export interface IOnBoardingQuestionConfigs {
  id: string;
  value?: string;
  hubspotPropertyId: string;
}

export interface IOnboardingQuestion extends IOnBoardingQuestionConfigs {
  type: QuestionTypeEnum;
  options?: string[];
  innerLabel?: string;
  text?: string;
}

export interface IOnboardingStep {
  id?: string;
  stepTitle: string;
  stepSubTitle?: string;
  stepDescription?: string;
  topIcon?: string;

  questions: IOnboardingQuestion[];
  hideInUI?: boolean;
}

export enum QuestionTypeEnum {
  SELECT = 'select',
  TEXT = 'text',
  EMAIL = 'email',
  TEXT_AREA = 'text-area',
}

export interface IQuestion {
  id: string;
  type: QuestionTypeEnum;
  text?: string;
  innerLabel?: string;
  value?: string;
  options?: string[];
  defaultValue?: string;
}

export interface IStep {
  stepTitle: string;
  topIcon: string;
  questions: IQuestion[];
}

export interface IOnBoardingConfig {
  steps: IOnboardingStep[];
}

export interface IVideoTag {
  id: string;
  text: string;
  title: string;
}

export interface IVideoTagsConfig {
  tags: IVideoTag[];
}

export interface ITagAndSuggestions {
  tag: IVideoTag;
  suggestions: string[];
}

export enum ExportQualityEnum {
  P1080 = 1080,
  P720 = 720,
  P480 = 480,
}

export interface ISceneAndEditTakes {
  scene: IScene;
  videoScenes: VideoEditTake[];
}

/// Using fake detection interface because when triggering a false into false, the child component detect changes not being triggered .
export interface IFakeDetection {
  isPlaying: boolean;
  randomNumber: number;
}

export interface IFeedbackQuestion extends IFeedbackQuestionConfigs {
  id: string;
  type: QuestionTypeEnum;
  text?: string;
  options?: string[];
}

export enum FeedbackEnum {
  GENERAL_FEEDBACK = 'General Feedback',
  EXPORT_FEEDBACK = 'Export Feedback',
}

export interface IFeedbackForm {
  id?: string;
  feedbackTitle: string;
  questions: IFeedbackQuestion[];
}

export interface IFeedbackQuestionConfigs {
  question_id: string;
  value?: string;
}

export interface IFeedbackConfigs {
  feedbackForms: IFeedbackForm[];
}

export enum CompositionVirtualEnum {
  SETUP = 'setup',
  Normal = 'normal',
  SEGMENT_START = 'segment-start',
}

export interface IComposition {
  _id: string;
  layouts: ILayout[]; /// might be few layouts per template (currently only 1), meaning few lotties .
  virtual: CompositionVirtualEnum;
  name: string;
  type?: string;
  description: string;
  aspectRatio?: string;
  duration?: number;
}

export enum GenericStatusEnum {
  NONE = 'none',
  INITIAL = 'initial',
  PENDING = 'pending',
  STARTED = 'started',
  FAILED = 'failed',
  DONE = 'done',
}

// LAUNCH DEMO DEFINES

export interface IquestionBank {
  question: string;
  shortQuestion: string;
}

export enum ILaunchStepEnum {
  WELCOME = 'welcome',
  USER_INFO = 'user_info',
  SURPRISE = 'surprise',
  SHOW_QUESTION = 'show_question',
  CHALLENGE = 'challenge',
  FINISH = 'finish',
  BRANDING = 'branding',
  GENERATE_QUESTION = 'generateQuestion',
}

export interface ILaunchFormQuestionConfigs {
  id: string;
  value?: string | number;
}

export interface ILaunchFormQuestion extends ILaunchFormQuestionConfigs {
  type: QuestionTypeEnum;
  options?: string[];
  innerLabel?: string;
  importButton?: boolean;
  text?: string;
}

export interface ILaunchFormStep {
  id?: ILaunchStepEnum;
  stepTitle?: string;
  body?: string;
  stepSubTitle?: string;
  stepDescription?: string;
  midTitle: string;
  videoHLS?: string;
  videoMP4?: string;
  topIcon?: string;
  questionsBank?: IquestionBank[];
  questions?: ILaunchFormQuestion[];
  hideInUI?: boolean;
}

export interface IDefaultLaunchBrand {
  logoUrl: string;
  colors: {
    colorPrimary: string;
    colorSecondary: string;
    colorExtra: string;
  };
  company: {
    id?: string;
    name?: string;
    domain?: string;
  };
}

export interface ILaunchFormConfig {
  steps: ILaunchFormStep[];
  defaultBranding: IDefaultLaunchBrand;
}
