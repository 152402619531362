import { MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { baseDetailsTakeUrl } from './take.urls';

const VIDEO_LAYER_URL = '/video-layers';
const VIDEO_LAYER_APPEND_CHUNK_URL = '/append-chunk';
const VIDEO_LAYER_UPDATE_TRIMS_URL = '/trims';
// export function getEnhanceVideoLayersPOST(
//   projectId: string,
//   sceneId: string,
//   takeId: string
// ) {
//   const url = getBasedVideoLayerUrl(projectId, sceneId, takeId);

//   return `${url}/enhance`;
// }

export function getAudioCleanVideoLayersPOST(
  projectId: string,
  sceneId: string,
  takeId: string
) {
  const url = getBasedVideoLayerUrl(projectId, sceneId, takeId);

  return `${url}/audioClean`;
}

export function getSegmentationVideoLayersPOST(
  projectId: string,
  sceneId: string,
  takeId: string
) {
  const url = getBasedVideoLayerUrl(projectId, sceneId, takeId);

  return `${url}/videoSegmentation`;
}

export function getVideoLayerPOST(
  projectId: string,
  sceneId: string,
  takeId: string
) {
  const url = getBasedVideoLayerUrl(projectId, sceneId, takeId);

  return `${url}/`;
}

export function getAppendChunkToVideoLayerUrlPOST(
  projectId: string,
  sceneId: string,
  takeId: string,
  videoLayerId: string
) {
  const videoSpecifiedUrl = getVideoLayerSpecificUrl(
    projectId,
    sceneId,
    takeId,
    videoLayerId
  );

  return `${videoSpecifiedUrl}${VIDEO_LAYER_APPEND_CHUNK_URL}`;
}

export function updateVideoLayerTrimsUrlPUT(
  projectId: string,
  sceneId: string,
  takeId: string,
  videoLayerId: string
) {
  const videoSpecifiedUrl = getVideoLayerSpecificUrl(
    projectId,
    sceneId,
    takeId,
    videoLayerId
  );

  return `${videoSpecifiedUrl}${VIDEO_LAYER_UPDATE_TRIMS_URL}`;
}

export function getVideoLayerSpecificUrl(
  projectId: string,
  sceneId: string,
  takeId: string,
  videoLayerId: string
) {
  if (!projectId || !sceneId || !takeId || !videoLayerId) {
    throw new MissingArgumentsError(
      `Could not get specific scene url because one of the arguments is null is null`
    );
  }

  const generalSceneUrl = getBasedVideoLayerUrl(projectId, sceneId, takeId);
  return `${generalSceneUrl}/${videoLayerId}`;
}

function getBasedVideoLayerUrl(
  projectId: string,
  sceneId: string,
  takeId: string
) {
  const takeUrl = baseDetailsTakeUrl(projectId, sceneId, takeId);
  return `${takeUrl}${VIDEO_LAYER_URL}`;
}
