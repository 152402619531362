import { Injectable } from '@angular/core';
import { SocketEventWrapperService } from '../socket-event-wrapper.service';
import {
  IProjectMainClientToServer,
  IProjectMainServerToClientEvents,
} from '../../../models/socket-events/project/socket-project-main-events';

@Injectable()
export class ProjectMainEventsService {
  constructor(
    private socketEventWrapper: SocketEventWrapperService<
      IProjectMainClientToServer,
      IProjectMainServerToClientEvents
    >
  ) {}

  joinProjectRoom(projectId: string) {
    this.socketEventWrapper.emit('joinProjectRoom', {
      projectId: projectId,
    });
  }

  disconnectFromRoom(projectId: string) {
    this.socketEventWrapper.emit('disconnectFromProjectRoom', {
      projectId: projectId,
    });
  }
}
