import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { VoiceRecognitionService } from '../../../../services/voice-recognition.service';
import { ITextPortion } from '../../../../models/Teleprompter';

@Component({
  selector: 'app-dummy-text',
  templateUrl: './dummy-text.component.html',
  styleUrls: ['./dummy-text.component.scss'],
})
export class DummyTextComponent implements OnInit, OnDestroy {
  @Input({ alias: 'teleprompterSettings', required: true }) teleprompterSettings: any;
  @Input({ alias: 'isRecording', required: true }) isRecording: boolean;
  @Output('dummy-text-changed') dummyTextChanged = new EventEmitter<string>();
  @Output('dummy-edit-mode') dummyEditMode = new EventEmitter<boolean>();
  @Output('dummy-hover-status') hoverStatus = new EventEmitter<boolean>();
  manualText: string;
  isEditMode = false;
  dummyText: ITextPortion[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    public voiceRecognition: VoiceRecognitionService) {
  }

  ngOnInit() {
    this.voiceRecognition.notifySplitTextProcessed
      .pipe(takeUntil(this.onDestroy$))  // Automatically unsubscribes on destroy
      .subscribe((processedText) => {
        if (!processedText) {
          return;
        }
        this.dummyText = Array.from(processedText.ai_text.values());
        if (!this.isEditMode) {
          this.manualText = this.dummyText.map(item => {
            return item.text;
          }).join('');
        }
      });
  }

  onTextareaBlur() {
    this.isEditMode = false;
    this.dummyEditMode.emit(this.isEditMode);
    this.dummyTextChanged.emit(this.manualText);
  }

  onHoverStart() {
    this.hoverStatus.emit(true);  // Emit 'true' when hover starts
  }

  onHoverEnd() {
    this.hoverStatus.emit(false); // Emit 'false' when hover ends
  }

  onTextChange() {
    this.dummyTextChanged.emit(this.manualText);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.isRecording) {
      return;
    }
    this.isEditMode = this.elementRef.nativeElement.contains(event.target);
    this.hoverStatus.emit(this.isEditMode); // Emit 'false' when hover ends
    this.dummyEditMode.emit(this.isEditMode);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
