import { Component } from '@angular/core';
import { DirectorCommand } from 'src/app/models/defines';
import { MatDialogRef } from '@angular/material/dialog';

interface ICommandOptions {
  command: DirectorCommand;
  text: string;
}

@Component({
  selector: 'app-pause-dialog',
  templateUrl: './pause-dialog.component.html',
  styleUrls: ['./pause-dialog.component.scss'],
})
export class PauseDialogComponent {
  constructor(public dialogRef: MatDialogRef<PauseDialogComponent>) {}
  commandOptions: ICommandOptions[] = [
    {
      command: null,
      text: 'Close',
    },
    // {
    //   command: DirectorCommand.CONTINUE,
    //   text: 'Continue Current Take',
    // },
    {
      command: DirectorCommand.RESTART_CURRENT,
      text: 'Restart Current',
    },
    {
      command: DirectorCommand.NEXT,
      text: 'Next Scene',
    },
  ];

  onSelectCommand(command: DirectorCommand) {
    this.dialogRef.close(command);
  }
}
