import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from 'src/app/models/dialog-wrapper.model';

@Component({
    selector: 'app-generate-image',
    templateUrl: './generate-image.component.html',
    styleUrls: ['./generate-image.component.scss']
})
export class GenerateImageComponent {
    prompt = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.prompt = data && data['prompt'] ? data['prompt'] : '';
    }

}
