import { Component } from '@angular/core';

@Component({
  selector: 'app-assets-main',
  templateUrl: './assets-main.component.html',
  styleUrls: ['./assets-main.component.scss']
})
export class AssetsMainComponent {

}
