<div id="wrapper">
  <div *ngIf="devicesPermissions$ | async; else noPermission">
    <div>
      <h2 *ngIf="data?.title" class="title">
        {{ data.title }}
      </h2>

      <div class="preview-wrapper">
        <video
          *ngIf="(recordingManager.mediaModel$ | async)?.mediaStream"
          [srcObject]="(recordingManager.mediaModel$ | async)?.mediaStream"
          autoplay
          class="user-main-video"
          [muted]="'true'"
          playsinline
          slot="end"></video>
      </div>

      <div *ngIf="mediaDevicesService.cameras$ | async as cameras">
        <mat-form-field>
          <mat-label>Video Input</mat-label>
          <mat-select
            (ngModelChange)="selectVideoDevice($event)"
            [(ngModel)]="selectedVideoDevice">
            <mat-option *ngFor="let device of cameras" [value]="device">
              {{ device.cleanedLabel }}
            </mat-option>
          </mat-select>
          <mat-icon matPrefix> videocam</mat-icon>
        </mat-form-field>
      </div>
      <div
        *ngIf="!(mediaDevicesService.cameras$ | async)?.length"
        id="no-video">
        <mat-label color="error">
          Hey! We noticed you didn't give us permissions to fetch your input
          devices. <br />
          Come on, don't be shy! It's the first step to becoming a star 😊
        </mat-label>
      </div>

      <div *ngIf="mediaDevicesService.microphones$ | async as microphones">
        <mat-form-field>
          <mat-label>Audio Input</mat-label>
          <mat-select
            (ngModelChange)="selectAudioDevice($event)"
            [(ngModel)]="selectedAudioDevice">
            <mat-option *ngFor="let device of microphones" [value]="device">
              {{ device.cleanedLabel }}
            </mat-option>
          </mat-select>

          <app-voice-detector-icon matPrefix></app-voice-detector-icon>
        </mat-form-field>
      </div>

      <div align="end" mat-dialog-actions>
        <button (click)="closeDialog()" cdkFocusInitial mat-button>
          Close
        </button>
      </div>
    </div>
  </div>

  <ng-template #noPermission>
    <app-devices-permissions></app-devices-permissions>

    <!-- <p>Please grant permissions to access your media devices.</p> -->
  </ng-template>
</div>
