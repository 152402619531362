import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AnalyticsNotifierService } from 'src/app/services/utils/analytics-notifier.service';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrl: './welcome-dialog.component.scss',
})
export class WelcomeDialogComponent implements OnInit {
  WELCOME_VIDEO_SUB_DIR = '/files/platform/welcome-video';
  hlsPath;
  mp4Path;
  finalVideoPath;
  posterPath;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { showVideo: boolean },
    private analyticsNotfier: AnalyticsNotifierService,
    private config: ConfigurationService,
    public dialogRef: MatDialogRef<WelcomeDialogComponent>
  ) {
    this.hlsPath =
      config.baseCdnUrl + this.WELCOME_VIDEO_SUB_DIR + '/index.m3u8';

    this.mp4Path =
      config.baseCdnUrl + this.WELCOME_VIDEO_SUB_DIR + '/welcome.mp4';

    this.posterPath =
      config.baseCdnUrl + this.WELCOME_VIDEO_SUB_DIR + '/welcome-poster.jpg';

    // plansService.getUnifiedPlan();
    // if (this.data.showVideo === false) {
    //   this.showPricing = true;
    // } else {
    //   this.showPricing = false;
    // }
  }
  ngOnInit(): void {
    if (this.isHLSSupported()) {
      this.finalVideoPath = this.hlsPath;
      console.log('playing hls welcome video');
    } else {
      this.finalVideoPath = this.mp4Path;
      console.log('playing mp4 welcome video');
    }
  }

  @Output()
  newSeekInMillisEmitter = new EventEmitter<number>();
  // showPricing: boolean = false;

  public next() {
    // this.showPricing = true;
  }

  public supportsHLS() {
    var video = document.createElement('video');
    return Boolean(
      video.canPlayType('application/vnd.apple.mpegURL') ||
        video.canPlayType('audio/mpegurl')
    );
  }

  public closeDialog() {
    // if (isSuccessPayment === true) {
    //   this.dialogRef.close({
    //     success: isSuccessPayment,
    //   });
    //   // localStorage.setItem(WELCOME_DIALOG_SHOWN_KEY, 'true');
    // }

    this.dialogRef.close();
  }

  public onPlayWelcomeVideo() {
    this.analyticsNotfier.pushTag({
      event: 'watch video',
    });
  }

  isHLSSupported(): boolean {
    const video = document.createElement('video');
    return Boolean(
      video.canPlayType('application/vnd.apple.mpegURL') ||
        video.canPlayType('audio/mpegurl')
    );
  }
}
