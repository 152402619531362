import { Injectable } from '@angular/core';
import {
  makeRandomString,
  MimeTypeEnum,
  ROLE,
  SflBoardComp,
  SflColor,
  SflPeer,
  SflRoomInfo,
} from '../../models/defines';
import { AuthenticationService } from '../guards/authentication.service';
import { BehaviorSubject, filter, take } from 'rxjs';
import { IBranding, User } from '../../models/user';
import mixpanel from 'mixpanel-browser';
import { ConfigurationService } from '../configuration.service';
import LogRocket from 'logrocket';
import { InAppNotificationService } from 'src/app/pages/private/host/services/in-app-notification.service';
import { AnalyticsNotifierService } from '../utils/analytics-notifier.service';

export interface IDeviceConfigs {
  id: string;
  // permission:
}

export type UserBrowserType =
  | 'Mozilla Firefox'
  | 'Opera'
  | 'Google Chrome'
  | 'Safari'
  | 'Internet Explorer'
  | 'Unknown';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  readonly userBrowser: UserBrowserType;
  public didRecordLocalStorageKey = 'First-Record';

  public roomId = 'NOT SET';
  public forceTcp = false;
  public useSimulcast = false;
  public videoNav = true;

  public color = SflColor.medium;

  public bLogin = false;
  public themeDark = false;
  public boardComponent = SflBoardComp.video;

  public roomInfo: SflRoomInfo; // not showroom
  public user$ = new BehaviorSubject<User>(null);

  public videoDeviceConfigs: string;
  public audioDeviceConfigs: string;
  public mainVideoResolution: number = 0;
  public userPeer = new SflPeer();
  public language = 'en-US';

  public role$ = new BehaviorSubject<ROLE>(null);
  //use for data how many minutes passed from login
  public sessionTimeInMinutes$ = new BehaviorSubject<number>(0);

  public privilegeAll = {
    [ROLE.HOST]: {
      pubCamera: true,
      pubAudio: true,
      showControl: true,
      shareMenu: true,
      shareDocument: true,
      shareDesktop: true,
      shareMedia: true,
      draw: true,
    },
    [ROLE.AUDIENCE]: {
      pubCamera: true,
      pubAudio: true,
      showControl: false,
      shareMenu: false,
      shareDocument: false,
      shareDesktop: true,
      shareMedia: false,
      draw: false,
    },
    [ROLE.PARTICIPANT]: {
      pubCamera: true,
      pubAudio: true,
      showControl: false,
      shareMenu: false,
      shareDocument: false,
      shareDesktop: true,
      shareMedia: false,
      draw: false,
    },
    [ROLE.RTMP]: {
      pubCamera: false,
      pubAudio: false,
      showControl: false,
      shareMenu: false,
      shareDocument: false,
      shareDesktop: true,
      shareMedia: false,
      draw: false,
    },
  };

  private readonly supportedCodecMimetype: string;
  private readonly supportedBaseMimetype: MimeTypeEnum;

  public get baseMimetype() {
    return this.supportedBaseMimetype;
  }

  public get codecMimetype() {
    return this.supportedCodecMimetype;
  }

  constructor(
    private auth: AuthenticationService,
    private config: ConfigurationService,
    private hostNotifications: InAppNotificationService,
    private analyticsNotifier: AnalyticsNotifierService
  ) {
    this.userBrowser = this.getBrowser();
    try {
      const { baseMimetype, codecs } = this.checkCodecSupport();
      this.supportedBaseMimetype = baseMimetype;
      this.supportedCodecMimetype = `${baseMimetype};codecs=${codecs}`;
    } catch (error) {
      console.error(`Could not get mimetypes for user`);
    }

    this.userPeer.id = this.genPeerId();

    /// Register to auth changes to update the user details if they change
    this.auth.isAuthenticated$.subscribe((newState) => {
      if (newState !== null) {
        this.updateUserDetails();
      }
    });

    this.language = navigator.language;
  }

  private getBrowser(): UserBrowserType {
    const userAgent = navigator.userAgent;
    let browserName: UserBrowserType = 'Unknown';

    switch (true) {
      case userAgent.indexOf('Firefox') > -1:
        browserName = 'Mozilla Firefox';
        break;
      case userAgent.indexOf('OPR') > -1 || userAgent.indexOf('Opera') > -1:
        browserName = 'Opera';
        break;
      case userAgent.indexOf('Chrome') > -1:
        browserName = 'Google Chrome';
        break;
      case userAgent.indexOf('Safari') > -1 &&
        userAgent.indexOf('Chrome') === -1:
        browserName = 'Safari';
        break;
      case userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1:
        browserName = 'Internet Explorer';
        break;
      default:
        browserName = 'Unknown';
    }

    return browserName;
  }

  updateUserDetails() {
    const user = this.auth.getUser();
    if (!user) return;

    this.user$.next(user);

    this.userPeer.displayName = user.name ? user.name : this.userPeer.id;
    this.userPeer.picture = user.profileImgUrl;
    this.userPeer.userId = user._id;
    if (user.token) {
      this.userPeer.userShuffllToken = user.token;
    }

    this.analyticsNotifier.identify(user);
    if (this.config.trackUserEvents) {
      /// Only identifying after the mixpanel has initalized
      /// Take until we identify once.
      this.hostNotifications.mixPanelIntialized$
        .pipe(
          filter((isInitalized) => isInitalized),
          take(1)
        )
        .subscribe((isInitalized) => {
          if (!isInitalized) return;

          mixpanel.people.set(user.email, {
            name: user.name,
            email: user.email,
            domain:
              user.branding?.company.domain ?? user.branding?.company.name,
          });
          mixpanel.identify(user.email);
          LogRocket.identify(user.email, {
            name: user.name,
            email: user.email,
          });
          /// hostNotifications.mixPanelIdentified$ to true
          this.hostNotifications.setMixpanelIdentified(true);
        });
    }
  }

  setRole(role: ROLE) {
    this.userPeer.role = role;
    this.role$.next(role);
  }

  setToken(token: string) {
    this.userPeer.userShuffllToken = token;
  }

  genPeerId() {
    let peerId = sessionStorage.getItem('Shuffll.peerId');
    if (!peerId) {
      peerId = makeRandomString(8);
      sessionStorage.setItem('Shuffll.peerId', peerId);
    }

    return peerId;
  }

  updateUserBranding(branding: IBranding) {
    this.user$.next({ ...this.user$.value, branding });
  }

  switchBoardComponent(comp: SflBoardComp) {
    this.boardComponent = comp;
  }

  /**
   * Helper function to check codec support in the browser
   * @private
   */
  private checkCodecSupport(): { baseMimetype: MimeTypeEnum; codecs: string } {
    // Check if MediaRecorder API is supported in the browser
    if (!window.MediaRecorder) {
      throw new Error('MediaRecorder API is not supported in this browser.');
    }

    // Define MIME types for VP9 and VP8 codecs
    const baseWebmMimetype = MimeTypeEnum.VideoWebm;

    /// vp9
    const vp9MimeType = `${baseWebmMimetype};codecs=vp9`;
    const vp9Supported = this.isCodecSupported(vp9MimeType);
    if (vp9Supported) return { baseMimetype: baseWebmMimetype, codecs: 'vp9' };

    // VP8 with Opus - Usually Firefox
    const vp8OpusMimeType = `${baseWebmMimetype};codecs=vp8,opus`;
    const vp8OpusSupported = this.isCodecSupported(vp8OpusMimeType);
    if (vp8OpusSupported)
      return { baseMimetype: baseWebmMimetype, codecs: 'vp8,opus' };

    /// vp8
    const vp8MimeType = `${baseWebmMimetype};codecs=vp8`;
    const vp8Supported = this.isCodecSupported(vp8MimeType);
    if (vp8Supported) return { baseMimetype: baseWebmMimetype, codecs: 'vp8' };

    /// Webm
    const webmMimeType = `${baseWebmMimetype}`;
    const webmSupported = this.isCodecSupported(webmMimeType);
    if (webmSupported) return { baseMimetype: baseWebmMimetype, codecs: '' };

    // Define MIME types for MP4 with H.264 and AAC codecs
    const baseMp4Mimetype = MimeTypeEnum.VideoMp4;
    const mp4MimeType = `${baseMp4Mimetype};codecs="avc1.42E01E, mp4a.40.2"`;
    const mp4Supported = this.isCodecSupported(mp4MimeType);
    if (mp4Supported)
      return {
        baseMimetype: baseMp4Mimetype,
        codecs: 'avc1.42E01E, mp4a.40.2',
      };

    // Throw an error if none of the codecs are supported
    throw new Error(
      'Neither VP9 nor VP8 codecs, nor WebM or MP4 formats, nor Opus or AAC audio codecs are supported in this browser.'
    );
  }

  private isCodecSupported(mimeType: string): boolean {
    return MediaRecorder.isTypeSupported(mimeType);
  }
}
