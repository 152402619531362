<div class="page-wrapper">
  <div class="logo-wrapper">
    <img
      [src]="
        baseCdnUrl +
        '/files/platform/branding/logos/shuffll_horz-logo_light.svg'
      " />
  </div>

  <div class="project-number-container">
    <span class="title"
      >Ready? <br />
      Please enter your unique number:</span
    >
    <mat-form-field appearance="outline" class="search-field">
      <input
        class="number-input"
        matInput
        [(ngModel)]="this.projectCode"
        type="number"
        (keydown.enter)="navigateToProject()" />
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="navigateToProject()">
      <span class="btn-content">Continue </span>
    </button>

    <div class="loader" *ngIf="isLoading">
      <loading-indicator size="extra-large"></loading-indicator>
    </div>
  </div>
</div>
