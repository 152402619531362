import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Button_USE_CASE, MAT_ICON } from 'src/app/models/defines';
import { AnalyticsNotifierService } from 'src/app/services/utils/analytics-notifier.service';

export interface LinkDisplay {
  relativeUrl: string;
  sentence: string;
  matIcon: MAT_ICON;
}
export interface ILinkConfigs {
  linkData: LinkDisplay[];
  disable: boolean;
}

export interface IButtonAndLinks {
  config: ILinkConfigs;
  matIcon: MAT_ICON;
  buttonUseCase: Button_USE_CASE;
}
@Component({
  selector: 'app-button-to-display-links',
  templateUrl: './button-to-display-links.component.html',
  styleUrls: ['./button-to-display-links.component.scss'],
})
export class ButtonToDisplayLinksComponent {
  @Input()
  buttonConfigs: IButtonAndLinks[] = [];

  baseCdn: string;

  constructor(
    private config: ConfigurationService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private analyticsNotifer: AnalyticsNotifierService
  ) {
    this.baseCdn = this.config.baseCdnUrl;
  }

  onClick(relativeUrl: string, buttonTarget: Button_USE_CASE) {
    const completeUrl = `${this.baseCdn}/${relativeUrl}`;
    switch (buttonTarget) {
      case Button_USE_CASE.DOWNLOAD:
        const link = document.createElement('a');
        link.href = completeUrl;
        link.download = `your-file-name.${this.getFileExtension(completeUrl)}`;
        this.analyticsNotifer.notifyEvent('Button Click - Download Export', {
          link: completeUrl,
        });
        link.click();
        break;
      case Button_USE_CASE.COPY:
        this.clipboard.copy(completeUrl);
        this.snackBar.open('URL has been copied to the clipboard.', 'Dismiss', {
          duration: 2500,
        });
        break;
      default:
        break;
    }
  }

  private getFileExtension(filename: string): string {
    return filename.split('.').pop();
  }
}
