import { Component, Input } from '@angular/core';
import { ICustomLayout, IStagePositionsExtended } from 'src/app/models/defines';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { IStagePosition } from 'lottie-json-helper/lib/types';

@Component({
  selector: 'app-silhouette',
  templateUrl: './silhouette.component.html',
  styleUrls: ['./silhouette.component.scss'],
})
export class SilhouetteComponent {
  @Input('stagePositions') stagePositions: IStagePosition[];

  silhouetteImg = '/files/platform/svgs/silhouette.svg';
  baseCdnUrl: string;
  constructor(private config: ConfigurationService) {
    this.baseCdnUrl = this.config.baseCdnUrl;
  }
}
