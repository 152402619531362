import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { JassubService } from 'src/app/services/jassub.service';
import { ProjectAuthApiService } from '../../../services/api/auth/project-auth-api.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './ass-tester.component.html',
  styleUrls: ['./ass-tester.component.scss'],
})
export class AssTesterComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('videoElement', { static: true })
  videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  testProject = '6690e2d18cfd4b6ebba38bfe';

  constructor(
    private jassubService: JassubService,
    private projectApi: ProjectAuthApiService
  ) {}

  // TODO:
  // 1. Embed this into the composite
  // 2. Make sure we can change the strategy dynamically
  // 3. Save the strategy and changes to the db
  // 4. rebuild the ASS in the editor oside as soon as we start the edit
  // 5. Fuse the ASS subtitle animations to the video when exporting.

  playerReady(vgApi) {
    console.log(vgApi);
  }

  ngAfterViewInit(): void {
    // Get a project
    this.projectApi.getProjectById$(this.testProject).subscribe((project) => {
      // Find the take with the transcript
      // Go through each scene and take
      const sceneWithTranscript = project.scenes.find((scene) =>
        scene.takes.find((take) => take.copy?.transcript)
      );
      const takeWithTranscript = sceneWithTranscript.takes[0];

      if (takeWithTranscript) {
        console.log(takeWithTranscript);
        // const subtitlesHelper = new SubtitlesHelper(SUBTITLE_STRATEGY.KARAOKE)
        // const assObj = subtitlesHelper.transcriptToAssJSON(takeWithTranscript.copy?.transcript?.sentences, sceneWithTranscript.name ?? sceneWithTranscript.id);
        // const assText = subtitlesHelper.stringify(assObj)

        // console.log(assText);
        // // Load video
        // const videoUrl: string = '/assets/videos/IntroVideo.mp4';
        // // const subtitlesUrl: string = '/assets/videos/sides.ass';
        // const workerUrl: string = '/assets/js/jassub/jassub-worker.js';
        //
        // this.loadVideo(videoUrl, assText, workerUrl, 'content');
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // this.jassubService.destroy();
  }

  private async loadVideo(
    videoUrl: string,
    subtitles: string,
    workerUrl: string,
    subtitleMode: 'url' | 'content' = 'url'
  ) {
    const video: HTMLVideoElement = this.videoElement.nativeElement;
    video.src = videoUrl;

    video.addEventListener('loadedmetadata', async () => {
      console.log('Video metadata loaded.');
      try {
        await this.jassubService.initializeCanvasJassub(
          this.canvas.nativeElement,
          this.videoElement.nativeElement,
          subtitles,
          [],
          1,
          subtitleMode
        );
        // await this.jassubService.setTrackByUrl(subtitlesUrl);
      } catch (error) {
        console.error('Error loading video or subtitles:', error);
      }
    });

    video.load();
  }
}
