import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-start-record-dialog',
  templateUrl: './start-record-dialog.component.html',
  styleUrl: './start-record-dialog.component.scss',
})
export class StartRecordDialogComponent {
  constructor(private dialogRef: MatDialogRef<StartRecordDialogComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
