import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlreadyLoggedInGuard  {

  constructor(public auth: AuthenticationService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    if (!this.auth.isAuthenticated$.value) {
      return true;
    } else {
      let returnUrl = next.queryParams['returnUrl'] || '/';
      this.router.navigate([returnUrl]);
      return false;
    }
  }

  // canActivate(route): boolean {
  //     console.log('Authenticated: ' + this.auth.isAuthenticated());
  //
  //     if (this.auth.isAuthenticated()) {
  //         return true;
  //     } else {
  //         this.router.navigateByUrl(route.params.businessName + '/sign-in');
  //         console.log(route);
  //         return false;
  //     }
  // }
}
