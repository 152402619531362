import {Component, Inject, NgZone} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AnimationItem} from 'lottie-web';
import {AnimationOptions} from 'ngx-lottie';
import {LottiePlayerService} from "../../../services/lottie-player.service";

@Component({
    selector: 'app-lottie-preview-dialog',
    styleUrls: ['./lottie-preview-dialog.component.scss'],
    templateUrl: './lottie-preview-dialog.component.html',
})
export class LottiePreviewDialogComponent {
    constructor(
        private ngZone: NgZone,
        private lottiePlayerService: LottiePlayerService,
        private dialogRef: MatDialogRef<LottiePreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { lottieOptions: AnimationOptions }
    ) {
    }

    animationCreated(animationItem: AnimationItem) {
        this.lottiePlayerService.lottieFindMarksAndPlay(animationItem, this.ngZone);
    }

    close() {
        this.dialogRef.close();
    }
}
