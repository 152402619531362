import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IFeedbackConfigs,
  IFeedbackForm,
  QuestionTypeEnum,
} from 'src/app/models/defines';
import { ConfigurationAuthApiService } from 'src/app/services/api/auth/config-auth-api.service';
import { AnalyticsNotifierService } from 'src/app/services/utils/analytics-notifier.service';
import { FeedbacksService } from 'src/app/services/utils/feedbacks.service';

export interface IFeedbackDialogCloseData {
  toCloseToasr: boolean;
  isFeedbackSubmited: boolean;
}
@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { feedbackFormId: string },
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private configurationApi: ConfigurationAuthApiService,
    private analyticsNotfier: AnalyticsNotifierService,
    private feedbacksService: FeedbacksService
  ) {}

  questionTypeEnum = QuestionTypeEnum;
  config: IFeedbackConfigs;
  currentFeedbackForm: IFeedbackForm;
  feedbackForms: IFeedbackForm[] = [];
  FEEDBACK_SUBMITED_LOCALSTORAGE_KEY = 'feedbackSubmited';

  ngOnInit(): void {
    this.getFeedbackConfigsAsync();
  }

  public async getFeedbackConfigsAsync() {
    this.config = await this.configurationApi.getFeedbackConfig();
    this.feedbackForms = this.config.feedbackForms;
    this.currentFeedbackForm = this.config.feedbackForms.find(
      (form) => form.id === this.data.feedbackFormId
    );
  }

  public saveUserAnswers() {
    this.feedbacksService.setFeedbackSubmitedToLocalStorage('true');
    const keyValuePairs: { [key: string]: string } = {};

    for (const form of this.feedbackForms) {
      if (form.questions?.length > 0) {
        for (const question of form.questions) {
          keyValuePairs[question.id] = question.value;
        }
      }
    }
    this.analyticsNotfier.pushTag({
      event: this.data.feedbackFormId,
      data: { ...keyValuePairs },
    });
    this.closeDialog(true);
  }

  public closeDialog(toCloseToasr: boolean) {
    this.dialogRef.close(toCloseToasr);
  }
}
