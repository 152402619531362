import { environment } from 'src/environments/environment';

const BASE_URL = environment.api;

export const POST_PRODUCTION_URL = BASE_URL + '/auth/post-production';

export const POST_PRODUCTION_POST_EDIT_JOB_URL = POST_PRODUCTION_URL + '/edit';

export const POST_PRODUCTION_GET_EDIT_JOB_URL =
  POST_PRODUCTION_URL + '/edit-job';
