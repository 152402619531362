<div class="icon-image-wrapper">
  <img src="/assets/images/icons/lock.svg">
</div>
<h1 mat-dialog-title>This is a Locked Feature</h1>
<div mat-dialog-content>
  <p>
    You are using a demo version of Shuffll.<br>
    Some of the features are locked.<br>
    In order to watch the full version contact Shuffll's team at: <br>
    info&#64;shuffll.com
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Got it</button>
</div>
