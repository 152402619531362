<h1>studio of specific scene</h1>
<!-- <button
  mat-button
  mat-raised-button
  style="width: 150px"
  color="primary"
  class="scene__info__actions__retake"
  (click)="back()">
  Back
</button> -->
