import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from '../configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient, private config: ConfigurationService) { }

  getFeed() {
    return this.http.get<any>(`${this.config.apiIP}/content/feed`);
  }
}
