import {animate, style, transition, trigger} from '@angular/animations';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {VgApiService} from '@videogular/ngx-videogular/core';
import {BehaviorSubject, combineLatest, map, Subject, Subscription, takeUntil,} from 'rxjs';
import {User} from 'src/app/models/user';
import {ITask, ITaskConfigs, IWalkthorugh, taskLinksMap, TaskTypeEnum,} from 'src/app/models/walkthrough';
import {ConfigurationService} from 'src/app/services/configuration.service';
import {AuthenticationService} from 'src/app/services/guards/authentication.service';
import {ProfileService} from 'src/app/services/show/profile.service';
import {WalkthroughPanelService} from 'src/app/services/walkthrough-panel.service';
import {AnalyticsNotifierService} from "../../services/utils/analytics-notifier.service";

export const animations = [
  trigger('fadeIn', [
    transition(':enter', [
      style({opacity: 0}),
      animate('300ms', style({opacity: 1})),
    ]),
  ]),
  trigger('slideOut', [
    transition(':leave', [
      animate('300ms', style({opacity: 0, transform: 'translateX(100%)'})),
    ]),
  ]),
];

@Component({
  selector: 'app-walkthrough-panel',
  templateUrl: './walkthrough-panel.component.html',
  styleUrls: ['./walkthrough-panel.component.scss'],
  animations: animations,
})
export class WalkthroughPanelComponent implements OnInit, OnDestroy {
  percentageCompletedTasks: number = 0;
  vgApi: VgApiService;
  subscriptionToUnsubscribe: Subscription[] = [];
  basedUrl: string = this.configurationService.baseCdnUrl;
  selectedTask: ITask;
  walkthroughData: IWalkthorugh;
  taskLinksMap: Map<TaskTypeEnum, ITaskConfigs> = taskLinksMap;
  btnText: string = '';
  route: string = '';
  panelRoute: string = '';
  toHide: boolean = false;
  panelOpenState: boolean = false;
  user: User = null;
  TaskTypeEnum = TaskTypeEnum;
  toDisplayRecordStepBtn: boolean = false;
  walkthroughData$ = new BehaviorSubject<IWalkthorugh>(null);
  user$ = new BehaviorSubject<User>(null);
  onDestroy$ = new Subject<boolean>();

  constructor(
    private configurationService: ConfigurationService,
    private walkthroughPanelService: WalkthroughPanelService,
    private router: Router,
    public profile: ProfileService,
    public authentication: AuthenticationService,
    private gtmService: AnalyticsNotifierService,
  ) {
  }

  ngOnInit(): void {
    this.walkthroughPanelService.getWalkthroughData().subscribe({
      next: (response) => {
        this.walkthroughData$.next(response);
      },
      error: (error) => {
        console.warn(`NO WALKTHROUGH DATA`);
      },
    });

    this.walkthroughPanelService.walkthroughHidden$.subscribe({
      next: (response) => {
        if (
          localStorage.getItem(WalkthroughPanelService.LOCALSTORAGE_KEY) ===
          'true'
        ) {
          this.toHide = true;
        } else {
          this.toHide = false;
        }
      },
    });

    this.walkthroughPanelService.prodAndDeviceTasksCompleted$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (response) => {
          this.toDisplayRecordStepBtn = !response;
        },
      });

    this.profile.user$.asObservable().subscribe({
      next: (user) => {
        this.user$.next(user);
      },
      error: (error) => {
        console.warn(`NO USER PROFILE DATA`);
      },
    });

    const combinedSubscriptions = combineLatest([
      this.user$,
      this.walkthroughData$,
    ]);

    combinedSubscriptions
      .pipe(
        map(([user, walkthroughData]) => {
          if (!user || !walkthroughData) return;

          this.walkthroughData = walkthroughData;
          this.user = user;
          this.initTasks();
          this.getPercentageCompletedTasks();
          this.walkthroughPanelService.completeDeviceOrProductionTask();
          this.walkthroughPanelService.closeWalkthrough(false);
        })
      )
      .subscribe();

    this.walkthroughTaskStatusChanged();
  }

  public getPercentageCompletedTasks(): number {
    const steps = this.walkthroughData?.config?.steps;
    if (!steps) return;
    const completedTasks = steps.filter((task) => task.isCompleted).length;
    this.percentageCompletedTasks = (completedTasks / steps.length) * 100;
  }

  public playVideoTask(task: ITask): void {
    task.configs.toPlay = true;
    this.selectedTask = task;
  }

  public onCloseVideo(task: ITask) {
    this.selectedTask = null;
  }

  public onClickGoToBtn(task: ITask): void {
    if (task.configs.panelRoute) {
      this.router.navigate([
        `${task.configs.route}`,
        {outlets: {panel: `${task.configs.panelRoute}`}},
      ]);
    } else {
      this.router.navigate([`${task.configs.route}`]);
    }
  }

  public onPlayerReady(api: VgApiService, task: ITask) {
    this.vgApi = api;
    const defaultMedia = api.getDefaultMedia();
    if (!defaultMedia) return;

    if (defaultMedia.canPlayThrough && task.configs.toPlay) {
      defaultMedia.play();
    } else {
      this.subscriptionToUnsubscribe.push(
        defaultMedia?.subscriptions.canPlayThrough.subscribe(
          (canPlayThrough) => {
            if (!canPlayThrough) return;
            defaultMedia.play();
          }
        )
      );
    }
  }

  public onClickHide(): void {
    this.walkthroughPanelService.closeWalkthrough(true);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private walkthroughTaskStatusChanged(): void {
    this.walkthroughPanelService.specialTaskCompleted$.subscribe({
      next: async (completedTaskCategory) => {
        const didUpdated = await this.handleCompletedTaskAsync(
          completedTaskCategory
        );
        if (!didUpdated) {
          console.warn(`Didn't update in server`);
          return;
        }
        this.walkthroughPanelService.completeDeviceOrProductionTask();
      },
      error: (error) => {
        return;
      },
    });

    this.walkthroughPanelService.tasksCompleted$.subscribe({
      next: (completedTaskCategory) => {
        const didUpdate = this.handleCompletedTaskAsync(completedTaskCategory);
        if (!didUpdate) {
          /// Do something 'bout that
          this.gtmService.pushTag({
            event: 'Walkthrough Task Completed',
            data: completedTaskCategory,
          });
        }
      },
      error: (error) => {
        return;
      },
    });
  }

  private initTasks() {
    const accountTask = this.walkthroughData.config.steps.find(
      (item) => item.category === TaskTypeEnum.ACCOUNT
    );
    // Filter out tasks that are not of type TaskTypeEnum.ACCOUNT
    const otherTasks = this.walkthroughData.config.steps.filter(
      (item) => item.category !== TaskTypeEnum.ACCOUNT
    );
    if (accountTask) {
      accountTask.isCompleted = true;
    } else {
      console.warn(`Weird, there is no account task !`);
    }
    otherTasks?.forEach((task) => {
      this.initTaskConfig(task);

      /// Searching the task in the user to see if he is completed, if he does so marking the tasks from the walkthrough as completed as well :)
      const isCompleted = this.user.configs.walkthroughTasks.find(
        (item) => item.id === task.id
      )?.isCompleted;
      this.walkthroughPanelService.setTaskState(task, isCompleted);
    });
  }

  private initTaskConfig(task: ITask): void {
    if (!task || task.category === TaskTypeEnum.ACCOUNT) return;
    const configs = this.taskLinksMap.get(task.category);
    if (configs) {
      task.configs = configs;
    }
  }

  private handleCompletedTaskAsync(
    completedTask: TaskTypeEnum
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!completedTask) return null;

      const task = this.walkthroughData?.config?.steps.find(
        (task) => task.category === completedTask
      );
      if (!task) {
        console.warn(`WTF NO TASK?`);
        return;
      }
      if (task.isCompleted) {
        console.warn(`WTF NO TASK?`);
        return;
      }
      this.walkthroughPanelService
        .updateCompletedTask(task.id, true)
        .subscribe(() => {
          task.isCompleted = true;
          task.configs.isCompleted;
          this.getPercentageCompletedTasks();
          return resolve(true);
        });
    });
  }
}
