// <div *ngFor="let myObj of myArr | OrderBy:'asc':'name'">{{ myObj | json }}</div>

import {Pipe, PipeTransform} from '@angular/core';
import {SflPeerAndSource} from "../models/defines";

export type SortOrder = 'asc' | 'desc';

@Pipe({name: 'OrderByPositionPipe'})
export class OrderByPositionPipePipe implements PipeTransform {
  transform(value: SflPeerAndSource[], sortOrder: SortOrder | string = 'asc'): SflPeerAndSource[] {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as any);
    console.log('sorting', {value, sortOrder})

    if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) {
      console.log({value, sortOrder})
      return value;}

    let sorted = value.sort((a, b) => {
      return a.getPosition() ?? 100 - b.getPosition() ?? 100
    });

    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }

}
