import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { throttleTime } from 'rxjs';
import { ScenePropertiesToUpdateEnum } from 'src/app/models/project-model';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';

export enum DeleteSceneOperationEnum {
  DELETE = 'delete',
  HIDE = 'hide',
}

export interface IDeleteSceneDialogOutput {
  operation: DeleteSceneOperationEnum;
}

@Component({
  selector: 'app-delete-scene-confirm',
  templateUrl: './delete-scene-confirm.component.html',
  styleUrls: ['./delete-scene-confirm.component.scss'],
})
export class DeleteSceneConfirmComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteSceneConfirmComponent>,
    private projectApiService: ProjectAuthApiService,
    private projectStoreService: ProjectStoreService
  ) {}
  protected readonly DeleteSceneOperation = DeleteSceneOperationEnum;

  // closeDialog() {
  //   setTimeout(() => {
  //     this.dialogRef.close('optional result');
  //   }, 10000);
  // }

  public deleteScene() {
    this.projectApiService
      .deleteScene$(this.data.projectId, this.data.scene.id)
      .subscribe((res) => {
        if (!res) return;
        if (res === true) {
          this.dialogRef.close();
        }
      });
  }

  public hideScene() {
    const params = {
      projectId: this.data.projectId,
      sceneId: this.data.scene.id,
      value: !this.data.scene.isHidden,
      key: ScenePropertiesToUpdateEnum.IS_HIDDEN,
    };
    this.projectApiService
      .updateSceneProperty$(params)
      .pipe(
        throttleTime(500) // Allow at most 1 request per 500ms
      )
      .subscribe((updatedScene) => {
        if (!updatedScene) return;
        this.projectStoreService.updateSceneProperty(
          params.projectId,
          params.sceneId,
          params.key,
          params.value
        );
      });
  }
}
