import { Component } from '@angular/core';

@Component({
  selector: 'app-apply-prompter-text',
  templateUrl: './apply-prompter-text.component.html',
  styleUrls: ['./apply-prompter-text.component.scss']
})
export class ApplyPrompterTextComponent {

}
