// ai-text.component.ts
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { VoiceRecognitionService } from '../../../../services/voice-recognition.service';
import { ITextPortion } from '../../../../models/Teleprompter';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IPrompterSettings } from '../../../../models/teleprompter/prompter-settings-model';

@Component({
  selector: 'app-ai-text',
  templateUrl: './ai-text.component.html',
  styleUrls: ['./ai-text.component.scss'],
})
export class AiTextComponent implements OnInit, OnDestroy {
  @Input({ alias: 'teleprompterSettings', required: true })
  teleprompterSettings: IPrompterSettings;
  @Input({ alias: 'isRecording', required: true }) isRecording: boolean;
  @Output('ai-text-changed') aiTextChanged = new EventEmitter<string>();
  @Output('ai-edit-mode') aiEditMode = new EventEmitter<boolean>();
  @Output('ai-hover-status') hoverStatus = new EventEmitter<boolean>();
  textAi: ITextPortion[] = [];
  textAiInString: string = '';
  private onDestroy$ = new Subject<void>();
  isEditMode: boolean = false;

  constructor(
    public voiceRecognition: VoiceRecognitionService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.voiceRecognition.notifySplitTextProcessed
      .pipe(takeUntil(this.onDestroy$)) // Automatically unsubscribes on destroy
      .subscribe((processedText) => {
        if (!processedText) {
          return;
        }
        this.textAi = Array.from(processedText.ai_text.values());
        if (!this.isEditMode) {
          this.textAiInString = this.textAi
            .map((item) => {
              return item.text;
            })
            .join('');
        }
      });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.isRecording) {
      return;
    }
    this.isEditMode = this.elementRef.nativeElement.contains(event.target);
    this.hoverStatus.emit(this.isEditMode); // Emit 'false' when hover ends
    this.aiEditMode.emit(this.isEditMode);
  }

  onTextareaBlur() {
    this.isEditMode = false;
    this.aiEditMode.emit(this.isEditMode);
    this.aiTextChanged.emit(this.textAiInString);
  }

  onTextChange() {
    this.aiTextChanged.emit(this.textAiInString);
  }

  onHoverStart() {
    this.hoverStatus.emit(true);
  }

  onHoverEnd() {
    this.hoverStatus.emit(false);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
