<div mat-dialog-content>
  Are you sure you want to replace the current scene on the timeline with this
  one?
</div>
<div mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    cdkFocusInitial
    (click)="closeDialog(false)">
    Cancel
  </button>

  <span style="flex: 1 1 auto"></span>
  <button mat-raised-button color="primary" (click)="replaceTake()">
    Replace Take
  </button>
</div>
