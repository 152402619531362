<mat-selection-list
  role="list"
  [multiple]="false"
  cdkDropList
  class="example-list"
  cdkDropListSortingDisabled
  [cdkDropListConnectedTo]="[cdkDropListConnectedTo]">
  <ng-container *ngFor="let audio of audios">
    <mat-list-item
      (click)="selectAudio(audio)"
      [class.active]="this.audioChanged === audio"
      role="listitem"
      cdkDrag
      [value]="audio"
      [cdkDragData]="audio"
      class="list-item">
      <div matListItemTitle matLine>{{ audio.fileName }}</div>
      <div matListItemIcon class="to-end"></div>
    </mat-list-item>
  </ng-container>
</mat-selection-list>
