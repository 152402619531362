import { IVideoTag } from './defines';

export class User {
  public created_at;
  public email: string;
  public name;
  public profileImgUrl;
  public _id: any;
  public token: string;
  public active: boolean;
  public socketId: string;
  public username: string;
  public branding?: IBranding;
  public credits: number;
  public onboarding: onboardingObj[];
  public configs: IUserConfigs;
  public plan: PlanEnum;
  public featureGroup: FeatureGroupEnum;

  public isShuffllUser: boolean = false;
  constructor(
    created_at = null,
    email = null,
    name,
    profileImgUrl = null,
    id = null,
    token: string,
    username = null,
    onboarding = null,
    configs = null
  ) {
    this.email = email;
    if (email) {
      this.isShuffllUser = email.toLowerCase().includes('shuffll');
    }
    this.name = name;
    this.profileImgUrl = profileImgUrl;
    this._id = id;
    this.token = token;
    this.username = username;
    this.onboarding = onboarding;
    this.plan = PlanEnum.BASIC;
    this.featureGroup = FeatureGroupEnum.NO_GROUP;
  }
}

export interface GetUsersResponse {
  users: User[];
  count: number;
}

export interface onboardingObj {
  key: string;
  value: string;
}

export interface IWalkthroughTasks {
  id: string;
  isCompleted: boolean;
}

export interface IUserConfigs {
  walkthroughTasks: IWalkthroughTasks[];
}

export interface IBranding {
  enabled?: boolean;
  design?: string;
  prompts: [{ tag: IVideoTag; suggestions: String[] }];
  company?: {
    id?: string;
    name?: string;
    domain?: string;
  };
  logo?: string;
  logoW?: string;
  colors?: {
    colorPrimary?: string;
    colorSecondary?: string;
    colorExtra?: string;
  };
  logoColors?: {
    colorPrimary?: string;
    colorSecondary?: string;
    colorExtra?: string;
  };
  about?: string;
}

export enum PlanEnum {
  FREE = 'free',
  BASIC = 'basic',
  PRO = 'Pro',
  PREMIUM = 'premium',
}

export enum FeatureGroupEnum {
  MUST_PAY = 'must_pay',
  FREE = 'free',
  NO_GROUP = 'no_group',
}
