<h3 mat-dialog-title>Ready to wrap this take and jump to the next scene?</h3>

<div mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    color="primary"
    (click)="closeDialog(true)">
    Keep Recording
  </button>
  <button
    mat-button
    mat-dialog-close
    cdkFocusInitial
    color="primary"
    (click)="closeDialog(false)">
    Jump To Selected Scene
  </button>
</div>
