<div class="container">
  <div class="info">
    <h1 class="title">Experience Shuffll on Desktop!</h1>
    <span class="description-1">
      Our platform is optimized for desktop use to ensure the best creation
      experience. <br />
    </span>
    <span class="description-2">
      Please login from a desktop computer to<br />
      unlock the full potential of your projects.<br />
    </span>
  </div>
  <ng-lottie
    class="lottie"
    [options]="{
      path: 'https://content.shuffll.com/files/platform/onboarding/desktop-animation.json',
      loop: 0
    }">
  </ng-lottie>
</div>
<div class="footer">
  <div class="sidenav-logo"></div>
</div>
