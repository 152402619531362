<ng-container *ngFor="let buttonConfig of this.buttonConfigs">
  <ng-container *ngIf="buttonConfig.config">
    <button
      mat-icon-button
      [matMenuTriggerFor]="buttonMenu"
      [disabled]="buttonConfig.config.disable"
    >
      <mat-icon>{{ buttonConfig.matIcon }}</mat-icon>
    </button>

    <mat-menu #buttonMenu="matMenu" yPosition="below">
      <ng-container *ngFor="let linkData of buttonConfig.config.linkData">
        <button
          *ngIf="linkData.relativeUrl"
          mat-menu-item
          (click)="onClick(linkData.relativeUrl, buttonConfig.buttonUseCase)"
        >
          <mat-icon>{{ linkData.matIcon }}</mat-icon>
          {{ linkData.sentence }}
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>

  <mat-menu #downloadMenu="matMenu" yPosition="below">
    <ng-container *ngFor="let linkData of buttonConfig.config.linkData">
      <a
        *ngIf="linkData.relativeUrl"
        style="text-decoration: none"
        target="_blank"
        [href]="this.baseCdn + '/' + linkData.relativeUrl"
        download
      >
        <button mat-menu-item>
          <mat-icon>{{ linkData.matIcon }}</mat-icon>
          {{ linkData.sentence }}
        </button>
      </a>
    </ng-container>
  </mat-menu>
</ng-container>
