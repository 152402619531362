import {Component, Input, OnInit} from '@angular/core';
import {IAsset, Mimetype} from 'src/app/models/defines';
import {DomSanitizer} from '@angular/platform-browser';
import {ConfigurationService} from 'src/app/services/configuration.service';
import {VgApiService} from '@videogular/ngx-videogular/core';

@Component({
    selector: 'dynamic-asset-content',
    templateUrl: './dynamic-asset-content.component.html',
    styleUrls: ['./dynamic-asset-content.component.scss'],
})
export class DynamicAssetContentComponent implements OnInit {
    @Input()
    asset: IAsset;
    fileMimetype: Mimetype;
    MimetypeEnum = Mimetype;
    fileSrc: string;
    vgApi!: VgApiService;
    baseCdnUrl: string;

    constructor(
        private sanitizer: DomSanitizer,
        private config: ConfigurationService
    ) {
        this.baseCdnUrl = this.config.baseCdnUrl;
    }

    ngOnInit(): void {
        // console.log('why this is happening', this.asset.uploadPath);
        this.fileMimetype = this.asset.mimetype;
        if (!this.fileMimetype) {
            /// This will happen in very special cases !
            const fileExt = this.getFileExtension(this.asset.uploadPath);
            switch (fileExt) {
                case 'png':
                    this.fileMimetype = Mimetype.PNG;
                    break;
                case 'jpg':
                    this.fileMimetype = Mimetype.JPG;
                    break;
                case 'jpeg':
                    this.fileMimetype = Mimetype.JPEG;
                    break;
                case 'mp4':
                    this.fileMimetype = Mimetype.VIDEO;
                    break;
                case 'svg':
                    this.fileMimetype = Mimetype.SVG;
                    break;
                default:
                    this.fileMimetype = Mimetype.PNG;
                    break;
            }
        }
        this.fileSrc = decodeURIComponent(
            this.asset.dashPath ?? this.asset.uploadPath
        );
    }

    onPlayerReady(api: any) {
        this.vgApi = api;
    }

    getFileExtension(fileUrl: string): string {
        // Split the URL by "." to get an array of segments
        const segments = fileUrl.split('.');

        // The file extension is the last segment
        const fileExt = segments[segments.length - 1].toLowerCase();

        return fileExt;
    }
}
