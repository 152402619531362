import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from 'src/app/models/dialog-wrapper.model';

@Component({
    selector: 'app-folder-name',
    templateUrl: './folder-name-dialog.component.html',
    styleUrls: ['./folder-name-dialog.component.scss']
})
export class FolderNameDialog {
    name = '';
    oldName = ''
    isNew = false;
    fileMode = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
        // let data = data as unknown as {new: true, oldName: null};
        this.name = data['oldName'] ?? '';
        this.oldName = data['oldName'] ?? '';
        this.isNew = data['isNew'] ?? false;
        this.fileMode = data['fileMode'] ?? false;
    }
}
