import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration.service';

export interface INoteReq {
  email: string;
  event: string;
}

@Injectable({
  providedIn: 'root',
})
export class HubspotProxyService {
  constructor(
    private http: HttpClient,
    private configService: ConfigurationService
  ) {}

  pushHubspotNote(noteReq: INoteReq) {
    return this.http.post(this.configService.hubspotProxyApi, noteReq);
  }
}
