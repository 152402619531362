import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ConfigurationService} from '../../configuration.service';
import {
    delayWhen,
    mergeMap,
    Observable,
    of,
    retryWhen,
    tap,
    throwError,
    timer,
} from 'rxjs';
import {GoalType, ITagAndSuggestions} from '../../../models/defines';
import {IDesignSuggestions} from "../../../models/design.model";

@Injectable({
    providedIn: 'root',
})
export class CreativeAuthApiService {
    path = '/auth/creative';
    MAX_RETRY_ATTEMPTS = 5;

    constructor(private http: HttpClient, private config: ConfigurationService) {
    }

    getFormatSuggestion(bringPrivate = false): Observable<IDesignSuggestions> {
        console.log('Generating Format Suggestions');
        return this.http.get<IDesignSuggestions>(
            `${this.config.apiIP + this.path}/format`,
            {
                params: {
                    bringPrivate,
                },
            }
        );
    }

    getPromptsSuggestions(): Observable<ITagAndSuggestions[]> {
        return this.http.get<any>(
            `${this.config.apiIP + this.path}/prompt-suggestions`
        );
    }

    getTopicsSubject(
        userInput: string,
        goal: GoalType,
        designGroupId: string
    ): Observable<{
        result: { topics: string[]; headers: string[] };
    }> {
        return this.http.get<any>(`${this.config.apiIP + this.path}/chapters`, {
            params: {
                goal: goal,
                userInput: userInput,
                designGroupId: designGroupId,
            },
        });
    }

    getScriptSubject(
        userInput: string,
        topics: string[],
        designGroupId: string
    ): Observable<{ result: string }> {
        return this.http
            .get<any>(`${this.config.apiIP + this.path}/script`, {
                params: {
                    userInput: userInput,
                    topics: topics,
                    designGroupId: designGroupId,
                },
            })
            .pipe();
    }

    generateImage(
        userInput: string,
        folder: string
    ): Observable<{ result: string }> {
        console.log('Generating Image');

        return this.http.get<any>(`${this.config.apiIP + this.path}/image`, {
            params: {
                userInput: userInput,
                folder: folder,
            },
        });
    }

    getScript(
        userInput: string,
        topics: string[],
        designGroupId: string
    ): Observable<{ result: string }> {
        console.log('Generating script');

        return this.http
            .get<any>(`${this.config.apiIP + this.path}/script`, {
                params: {
                    userInput: userInput,
                    topics: topics,
                    designGroupId: designGroupId,
                },
            })
            .pipe(
                retryWhen((errors) =>
                    errors.pipe(
                        // Use mergeMap to preserve the error and pass it along
                        mergeMap((error, index) =>
                            // If we've tried more than 5 times or the error status isn't a 5xx or 429, throw an error
                            index > 5 ||
                            !error.status ||
                            (Math.floor(error.status / 100) !== 5 && error.status !== 429)
                                ? throwError(error)
                                : // If it's a 5xx or 429 error, retry after a delay
                                of(error).pipe(
                                    tap((error) =>
                                        console.log(`Attempt ${index + 1} failed, retrying...`)
                                    ),
                                    delayWhen(() => timer((index + 1) * 2000)) // Increase delay with each retry
                                )
                        )
                    )
                )
            );
    }

    generateScriptIntro(userInput: string): Observable<string> {
        console.log('Generating topics');
        return this.http
            .get<any>(`${this.config.apiIP + this.path}/script-intro`, {
                params: {
                    userInput: userInput,
                },
            })
            .pipe(
                retryWhen((errors) =>
                    errors.pipe(
                        // Use mergeMap to preserve the error and pass it along
                        mergeMap((error, index) =>
                            // If we've tried more than 5 times or the error status isn't a 5xx or 429, throw an error
                            index > 5 ||
                            !error.status ||
                            (Math.floor(error.status / 100) !== 5 && error.status !== 429)
                                ? throwError(error)
                                : // If it's a 5xx or 429 error, retry after a delay
                                of(error).pipe(
                                    tap((error) =>
                                        console.log(`Attempt ${index + 1} failed, retrying...`)
                                    ),
                                    delayWhen(() => timer((index + 1) * 1000)) // Increase delay with each retry
                                )
                        )
                    )
                )
            );
    }

    shouldRetry(error: HttpErrorResponse, attempt: number) {
        const shouldRetry =
            Math.floor(error.status / 100) !== 5 && error.status !== 429;
        return shouldRetry ? timer((attempt + 1) * 2000) : throwError(() => error);
    }
}
