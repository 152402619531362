import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IAudioEditTake } from 'src/app/models/project/edit/edit-model';

@Component({
  selector: 'app-audio-list',
  templateUrl: './audio-list.component.html',
  styleUrls: ['./audio-list.component.scss'],
})
export class AudioListComponent {
  @Input('audios')
  audios: IAudioEditTake[];

  @Input('cdk-drop-to')
  cdkDropListConnectedTo: string;

  @Input('add-play-button')
  addPlayButton: boolean = true;

  @Output()
  playAudioEvent = new EventEmitter<IAudioEditTake>();

  @Output()
  stopAudioEvent = new EventEmitter<IAudioEditTake>();

  @Input()
  isPlayingSpecificAudio: boolean;

  @Input()
  audioChanged: IAudioEditTake;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['audios']) {
    }
  }

  selectAudio(audio: IAudioEditTake) {
    this.audioChanged = audio;
    if (this.audioChanged) {
      this.playAudioEvent.emit(audio);
    }
  }

  ngOnInit(): void {
    console.log('bit and scenes', this.audios);
  }

  playOrPauseScene(audio: IAudioEditTake) {
    if (!audio) return;
    /// using the opposite value because it will be changed after emiting the event
    audio.isPlaying = !this.isPlayingSpecificAudio;
    console.log('audio is playing', audio.isPlaying);
    console.log('is playingggg from audio list', this.isPlayingSpecificAudio);

    this.playAudioEvent.emit(audio);
  }
}
