import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfigurationService} from '../../../services/configuration.service';
import {
  FormBuilder,



} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from '../../../services/guards/authentication.service';
import {ParticipationAuthApiService} from '../../../services/api/auth/participation-auth-api.service';

@Component({
  selector: 'app-share-links',
  templateUrl: './share-links.component.html',
  styleUrls: ['./share-links.component.scss'],
})
export class ShareLinksComponent implements OnInit {
  inviteUrl: string;
  directInviteUrl: string;
  joinUrl: string;

  constructor(
    public dialogRef: MatDialogRef<ShareLinksComponent>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private configuration: ConfigurationService,
    private participationAPI: ParticipationAuthApiService,
    private authentication: AuthenticationService
  ) {
    const host = this.authentication.getUser();
    // Get from config
    this.inviteUrl = this.configuration.prettyUrl + '/guest/' + host.username;

    /// Use the token in the link
    participationAPI.participationToken.subscribe((tokenResponse) => {
      if (tokenResponse) {
        this.directInviteUrl =
          this.configuration.prettyUrl +
          '/guest/' +
          host.username +
          '?t=' +
          tokenResponse.token;
      }
    });
  }

  reactiveForm() {
    // this.shareLinks = this.formBuilder.group({
    //   inviteLink: [this.inviteUrl],
    //   joinLink: [this.joinUrl]
    // });
  }

  ngOnInit(): void {
    /// Ask to generate a new token if needed
    if (!this.participationAPI.participationToken.value) {
      this.participationAPI.generate();
    }
    // this.joinUrl = this.configuration.prettyUrl + '/join/' + host.username;
  }

  notifyCopy(kind: string) {
    let snackBarRef = this.snackBar.open(kind + ' link copied!', 'Dismiss', {
      duration: 2000,
    });
  }
}
