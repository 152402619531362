import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITakeWithLottieComposed } from 'src/app/models/project/take/take-model';
import { EnhanceManagerService } from 'src/app/services/project/enhance/enhance-manager.service';
import {
  SnackbarActionEnum,
  SnackBarService,
} from 'src/app/services/utils/snack-bar.service';

@Component({
  selector: 'app-replace-take-dialog',
  templateUrl: './replace-take-dialog.component.html',
  styleUrl: './replace-take-dialog.component.scss',
})
export class ReplaceTakeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ReplaceTakeDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      chosenTake: ITakeWithLottieComposed;
      projectId: string;
      editId: string;
      sceneId: string;
    },
    private enhanceManager: EnhanceManagerService,
    private snackBarService: SnackBarService
  ) {}

  replaceTake() {
    this.enhance(this.data.chosenTake);
    this.closeDialog(true);
  }

  public enhance(chosenTake: ITakeWithLottieComposed) {
    if (!chosenTake.videoLayers || chosenTake.videoLayers.length === 0) {
      this.snackBarService.openMessage(
        `Could not enhance because no video layers are existed`,
        SnackbarActionEnum.Close,
        5000
      );
      return;
    }

    this.enhanceManager.audioCleanVideoLayersAsync(
      this.data.projectId,
      this.data.editId,
      this.data.sceneId,
      this.data.chosenTake.id,
      this.data.chosenTake.videoLayers.map((videoLayer) => videoLayer.id)
    );

    this.enhanceManager.segmentationVideoLayersAsync(
      this.data.projectId,
      this.data.editId,
      this.data.sceneId,
      this.data.chosenTake.id,
      this.data.chosenTake.videoLayers.map((videoLayer) => videoLayer.id)
    );
  }

  closeDialog(confirmReplace) {
    this.dialogRef.close(confirmReplace);
  }
}
