<h1
  mat-dialog-title
  [innerHTML]="data.title.htmlContent"
  [style.text-align]="data.title.align"
  [style.color]="data.title.css.color"
  [ngStyle]="data.title.css.style"
></h1>

<div *ngFor="let body of data.body">
  <div mat-dialog-content [ngStyle]="body.css" [style.text-align]="body.align">
    <p [innerHTML]="body.htmlContent"></p>
  </div>
</div>
<mat-form-field *ngIf="data.input" class="input-width" appearance="fill">
  <mat-label>{{ data.input.label }}</mat-label>
  <textarea matInput *ngIf="data.input.type === 'textarea'"
            [placeholder]="data.input.placeholder"
            [(ngModel)]="data.input.value"
            [ngStyle]="data.input.css.style"

  ></textarea>
  <input *ngIf="!(data.input.type === 'textarea')"
         [type]="data.input.type" matInput
         [placeholder]="data.input.placeholder"
         [(ngModel)]="data.input.value"
         [ngStyle]="data.input.css.style" />
</mat-form-field>
<mat-dialog-actions align="center">
  <button *ngFor="let button of data.buttons"
          mat-raised-button
          [color]="button.css.color"
          [ngClass.lt-sm]="'mobile'"
          [ngStyle]="button.css.style"
          (click)="onButtonClick(button)">
    {{ button.text }}
  </button>
</mat-dialog-actions>
