import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorPickerDirective } from 'ngx-color-picker';
import { IDynamicText } from '../models/defines';

@Directive({
  selector: '[colorPickerRgba]',
})
export class ColorPickerRGBADirective implements OnInit {
  @Input() colorPickerRgba: any;
  @Input() dynamicColor: IDynamicText;
  @Output() colorPickerRgbaChange: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private colorPicker: ColorPickerDirective) {}

  ngOnInit() {
    this.colorPicker.colorPickerChange.subscribe((color: string) => {
      if (color.startsWith('rgba(')) {
        this.colorPickerRgbaChange.emit(color);
      } else {
        const rgbaColor = this.getRgbaColor(color);
        const rgbaString = `rgba(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b},${rgbaColor.a})`; // console.log('changeee', rgbaString)
        this.colorPickerRgba = rgbaString;
        this.colorPickerRgbaChange.emit(rgbaString);
      }
    });
  }

  private getRgbaColor(color: string) {
    if (color.startsWith('#')) {
      return this.hexToRgba(color);
    } else if (color.startsWith('rgba(')) {
      return this.extractRgbaValues(color);
    }
  }

  private hexToRgba(hex: string) {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const a = 1;
    return { r, g, b, a };
  }

  private extractRgbaValues(rgbString: string) {
    const values = rgbString
      .substring(4, rgbString.length - 1)
      .replace(/ /g, '')
      .split(',');

    const [r, g, b, a] = values.map(parseFloat);

    return { r, g, b, a };
  }

  private rgbaToHex(r: number, g: number, b: number, a: number): string {
    const toHex = (n: number) => {
      const hex = n.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    const alpha = Math.round(a * 255);

    return (
      '&H' + toHex(r) + toHex(g) + toHex(b) + (alpha < 255 ? toHex(alpha) : '')
    );
  }
}
