import { Component } from '@angular/core';

@Component({
  selector: 'app-device-in-use',

  templateUrl: './device-in-use.component.html',
  styleUrl: './device-in-use.component.scss'
})
export class DeviceInUseComponent {

}
