import {Component} from '@angular/core';
import {VgApiService} from '@videogular/ngx-videogular/core';
import {ConfigurationService} from 'src/app/services/configuration.service';

@Component({
  selector: 'app-host-tester',
  templateUrl: './host-tester.component.html',
  styleUrls: ['./host-tester.component.scss'],
})
export class HostTesterComponent {
  baseCdnUrl: string;
  dynamicUrl = '/files/platform/tmp-dash-test/output.mpd';
  thumbnailDataURLs: string[] = [];
  api: VgApiService;

  constructor(private config: ConfigurationService) {
    this.baseCdnUrl = this.config.baseCdnUrl;
  }

  captureThumbnailAtTime(seconds: number, width: number, height: number): Promise<string> {
    console.log(`Capturing thumbnail at ${seconds} seconds`);
    return new Promise<string>((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const videoElement = this.api.getDefaultMedia().elem;

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');

      // Seek to the desired time in seconds
      videoElement.currentTime = seconds;

      // Wait for the video to seek
      videoElement.onseeked = () => {
        // Draw video frame onto the canvas
        ctx.drawImage(videoElement, 0, 0, width, height);

        // Resolve with the captured thumbnail as data URL
        const thumbnailDataURL = canvas.toDataURL('image/jpeg');
        console.log(`Thumbnail captured: ${thumbnailDataURL}`);
        // Reset onseeked event handler to avoid memory leaks
        videoElement.onseeked = null;
        resolve(thumbnailDataURL);


      };

      // Handle seek error
      videoElement.onerror = (event) => {
        reject(`Error seeking video: ${event}`);
      };
    });
  }

  async generateThumbnailsInterval(intervalSeconds: number, width: number, height: number): Promise<void> {
    const videoElement = this.api.getDefaultMedia().elem;
    const duration = videoElement.duration;
    const capturedThumbnails: Promise<string>[] = [];

    // Capture thumbnails at intervals until the end of the video
    for (let currentTime = 0; currentTime < duration; currentTime += intervalSeconds) {
      try {
        const thumbnailDataURL = await this.captureThumbnailAtTime(currentTime, width, height);
        capturedThumbnails.push(Promise.resolve(thumbnailDataURL));
      } catch (error) {
        console.error(`Error capturing thumbnail at ${currentTime} seconds:`, error);
        // Handle error as needed
      }
    }

    try {
      // Resolve all promises and store the data URLs
      this.thumbnailDataURLs = await Promise.all(capturedThumbnails);
      console.log('Thumbnails generated:', this.thumbnailDataURLs);
    } catch (error) {
      console.error('Error generating thumbnails:', error);
      // Handle error as needed
    }
  }


  onPlayerReady(api: VgApiService) {
    this.api = api;
    // Optionally, autoplay the video when ready
    // this.api.getDefaultMedia().play();
  }
}
