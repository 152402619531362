<div
  mat-icon-button
  class="profile-image-wrapper"
  [class.muted]="participant?.stageOptions.stageMicEnabled === false"
>
  <video
    class="participant-video"
    *ngIf="participant?.peer.camStream"
    [muted]="
      participant?.stageOptions.stageMicEnabled === false ||
      participant.peer.id === profile.userPeer.id
        ? 'muted'
        : false
    "
    [srcObject]="participant?.peer.camStream"
    autoplay
    playsinline
  ></video>

  <div
    class="participant-image"
    *ngIf="!participant?.peer.camStream"
    [ngStyle]="{ 'background-image': 'url(' + participant?.peer.picture + ')' }"
  ></div>
</div>
