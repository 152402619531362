import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/guards/auth.guard';
import { PrivacyComponent } from './pages/public/others/privacy/privacy.component';
import { TermsComponent } from './pages/public/others/terms/terms.component';
import { AlreadyLoggedInGuard } from './services/guards/alreadyLoggedIn.guard';
import { HomepageWpComponent } from './pages/public/homepage-wp/homepage-wp.component';
import { ConnectionErrorPageComponent } from './pages/public/connection-error-page/connection-error-page.component';
import { DashboardComponent } from './pages/private/dashboard/dashboard.component';
import { BrandingComponent } from './pages/private/dashboard/branding/branding.component';
import { TeleprompterComponent } from './components/major/teleprompter/teleprompter.component';
import { AiPrompterComponent } from './components/major/teleprompter/ai-prompter/ai-prompter.component';
import { AssetsComponent } from './pages/private/dashboard/assets/assets.component';
import { DiscoverComponent } from './pages/private/dashboard/discover/discover.component';
import { LottieTesterComponent } from './pages/public/lottie-tester/lottie-tester.component';
import { AdminGuard } from './services/guards/admin.guard';
import { OnboardingMobileStepComponent } from './components/minor/onboarding-mobile-step/onboarding-mobile-step.component';
import { MyLibraryComponent } from './pages/private/dashboard/project/my-library/my-library.component';
import { PlansPageComponent } from './pages/private/dashboard/plans-and-payments/plans-page/plans-page.component';
import { AssTesterComponent } from './pages/public/ass-tester/ass-tester.component';
import { NewProjectComponent } from './pages/private/dashboard/project/new-project/new-project.component';
import { DASHBOARD_URL, PANEL_OUTLET_URL } from './pages/private/host/services/shared/constants/client/dashboard/dashboard.routes';
import { LaunchWelcomeComponent } from './pages/private/launch/launch-welcome/launch-welcome.component';

const routes: Routes = [
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  {
    path: 'host/:id',
    redirectTo: '/dashboard/(panel:library)',
  },

  {
    path: 'launch',
    loadChildren: () =>
      import('./modules/launch/launch.module').then((m) => m.LaunchModule),
  },
  {
    path: 'cmw',
    loadChildren: () =>
      import('./modules/launch/launch.module').then((m) => m.LaunchModule),
  },

  {
    path: 'onboarding',
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule,
      ),
  },

  {
    path: 'onboarding/mobile',
    canActivate: [AuthGuard],
    component: OnboardingMobileStepComponent,
  },
  {
    path: DASHBOARD_URL,
    // canActivate: [AuthGuard, WelcomeGuard],
    canActivate: [AuthGuard],

    component: DashboardComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: `/${DASHBOARD_URL}/(${PANEL_OUTLET_URL}:library)`,
      },

      {
        path: 'plans',
        component: PlansPageComponent,
        pathMatch: 'full',
        outlet: PANEL_OUTLET_URL,
      },
      {
        path: 'library',
        component: MyLibraryComponent,
        pathMatch: 'full',
        outlet: PANEL_OUTLET_URL,
      },
      // {
      //   path: 'launch',
      //   component: LaunchFormComponent,
      //   pathMatch: 'full',
      //   outlet: 'panel',
      // },
      {
        path: 'new',
        component: NewProjectComponent,
        pathMatch: 'full',
        outlet: PANEL_OUTLET_URL,
      },
      {
        path: 'project',
        outlet: PANEL_OUTLET_URL, // Ensure this is included

        loadChildren: () =>
          import('./modules/private/project/project.module').then(
            (m) => m.ProjectModule,
          ),
      },

      {
        path: 'discover',
        component: DiscoverComponent,
        pathMatch: 'full',
        outlet: PANEL_OUTLET_URL,
      },
      {
        path: 'assets',
        component: AssetsComponent,
        pathMatch: 'full',
        outlet: PANEL_OUTLET_URL,
      },
      {
        path: 'branding',
        component: BrandingComponent,
        pathMatch: 'full',
        outlet: PANEL_OUTLET_URL,
      },
      {
        path: 'settings',
        outlet: PANEL_OUTLET_URL,
        loadChildren: () =>
          import('./modules/private/user/settings/user-settings/user-settings.module').then((m) => m.UserSettingsModule),
      },
      {
        path: 'launch-project',
        component: LaunchWelcomeComponent,
        pathMatch: 'full',
        outlet: PANEL_OUTLET_URL,
      },
      {
        path: 'backoffice',
        outlet: PANEL_OUTLET_URL,

        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },

  {
    path: 'participate/:hostUsername',
    redirectTo: '/dashboard/(panel:library)',
  },
  {
    path: 'join/:hostUsername',
    redirectTo: '/dashboard/(panel:library)',
  },

  { path: 'hoster', component: AssTesterComponent },
  { path: 'lottie', component: LottieTesterComponent },

  {
    path: 'login',
    canActivate: [AlreadyLoggedInGuard],
    loadChildren: () =>
      import('./modules/sign-in/sign-in.module').then((m) => m.SignInModule),
  },
  {
    path: 'signin', // Alias for login
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'register', // Another alias for login
    redirectTo: 'login',
    pathMatch: 'full',
  },

  // {path: 'feed', component: FeedComponent},
  { path: 'maintenance', component: ConnectionErrorPageComponent },
  { path: 'prompter', component: TeleprompterComponent },
  { path: 'ai-prompter', component: AiPrompterComponent },

  { path: '', component: HomepageWpComponent }, // {path: '', component: MainComponent},
  // Social media auth - needed for redirects
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
