<div *ngIf="objectToPlay">
  <button
    mat-icon-button
    color="primary"
    (click)="playOrPauseScene(); $event.stopPropagation()"
  >
    <mat-icon>
      {{ isPlaying ? 'pause' : 'play_arrow' }}
    </mat-icon>
  </button>
</div>
