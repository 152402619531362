<mat-selection-list
  class="scene-bank"
  *ngIf="design && personalizedDynamics"
  fxLayout="row"
  fxLayoutWrap="wrap">
  <mat-list-option
    *ngFor="let sceneComposition of this.sceneCompositions"
    [fxFlex.gt-xs]="'33.3%'"
    [fxFlex.xs]="'100%'"
    class="composition-cell"
    (click)="chooseComposition(sceneComposition.composition)">
    <div class="composition-area">
      <app-silhouette
        *ngIf="sceneComposition.stagePositions.length > 0"
        [stagePositions]="sceneComposition.stagePositions"></app-silhouette>

      <dynamic-lottie
        [config]="{
          layout: sceneComposition.composition.layouts[0],
          dynamicLottieChanges: personalizedDynamics,
          basePath: design?.basePath,
        }">
      </dynamic-lottie>
    </div>
    <h5 class="name">{{ sceneComposition.composition.name }}</h5>
  </mat-list-option>
</mat-selection-list>
<div class="buttons-area" mat-dialog-actions align="end">
  <button mat-dialog-close mat-raised-button (click)="closeDialog()">
    Cancel
  </button>
  <button
    color="primary"
    mat-raised-button
    (click)="addNewScene()"
    mat-raised-button
    [class.button-loading-spinner]="this.isLoadingNewScene">
    <span *ngIf="!this.isLoadingNewScene">Choose</span>
  </button>
</div>
