import { Injectable } from '@angular/core';
import Intercom, { shutdown } from '@intercom/messenger-js-sdk';
import { User } from '../models/user';
import { UserService } from './api/auth/user.service';
import { IIntercomDefaultMessage } from '../models/user/user-settings-model';
import { IntercomSettings } from '@intercom/messenger-js-sdk/types';
import { isWorkEmail, NonWorkEmailProviders } from '../utils/non-work-emails';
interface IIntercomCompanySettings {
    id: string;
    website: string;
    name: string;
}
@Injectable({
    providedIn: 'root',
})
export class IntercomService {
    private intercomInitialized = false;
    private appKey = 'hn18clua';

    constructor(private userService: UserService) {
        if (this.userService.isMobileUser()) {
            this.hideIntercom();
        }
    }

    hideIntercom() {
        Intercom({ app_id: this.appKey, hide_default_launcher: true });
    }

    init(user: User) {
        if (!this.intercomInitialized) {
            const utmParams: any = {};
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (
                    key?.startsWith('utm_') &&
                    key in
                        ({
                            utm_term: '',
                            utm_content: '',
                            utm_medium: '',
                            utm_campaign: '',
                            utm_source: '',
                        } as IntercomSettings)
                ) {
                    utmParams[key] = localStorage.getItem(key) || '';
                }
            }
            let company: IIntercomCompanySettings;
            const userBranding = user.branding?.company;
            if (userBranding) {
                const isWorkingEmail = isWorkEmail(
                    userBranding.domain ?? userBranding.name ?? ''
                );
                if (isWorkingEmail) {
                    company = {
                        id: userBranding.id,
                        name: userBranding.name,
                        website: userBranding.domain ?? userBranding.id,
                    };
                }
            }
            Intercom({
                company: company,
                app_id: this.appKey,
                email: user.email,
                name: user.name ?? user.email,
                user_id: user.email,
                ...utmParams,
            });
            this.intercomInitialized = true;
        }
    }

    show() {
        console.log('this.intercomInitialized', this.intercomInitialized);
        if (this.intercomInitialized) {
            window.Intercom('update', {
                hide_default_launcher: false,
            });
        }
    }

    hide() {
        console.log('this.intercomInitialized', this.intercomInitialized);
        if (this.intercomInitialized) {
            window.Intercom('update', {
                hide_default_launcher: true,
            });
        }
    }

    open(defaultMessage?: IIntercomDefaultMessage) {
        if (this.intercomInitialized) {
            window.Intercom('show'); // Open the Intercom messenger
            if (defaultMessage) {
                window.Intercom('showNewMessage', defaultMessage.message); // Open with a default message
            }
        } else {
            console.warn(
                'Intercom is not initialized. Please initialize it before opening.'
            );
        }
    }

    shutdown() {
        console.log('this.intercomInitialized', this.intercomInitialized);

        if (this.intercomInitialized) {
            this.intercomInitialized = false;
        }
        shutdown();
    }
}
