import { getProjectGeneralUrl, PROJECT_BASE_URL } from './project.urls';
import { MissingArgumentsError } from 'src/app/models/errors/general.errors';

export const EDIT_URL = '/edit';

export function getPostEditUrl(projectId: string): string {
  const baseEditUrl = getBaseEditUrl(projectId);
  return baseEditUrl;
}

export function getSaveEditUrlPUT(projectId: string, editId: string): string {
  if (!projectId || !editId) {
    throw new MissingArgumentsError(
      `Could not construct the PUT save edit URL because one of the arguments is null`
    );
  }
  const baseEditUrl = getDetailsEditUrl(projectId, editId);

  return baseEditUrl;
}

export function getDetailsEditUrl(projectId: string, editId: string): string {
  if (!editId) {
    throw new MissingArgumentsError(
      `COuld not construct the details edit URL because the edit ID is null`
    );
  }

  return `${getBaseEditUrl(projectId)}/${editId}`;
}

export function getBaseEditUrl(projectId: string): string {
  if (!projectId) {
    throw new MissingArgumentsError(
      `Could not construct the POST export URL because the recording ID is null`
    );
  }
  return `${getProjectGeneralUrl(projectId)}${EDIT_URL}`;
}
