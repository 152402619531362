import { Injectable } from '@angular/core';
import { IBlobObject } from '../models/indexDB.model';

/**
 * Injecting the service inside the app component so it will initialize in the begining of the application
 */
@Injectable({
  providedIn: 'root',
})
export class IndexCacheService {
  private indexedDB!: IDBFactory;

  private dbMap = new Map<string, IDBDatabase>();
  constructor() {
    this.indexedDB =
      window.indexedDB ||
      (<any>window).mozIndexedDB ||
      (<any>window).webkitIndexedDB ||
      (<any>window).msIndexedDB;

    try {
      // this.dbPromise = this.initDatabaseAsync();
    } catch (error) {
      console.error(`Could not open db recordings ${error}`);
    }

    this.indexedDB.databases();
  }

  public openDatabaseAsync(
    databaseName: string,
    version: number,
    storeNames: string[]
  ): Promise<IDBDatabase> {
    if (!databaseName || version <= 0) {
      return Promise.reject(new Error('Invalid input parameters'));
    }

    const dbPromise = new Promise<IDBDatabase>((resolve, reject) => {
      const request = this.indexedDB.open(databaseName, version);

      request.onupgradeneeded = (event: any) => {
        const db = request.result;
        this.dbMap.set(databaseName, db);
        for (const storeName of storeNames) {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, { keyPath: 'id' });
          }
        }
      };

      request.onsuccess = () => {
        this.dbMap.set(databaseName, request.result);
        return resolve(request.result);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
    return dbPromise;
  }

  public async addObjectStoreAsync(dbName: string, storeName: string) {
    if (!dbName || !storeName) {
      console.error(
        'Could not add object store because db name or store name are null'
      );

      throw new Error(
        `Could not add object store because db name or store name are null`
      );
    }

    if (!this.dbMap) {
      console.error(`Could add store because db map is null`);
      throw new Error(`Could add store because db map is null`);
    }

    const dbFromMap = this.getDBFromMap(dbName);

    return new Promise<boolean>((resolve, reject) => {
      if (!dbFromMap.objectStoreNames.contains(storeName)) {
        dbFromMap.createObjectStore(storeName, { keyPath: 'id' });
      }
      return resolve(true);
    });
  }

  public async getRecordAsync(
    dbName: string,
    storeName: string,
    id: any
  ): Promise<any> {
    if (!dbName || !storeName || !id) {
      return Promise.reject(new Error('Invalid input parameters'));
    }
    const dbFromMap = this.getDBFromMap(dbName);

    return new Promise((resolve, reject) => {
      const transaction = dbFromMap.transaction(storeName, 'readonly');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.get(id);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  public async addRecordAsync(
    dbName: string,
    storeName: string,
    data: IBlobObject & { [key: string]: any }
  ): Promise<void> {
    if (!dbName || !storeName || !data) {
      return Promise.reject(new Error('Invalid input parameters'));
    }

    try {
      // Ensure the database and object store exist, incrementing the version if necessary.
      const db = await this.ensureObjectStoreExists(dbName, storeName);

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        const request = objectStore.add(data);

        request.onsuccess = () => {
          resolve();
        };

        request.onerror = () => {
          reject(request.error);
        };
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateRecordAsync(
    dbName: string,
    storeName: string,
    data: IBlobObject & { [key: string]: any }
  ): Promise<void> {
    if (!dbName || !storeName || !data) {
      return Promise.reject(new Error('Invalid input parameters'));
    }
    const dbFromMap = this.getDBFromMap(dbName);

    return new Promise((resolve, reject) => {
      const transaction = dbFromMap.transaction(storeName, 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.put({ id: data.id, ...data });

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  /**
   * Asynchronously removes all IndexedDB databases whose names start with the specified string.
   *
   * This function first closes all open connections to databases whose names start with the specified string.
   * It then attempts to delete these databases. Note that the `await` keyword is used here only to fetch
   * the list of current databases in the browser.
   *
   * @param {string} startWith - The prefix to match database names.
   * @returns {Promise<void[]>} - A promise that resolves when all matching databases have been deleted.
   * @throws {DOMException} - If an error occurs while attempting to delete a database.
   *
   * @example
   * // Remove databases that start with 'example'
   * dbManager.removeDBStartWithAsync('example').then(() => {
   *   console.log('databases fetched successfully');
   * }).catch(error => {
   *   console.error('Error fetching databases:', error);
   * });
   */
  public async removeDBStartWithAsync(startWith: string) {
    const dbs = await this.indexedDB.databases();
    // try {
    //   this.closeAllConnectionsStartsWith(startWith, dbs);
    // } catch (error) {
    //   console.error(
    //     `Could not close connection start with ${startWith} ${error}`
    //   );
    // }
    const promises: Promise<void>[] = [];
    for (const db of dbs) {
      const dbName = db.name;
      if (!dbName.startsWith(startWith)) continue;
      const dbRequest = indexedDB.open(dbName);
      await new Promise<void>((resolve, reject) => {
        /// Sometimes the request to open the db is hanging in air, and not triggering any event. so we make it as reject
        const setTimeoutId = setTimeout(() => {
          return reject('open db request was hanging in air for too long');
        }, 1500);
        dbRequest.onsuccess = () => {
          clearTimeout(setTimeoutId);
          dbRequest.result.close();
          promises.push(this.deleteDatabaseAsync(dbName));
          return resolve();
        };
      });

      // const promise = new Promise<void>((resolve, reject) => {
      //   const request = window.indexedDB.deleteDatabase(db.name);
      //   request.onsuccess = () => resolve();
      //   request.onerror = (error) => reject(error);
      //   request.onblocked = (error) => reject(error);
      // });
      // promises.push(promise);
    }
    return promises;
  }
  // public async removeDBStartWithAsync(startWith: string) {
  //   const dbs = await window.indexedDB.databases();
  //   try {
  //     this.closeAllConnectionsStartsWith(startWith, dbs);
  //   } catch (error) {
  //     console.error(
  //       `Could not close connection start with ${startWith} ${error}`
  //     );
  //   }
  //   const promises: Promise<void>[] = [];
  //   for (const db of dbs) {
  //     if (!db.name.startsWith(startWith)) continue;
  //
  //     const promise = new Promise<void>((resolve, reject) => {
  //       const request = window.indexedDB.deleteDatabase(db.name);
  //       request.onsuccess = () => resolve();
  //       request.onerror = (error) => reject(error);
  //       request.onblocked = (error) => reject(error);
  //     });
  //     promises.push(promise);
  //   }
  //   return promises;
  // }

  deleteDatabaseAsync(dbName: string, maxAttempts = 5) {
    return new Promise<void>((resolve, reject) => {
      const deleteRequest = this.indexedDB.deleteDatabase(dbName);
      deleteRequest.onsuccess = () => {
        return resolve();
      };
      deleteRequest.onerror = (error) => {
        console.error(`An error occurred while deleting database`, error);
        return reject(error);
      };

      deleteRequest.onblocked = (error) => {
        console.error(
          `Database couldn't be removed because he is onblocked`,
          error
        );
        return reject(error);
      };
      deleteRequest.onupgradeneeded = (event) => {
        console.log(`onupgradeneeded fired for '${dbName}':`, event);
      };
    });
  }
  public async deleteRecordAsync(
    dbName: string,
    storeName: string,
    fieldId: any
  ): Promise<void> {
    if (!dbName || !storeName || !fieldId) {
      return Promise.reject(new Error('Invalid input parameters'));
    }

    const dbFromMap = this.getDBFromMap(dbName);

    return new Promise((resolve, reject) => {
      const transaction = dbFromMap.transaction(storeName, 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.delete(fieldId);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }
  public clearStoreAsync(dbName: string, storeName: string): Promise<void> {
    if (!dbName || !storeName) {
      return Promise.reject(
        new Error('Database or store name not initialized')
      );
    }
    const dbFromMap = this.getDBFromMap(dbName);

    return new Promise((resolve, reject) => {
      const transaction = dbFromMap.transaction(storeName, 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.clear();

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  private getDBFromMap(dbName: string) {
    const dbFromMap = this.dbMap.get(dbName);
    if (!dbFromMap) {
      throw new Error(
        `Could not update store because there is no db with db name: ${dbName}`
      );
    }
    return dbFromMap;
  }

  // private async initDatabaseAsync(): Promise<IDBDatabase> {
  //   return new Promise((resolve, reject) => {
  //     const request = indexedDB.open(this.dbName, 1);
  //     this.db = request.result;

  //     request.onupgradeneeded = (event) => {
  //       // const db = request.result;
  //       // if (!db.objectStoreNames.contains(this.storeName)) {
  //       //   db.createObjectStore(this.storeName, { keyPath: 'id' });
  //       // }
  //     };

  //     request.onsuccess = (event) => resolve(request.result);
  //     request.onerror = (event) => reject(request.error);
  //   });
  // }
  private async ensureObjectStoreExists(
    dbName: string,
    storeName: string
  ): Promise<IDBDatabase> {
    // Get the current database version from the map, or default to 1 if not found.
    let currentDB: IDBDatabase;
    try {
      const currentDB = this.getDBFromMap(dbName);
    } catch (error) {}
    const currentVersion = currentDB ? currentDB.version : 1;

    // Try to open the database with the current version first.
    let db = await this.openDatabaseAsync(dbName, currentVersion, [storeName]);

    // Check if the object store exists.
    if (db.objectStoreNames.contains(storeName)) {
      return db;
    }

    // If the object store doesn't exist, increment the version and create it.
    const newVersion = currentVersion + 1;
    db = await this.openDatabaseAsync(dbName, newVersion, [storeName]);

    return db;
  }

  private closeAllConnectionsStartsWith(
    startWith: string,
    dbs: IDBDatabaseInfo[]
  ): void {
    for (const db of dbs) {
      const dbName = db.name;
      if (!dbName.startsWith(startWith)) continue;
    }
    for (const [dbName, db] of this.dbMap) {
      db.close();
      this.dbMap.delete(dbName);
    }
  }
}
