<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" [fullScreen]="true" type="ball-triangle-path" >
</ngx-spinner>

<!--<div id="page-wrapper">-->
<!--  &lt;!&ndash; <app-navbar> </app-navbar> &ndash;&gt;-->
<!--  <div id="inner-page">-->
<!--    <iframe src="https://shuffll.co">-->

<!--    </iframe>-->
<!--  </div>-->

<!--</div>-->

