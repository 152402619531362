import {Component, Input} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';

@Component({
  selector: 'full-screen-loading',
  templateUrl: './full-screen-loading.component.html',
  styleUrls: ['./full-screen-loading.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({opacity: 0})),
      transition(':enter', [animate('0.5s ease-in')]),
      transition(':leave', [animate('0.5s ease-out')])
    ])
  ]
})
export class FullScreenLoadingComponent {

  @Input('text') text = 'loading...'

}
