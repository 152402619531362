import { Injectable } from '@angular/core';
import {
  AuthenticateResponses,
  AuthenticationService,
} from './authentication.service';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '../api/auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  // Inject both the AuthenticationService and the Router to be able to perform redirects

  isMobile: boolean;

  constructor(
    public auth: AuthenticationService,
    private router: Router,
    private userService: UserService
  ) {
    this.isMobile = this.userService.isMobileUser();
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // console.log('Running guard', state.url)
    // Attempt to authenticate the user
    const authResult = await this.auth.authorize(true);

    // If the user is authenticated
    if (
      authResult?.response === AuthenticateResponses.AUTHENTICATED &&
      authResult.user
    ) {
      // If the user is trying to access the onboarding route
      if (state.url.startsWith('/onboarding')) {
        // If the user is already active (completed onboarding)
        if (authResult.user.active) {
          // Redirect them to the dashboard since they don't need onboarding
          this.router.navigate(['/dashboard']);
          return false;
        }
        // If the user is not active (hasn't completed onboarding), allow them to access onboarding
        return true;
      }
      // For routes other than onboarding
      else {
        // If the user isn't active yet (hasn't completed onboarding)
        if (!authResult.user.active) {
          console.log('User need to go through onboarding');
          // Redirect them to onboarding to complete the process
          this.router.navigate(['/onboarding']);
          return false;
        }
        // If the user is active (has completed onboarding), allow them to access the intended route
        return true;
      }
    }
    // If the server isn't responsive during authentication
    else if (
      authResult?.response === AuthenticateResponses.SERVER_NOT_RESPONSIVE
    ) {
      console.error('Server not responsive');
      // Navigate to a custom error page or handle the error as needed
      this.auth.goToConnectionErrorPage(state.url);
      return false;
    }
    // If the authentication fails for any other reason
    else {
      // Logout the user and redirect them as necessary
      this.auth.logout(state.url);
      return false;
    }
  }
}
