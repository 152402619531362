<div class="positions" id="positions">
  <div
    *ngFor="let position of stagePositions"
    class="position"
    [style.background-image]="
      'url(' + this.baseCdnUrl + this.silhouetteImg + ')'
    "
    [style.top]="position.y + '%'"
    [style.left]="position.x + '%'"
    [style.height]="position.height + '%'"
    [style.width]="position.width + '%'"></div>
</div>
