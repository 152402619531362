import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { IFont } from '../../../models/configs/fonts.model';
import { ConfigurationAuthApiService } from '../../api/auth/config-auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class FontsStoreService {
  private _fonts$ = new BehaviorSubject<IFont[]>(null);
  public fonts$ = this._fonts$.asObservable();

  constructor(private configAuthService: ConfigurationAuthApiService) {
    this.configAuthService.getFonts$().subscribe({
      next: (fontsConfigs) => {
        if (!fontsConfigs) {
          this._fonts$.error(new Error('Fonts configuration not found'));
          return;
        }

        const fonts = fontsConfigs.fonts;
        this._fonts$.next(fonts);
      },
      error: (error) => {
        this._fonts$.error(error); // Propagates the error to all subscribers
      },
    });
  }

  getFontsAsync() {
    const fonts = this._fonts$.value;
    if (fonts) return fonts;

    return new Promise<IFont[]>((fontResolve, fontReject) => {
      this._fonts$.subscribe({
        next: (fonts) => {
          if (!fonts) return;

          return fontResolve(fonts);
        },
        error: (error) => {
          return fontReject(error);
        },
      });
    });
  }
}
