import { environment } from 'src/environments/environment';

const BASE_URL = environment.api;

export const CONFIGS_URL = BASE_URL + '/auth/config';

export const GET_ALL_WALKTHROUGH_TASKS = CONFIGS_URL + '/walkthrough_steps';

export const UPDATE_COMPLETED_TASK =
  CONFIGS_URL + '/completed_walkthrough_task';
