<div
  *ngIf="
    plansService.unifiedProduct$ | async as unifiedProduct;
    else loadingIndicator
  "
  class="wrapper row-centered">
  <div class="left-side">
    <div>
      <h2>{{ unifiedProduct.paymentConfigs.text.header }}</h2>
      <h5>{{ unifiedProduct.paymentConfigs.text.subHeader }}</h5>

      <div class="feature-list">
        <div
          class="row-centered feature"
          *ngFor="
            let feature of unifiedProduct.productConfigs.product
              ?.marketing_features
          ">
          <mat-icon class="smaller-icon">check_circle</mat-icon>
          {{ feature.name }}
        </div>
      </div>
    </div>

    <div
      class="terms"
      *ngIf="plansService.unifiedProduct$ | async as unifiedProduct"
      [innerHTML]="unifiedProduct.paymentConfigs.text.body">
      <!--        By subscribing, you agree to our-->
      <!--        <a routerLink="/terms" target="_blank">Terms of Service</a> and-->
      <!--        <a routerLink="/privacy" target="_blank">Privacy Policy</a>.<br/>-->
    </div>
  </div>

  <div class="right-side">
    <payment-form
      [price]="unifiedProduct.productConfigs.price"
      [product]="unifiedProduct.productConfigs.product"></payment-form>
  </div>
</div>

<ng-template #loadingIndicator>
  <loading-indicator class="wrapper loading-indicator"></loading-indicator>
</ng-template>
