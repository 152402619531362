import {Injectable} from '@angular/core';
import {SignalingService} from './signaling.service';
import {DisplayMediaScreenShare, MediaService} from './media.service';
import {LoggerService} from '../logger.service';
import {EventbusService, EventType, IEventType} from './eventbus.service';
import {ProfileService} from './profile.service';
import {
  CONNECT_VIDEO_STATUS,
  IVideoAssetAndFlag,
  RequestMethod,
  ROLE,
  SCREENSHARE_CONSTRAINTS,
  SflBoardComp,
  SflMedia,
  SflMediaSource,
  SflPeer,
  Showroom,
  VIDEORESOLUTION,
} from '../../models/defines';
import {types as mediaTypes} from 'mediasoup-client';
import * as hark from 'hark';
import 'webrtc-adapter';
import {ConfigurationService} from '../../services/configuration.service';
import {StatsService} from './stats.service';
import {BehaviorSubject, Subject} from 'rxjs';

export enum PeerChangeEventType {
  addStream,
  closeStream,
  addMedia,
  removeMedia,
  changeMedia,
}

export interface IPeerChangeEvent {
  type: PeerChangeEventType;
  mediaSource: SflMediaSource;
  peer: SflPeer;
}

export interface ISpeakingEvent {
  peer: SflPeer;
  isSpeaking: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PeerService {
  public localCam: SflMedia;
  public localMic: SflMedia;
  public pScreen: DisplayMediaScreenShare;
  // TODO: Delete sometime peerStreams
  public peerStreams: SflMedia[] = [];
  public peersInfo: SflPeer[] = [];
  public peer$: Subject<IPeerChangeEvent> = new Subject<IPeerChangeEvent>();
  public speaking$: Subject<ISpeakingEvent> = new Subject<ISpeakingEvent>();
  public producerMap = new Map<string, mediaTypes.Producer>();
  public toggleSide = false;
  public networkType;

  public hasInit = new BehaviorSubject<boolean>(false);

  constructor(
    private signaling: SignalingService,
    private media: MediaService,
    private logger: LoggerService,
    private config: ConfigurationService,
    private eventbus: EventbusService,
    private profile: ProfileService,
    private stats: StatsService
  ) {
    this.eventbus.socket$.subscribe(async (event: IEventType) => {
      const {type} = event;
      if (type === EventType.socket_connected) {
        if (!this.hasInit.value) {
          await this.init();
          await this.roomUpdate();
          await this.connectMediaServer();

          this.hasInit.next(true);
        } else {
          await this.connectMediaServer(true);
          await this.iceRestart();
        }
      }
    });

    this.eventbus.media$.subscribe(async (event: IEventType) => {
      const {type} = event;

      if (type === EventType.media_newPeer) {
        this.newPeer(event.data);
      }

      if (type === EventType.media_peerClose) {
        this.peerClosed(event.data);
      }

      if (type === EventType.media_consumerClosed) {
        this.consumerClosed(event.data);
      }

      if (type === EventType.media_newConsumer) {
        console.log('hey new peer');
        this.newConsumer(event.data);
      }

      if (type === EventType.media_peerProducersClosed) {
        this.peerProducersClosed(event.data);
      }
    });

    this.eventbus.peer$.subscribe((event: IEventType) => {
      if (event.type === EventType.peer_changeRole) {
        const {peerId, role} = event.data;
        this.peersInfo.forEach((peer) => {
          if (peer.id === peerId) {
            peer.role = role;
          }
        });
      }
    });
  }

  public addMediaPeer(video: IVideoAssetAndFlag) {
    if (video) {
      const peerInfo = new SflPeer(
        video.videoAsset._id,
        video.videoAsset.displayName,
        ROLE.VIDEO,
        'MediaSource'
      );
      /// This ensures that videoSource is initialized as an empty object if it doesn't exist on peerInfo
      const videoAndAutoplay = peerInfo.videoAndAutoplay;

      videoAndAutoplay.videoSource.uploadPath = video.videoAsset.uploadPath;
      videoAndAutoplay.videoSource.dashPath = video.videoAsset.dashPath;
      videoAndAutoplay.autoPlay = video.autoPlay;
      this.peer$.next({
        peer: peerInfo,
        type: PeerChangeEventType.addMedia,
        mediaSource: SflMediaSource.media,
      });
    }
  }

  public removeMediaPeer(video: IVideoAssetAndFlag) {
    if (video) {
      const peerInfo = new SflPeer(
        video.videoAsset._id,
        video.videoAsset.displayName,
        ROLE.VIDEO,
        'MediaSource',
        'bla'
      );
      peerInfo.videoAndAutoplay.videoSource.uploadPath =
        video.videoAsset.uploadPath;
      this.peer$.next({
        peer: peerInfo,
        type: PeerChangeEventType.removeMedia,
        mediaSource: SflMediaSource.media,
      });
    }
  }

  private async iceRestart() {
    //this.logger.debug('iceRestart begin...');
    const paramsS = (await this.signaling.sendIceRestart(
      this.media.sendTransport.id
    )) as any;
    await this.media.sendTransport.restartIce({
      iceParameters: paramsS.iceParameters,
    });

    const paramsR = (await this.signaling.sendIceRestart(
      this.media.recvTransport.id
    )) as any;
    await this.media.recvTransport.restartIce({
      iceParameters: paramsR.iceParameters,
    });
  }

  private async newConsumer(data: any) {
    let {peerId, appData, id, producerId} = data;

    const consumer = await this.media.recvTransport.consume({
      ...data,
      appData: {...appData, peerId, producerId},
    });

    consumer.on('transportclose', () => {
      this.logger.warn('transportclose !');
    });

    this.logger.debug(
      'new consumer, kind: %s, consumer id: %s, producerId: %s, peerId: %s, appData: %s',
      consumer.kind,
      consumer.id,
      producerId,
      peerId,
      appData.source
    );

    const appdata = appData.source as string;
    const appArray = appdata.split('_'); // 0 - peerId, 1 - source ; 2-type
    const source = appArray[1]; // source as source id

    //this.logger.debug('appdata, peerId: %s, source: %s, type: %s', appArray[0], appArray[1], appArray[2]);

    switch (source) {
      case SflMediaSource.cameramic:
        this.newConsumerCam(peerId, consumer, source);
        break;
      case SflMediaSource.screen:
        this.newConsumerScreen(peerId, consumer, source);
        break;
      case SflMediaSource.media:
        this.newCosumerMedia(peerId, consumer, source);
        break;
    }
  }

  private newConsumerCam(
    peerId: string,
    consumer: mediaTypes.Consumer,
    source: string
  ) {
    const peerInfo = this.getPeerInfo(peerId);
    peerInfo.connectVideoStatus = CONNECT_VIDEO_STATUS.Connected;

    let stream = null;
    const existStream = this.peerStreams.find(
      (ps) => ps.source === source && ps.peer.id === peerId
    );

    if (existStream) {
      stream = existStream;
    } else {
      stream = new SflMedia();
      this.peerStreams = [...this.peerStreams, stream];
      stream.source = source;

      peerInfo.camStream = stream;
      stream.peer = peerInfo;
    }

    stream.addTrack(consumer.track);

    if (consumer.kind === 'video') {
      stream.videoConsumer = consumer;
      stream.toggleSide = this.toggleSide;
      this.stats.setVideoConsumer(consumer);
    } else {
      stream.audioConsumer = consumer;
      this.setupVolumeDetect(stream);

      this.stats.setAudioConsumer(consumer);
    }

    // do not consumer audio produced by itself
    if (peerId === this.profile.userPeer.id && consumer.kind === 'audio') {
      stream.getAudioTracks()[0].enabled = false;
    }

    this.peer$.next({
      peer: peerInfo,
      type: PeerChangeEventType.addStream,
      mediaSource: SflMediaSource.cameramic,
    });
  }

  private newConsumerScreen(
    peerId: string,
    consumer: mediaTypes.Consumer,
    source: string
  ) {
    const peerInfo = this.getPeerInfo(peerId);
    let stream = null;

    if (peerInfo.screenStream) {
      stream = peerInfo.screenStream;
    } else {
      stream = new SflMedia();
      stream.source = source;
      peerInfo.screenStream = stream;
      stream.peer = peerInfo;
      this.peerStreams = [...this.peerStreams, stream];
    }

    stream.addTrack(consumer.track);
    if (consumer.kind === 'video') {
      stream.videoConsumer = consumer;
    } else {
      stream.audioConsumer = consumer;
    }

    // do not consumer audio produced by itself
    if (peerId === this.profile.userPeer.id && consumer.kind === 'audio') {
      stream.getAudioTracks()[0].enabled = false;
    }

    this.profile.switchBoardComponent(SflBoardComp.sharescreen);

    this.peer$.next({
      peer: peerInfo,
      type: PeerChangeEventType.addStream,
      mediaSource: SflMediaSource.screen,
    });
  }

  private newCosumerMedia(
    peerId: string,
    consumer: mediaTypes.Consumer,
    source: string
  ) {
    // not consume media produce by me
    // if (peerId === this.profile.me.id) {
    //   consumer.close();
    //   return;
    // }

    const peerInfo = this.getPeerInfo(peerId);

    let stream = null;

    if (peerInfo.mediaStream) {
      stream = peerInfo.mediaStream;
    } else {
      stream = new SflMedia();
      stream.source = source;
      peerInfo.mediaStream = stream;
      stream.peer = peerInfo;
      this.peerStreams = [...this.peerStreams, stream];
    }

    stream.addTrack(consumer.track);
    if (consumer.kind === 'video') {
      stream.videoConsumer = consumer;
    } else {
      stream.audioConsumer = consumer;
    }

    // do not consumer audio produced by itself
    if (peerId === this.profile.userPeer.id && consumer.kind === 'audio') {
      stream.getAudioTracks()[0].enabled = false;
    }

    this.profile.switchBoardComponent(SflBoardComp.sharemedia);

    this.peer$.next({
      peer: peerInfo,
      type: PeerChangeEventType.addStream,
      mediaSource: SflMediaSource.media,
    });
  }

  public cameraToggleSide(toggle: boolean) {
    this.peersInfo
      .map((peer) => peer.camStream)
      .forEach((stream) => {
        stream.toggleSide = toggle;
      });
    this.toggleSide = toggle;
  }

  private setupVolumeDetect(stream: SflMedia) {
    console.warn('Avoiding volume detection for now');
    return;

    const speechEvents = hark(stream, {});
    speechEvents.on('speaking', () => {
      // this.logger.debug('%s speaking.', stream.peer.id);
      stream.volume = 10;
    });
    speechEvents.on('stopped_speaking', () => {
      // this.logger.debug('%s stopped_speaking.', stream.peer.id);
      stream.volume = 0;
    });
    speechEvents.on('volume_change', (volume, threshold) => {
      const calVolume = volume + 100;
      if (calVolume > 50) {
        stream.volume = Math.floor((calVolume - 50) * 2);
      }
    });
  }

  private consumerClosed(data) {
    const {consumerId} = data;

    //this.logger.debug('consumerClosed, %s', consumerId);

    const foundStream = this.peerStreams.find((ps) => {
      return (
        (ps.videoConsumer && ps.videoConsumer.id === consumerId) ||
        (ps.audioConsumer && ps.audioConsumer.id === consumerId)
      );
    });

    if (!foundStream) {
      //this.logger.debug('consumerClosed, do not find consumer: %s', consumerId);
      return;
    }

    const peer = foundStream.peer;

    if (peer.id === this.profile.userPeer.id) {
      // console.log("Omg im disconnected");
      // console.log("Media, " + this.media);
      console.log('supssssss');
      peer.screenStream = null;
      this.peer$.next({
        peer,
        type: PeerChangeEventType.closeStream,
        mediaSource: SflMediaSource.screen,
      });
    }

    if (
      foundStream.videoConsumer &&
      foundStream.videoConsumer.id === consumerId
    ) {
      foundStream.removeTrack(foundStream.videoConsumer.track);
      foundStream.videoConsumer.close();
      foundStream.videoConsumer = null;
    }

    if (
      foundStream.audioConsumer &&
      foundStream.audioConsumer.id === consumerId
    ) {
      foundStream.removeTrack(foundStream.audioConsumer.track);
      foundStream.audioConsumer.close();
      foundStream.audioConsumer = null;
    }

    if (!foundStream.videoConsumer && !foundStream.audioConsumer) {
      this.peerStreams = this.peerStreams.filter((ps) => ps !== foundStream);

      if (peer.camStream === foundStream) {
        peer.camStream = null;
        this.peer$.next({
          peer,
          type: PeerChangeEventType.closeStream,
          mediaSource: SflMediaSource.cameramic,
        });
      } else if (peer.screenStream === foundStream) {
        peer.screenStream = null;
        this.peer$.next({
          peer,
          type: PeerChangeEventType.closeStream,
          mediaSource: SflMediaSource.screen,
        });
      } else if (peer.mediaStream === foundStream) {
        peer.mediaStream = null;
        this.peer$.next({
          peer,
          type: PeerChangeEventType.closeStream,
          mediaSource: SflMediaSource.media,
        });
      } else {
        this.logger.error('Do not find stream in peer when stream closed!');
      }
    }
  }

  public isEnableCamera(peer: SflPeer) {
    return (
      peer.camStream &&
      peer.camStream.videoConsumer &&
      !peer.camStream.videoConsumer.closed
    );
  }

  public isEnableMic(peer: SflPeer) {
    return (
      peer.camStream &&
      peer.camStream.audioConsumer &&
      !peer.camStream.audioConsumer.closed
    );
  }

  private peerClosed(data: any) {
    const {peerId} = data;
    this.peersInfo = this.peersInfo.filter((p) => p.id !== peerId);
  }

  private newPeer(data: any) {
    const {id} = data;
    if (this.getPeerInfo(id)) {
      this.logger.warn('peer %s already existed!', id);
      return;
    }

    const peer = new SflPeer(
      data.id,
      data.displayName,
      data.role,
      data.platform,
      data.string
    );

    //this.logger.debug('newPeer, %o', peer);
    this.peersInfo = [...this.peersInfo, peer];
  }

  async connectMediaServer(reconnect = false) {
    if (reconnect) {
      if (
        this.media.recvTransport &&
        (await this.checkTransport(this.media.recvTransport.id))
      ) {
        //this.logger.debug('recv transport closed in server, %s', this.media.recvTransport.id);
        this.media.recvTransport.close();
      }
      if (
        this.media.sendTransport &&
        (await this.checkTransport(this.media.sendTransport.id))
      ) {
        //this.logger.debug('send transport closed in server, %s', this.media.sendTransport.id);
        this.media.sendTransport.close();
      }
    }

    if (!this.media.device.loaded) {
      const routerRtpCapabilities =
        await this.signaling.getRouterRtpCapabilities();
      //this.logger.debug('get route capabilities from server: ', routerRtpCapabilities);

      const loaded = await this.media.load({routerRtpCapabilities});
      if (!loaded) {
        this.logger.error('device load error!');
        return false;
      }
    }

    try {
      await this._createSendTransport();
      await this._createRecvTransport();
      await this.joinRoom();
    } catch (e) {
      this.logger.error(e);
      return false;
    }

    return true;
  }

  private async checkTransport(transportId) {
    const {closed} = (await this.signaling.sendRequest(
      RequestMethod.getTransportStats,
      {transportId}
    )) as any;

    return closed;
  }

  async joinRoom() {
    const {joined, peers} = await this.signaling.join({
      role: this.profile.userPeer.role,
      displayName: this.profile.userPeer.displayName,
      picture: this.profile.userPeer.picture,
      platform: this.profile.userPeer.platform,
      rtpCapabilities: this.media.device.rtpCapabilities,
      userShuffllToken: this.profile?.userPeer?.userShuffllToken,
    });

    if (joined) {
      return;
    }

    //this.logger.debug('joined, peersinfo: %s', JSON.stringify(peers));

    for (const peer of peers) {
      this.newPeer(peer);
    }
  }

  async init() {
    this.peersInfo = [...this.peersInfo, this.profile.userPeer];

    if (!this.profile.audioDeviceConfigs || !this.profile.videoDeviceConfigs) {
      await this.media.enumerateDevices();

      if (typeof this.media.videoDevices[0] !== 'undefined') {
        this.profile.videoDeviceConfigs = this.media.videoDevices[0].deviceId;
      }
      if (typeof this.media.audioDevices[0] !== 'undefined') {
        this.profile.audioDeviceConfigs = this.media.audioDevices[0].deviceId;
      }
    }
  }

  getPeerInfo(peerId: string) {
    return this.peersInfo.find((peer) => peer.id === peerId);
  }

  async _createSendTransport() {
    if (
      this.media.sendTransport &&
      !this.media.sendTransport.closed &&
      this.media.sendTransport.connectionState !== 'closed' &&
      this.media.sendTransport.connectionState !== 'failed'
    ) {
      // this.logger.debug('sendTransport status : %s, connectionState: %s',
      //   this.media.sendTransport.closed,
      //   this.media.sendTransport.connectionState);
      return;
    }

    const transportInfo = await this.signaling.createWebRtcTransport({
      forceTcp: this.profile.forceTcp,
      producing: true,
      consuming: false,
    });
    // this.logger.debug('producer transportInfo: %o', transportInfo);

    const transport = this.media.createSendTransport(transportInfo);
    transport.on('connect', async ({dtlsParameters}, callback, errback) => {
      // this.logger.debug('transport connect event, dtlsParameter: %o', dtlsParameters);

      await this.signaling
        .sendRequest(RequestMethod.connectWebRtcTransport, {
          transportId: transport.id,
          dtlsParameters,
        })
        .then(callback)
        .catch(errback);
    });
    transport.on(
      'produce',
      async ({kind, rtpParameters, appData}, callback, errback) => {
        // this.logger.debug('transport produce event, kind: %s, rtpParameters: %o', kind, rtpParameters);

        await this.signaling
          .sendRequest(RequestMethod.produce, {
            transportId: transport.id,
            kind,
            rtpParameters,
            appData,
          })
          .then(callback)
          .catch(errback);
      }
    );

    this.media.setSendTransport(transport);
  }

  async produceLocalCamera() {
    if (!this.localCam) {
      try {
        await this.getLocalCameraAsync();
      } catch (e) {
        console.error(e);
        return;
      }
    }
    const producer = await this.produceVideo(
      this.localCam,
      SflMediaSource.cameramic
    );
    this.stats.setVideoProducer(producer);
  }

  async produceLocalMic() {
    if (!this.localMic) {
      try {
        await this.getLocalMic();
      } catch (e) {
        return;
      }
    }
    const producer = await this.produceAudio(
      this.localMic,
      SflMediaSource.cameramic
    );
    this.stats.setAudioProducer(producer);
  }

  async stopLocalCamera() {
    const sourceVideo =
      this.profile.userPeer.id + '_' + SflMediaSource.cameramic + '_' + 'video';

    const videoProducer = this.producerMap.get(sourceVideo);

    if (videoProducer) {
      videoProducer.close();
      await this.signaling.sendRequest(RequestMethod.closeProducer, {
        producerId: videoProducer.id,
      });

      // this.logger.debug('stopLocalCamera, video: %s', videoProducer.id);
      this.producerMap.delete(sourceVideo);
    }

    ////TODO: SHACHAR, what will happen if we do this first?
    if (this.localCam) {
      this.localCam.getVideoTracks().forEach((track) => track.stop());
      this.localCam = null;
    }
  }

  async stopLocalMic() {
    const sourceAudio =
      this.profile.userPeer.id + '_' + SflMediaSource.cameramic + '_' + 'audio';

    const audioProducer = this.producerMap.get(sourceAudio);

    if (audioProducer) {
      audioProducer.close();
      await this.signaling.sendRequest(RequestMethod.closeProducer, {
        producerId: audioProducer.id,
      });

      // this.logger.debug('stopLocalCamera, audio: %s', audioProducer.id);

      this.producerMap.delete(sourceAudio);
    }

    if (this.localMic) {
      // 'console.log - stoping mic track'
      this.localMic.getAudioTracks().forEach((track) => track.stop());
      this.localMic = null;
    }
  }

  async produceAudio(stream: MediaStream, src: string) {
    // this.logger.debug('produce now kind: audio, id: %s.', stream.id);

    if (!this.media.device.canProduce('audio')) {
      this.logger.error('this device can not produce audio!');
      return;
    }

    const track = stream.getAudioTracks()[0];
    if (!track) {
      this.logger.error('Do not find audio track!');
      return null;
    }

    const source = this.profile.userPeer.id + '_' + src + '_' + 'audio';

    const producer = await this.media.sendTransport.produce({
      track,
      appData: {source},
    });

    producer.on('transportclose', () => {
      this.logger.warn('video source %s transportclose !', source);
      this.producerMap.delete(source);
    });

    producer.on('trackended', () => {
      // this.logger.debug('audio source %s trackended!', source);
      this.signaling.sendRequest(RequestMethod.closeProducer, {
        producerId: producer.id,
      });

      this.producerMap.delete(source);
    });

    this.producerMap.set(source, producer);
    return producer;
  }

  async produceVideo(stream: MediaStream, src: SflMediaSource) {
    console.log('trying to understand before');
    if (!this.media.device.canProduce('video')) {
      this.logger.error('this device can not produce video!');
      return;
    }
    const tracks = stream.getVideoTracks();
    if (!tracks || tracks.length === 0) {
      this.logger.error('Do not find video track!');
      return;
    }
    const track = tracks[0];
    const source = this.profile.userPeer.id + '_' + src + '_' + 'video';
    const params: mediaTypes.ProducerOptions = {
      track,
      appData: {
        source,
      },
      codec: this.media.device.rtpCapabilities.codecs.find(
        (codec) => codec.mimeType === 'video/VP8'
      ),
      stopTracks: true,
    };

    const producer = await this.media.sendTransport.produce(params);

    producer.on('transportclose', () => {
      this.logger.warn('video source %s transportclose !', source);
      this.producerMap.delete(source);
    });

    producer.on('trackended', () => {
      // this.logger.debug('video source %s trackended!', source);
      this.signaling.sendRequest(RequestMethod.closeProducer, {
        producerId: producer.id,
      });
      this.producerMap.delete(source);
    });

    producer.observer.on('close', () => {
    });

    producer.observer.on('pause', () => {
    });
    this.producerMap.set(source, producer);
    return producer;
  }

  public async replaceProducerDevicesAsync(
    cameraStream: MediaStream,
    micStream: SflMedia,
    src: SflMediaSource
  ) {
    const sourceVideo = this.profile.userPeer.id + '_' + src + '_' + 'video';
    const sourceAudio = this.profile.userPeer.id + '_' + src + '_' + 'audio';

    const videoProducer = this.producerMap.get(sourceVideo);
    const audioProducer = this.producerMap.get(sourceAudio);

    const videoTrack = cameraStream.getVideoTracks()[0];
    const audioTrack = micStream.getAudioTracks()[0];

    try {
      await videoProducer?.replaceTrack({track: videoTrack});
      await audioProducer?.replaceTrack({track: audioTrack});
    } catch (error) {
      console.warn('cant replace device', error);
    }
  }

  getProducer(producerId: string): mediaTypes.Producer {
    let producer;
    this.producerMap.forEach((value, key) => {
      if (value.id === producerId) {
        producer = value;
      }
    });

    return producer;
  }

  async startScreenShare() {
    const screen = new DisplayMediaScreenShare();
    try {
      await screen.start(SCREENSHARE_CONSTRAINTS);
    } catch (e) {
      this.logger.error(e);
      return false;
    }

    this.pScreen = screen;

    const producer = await this.produceVideo(
      this.pScreen.pStream,
      SflMediaSource.screen
    );

    producer.on('trackended', () => {
      this.pScreen = null;
    });

    return true;
  }

  stopScreenShare() {
    if (this.pScreen) {
      this.pScreen.stop();
      this.pScreen = null;
    }
  }

  stopProduceStream(stream: SflMedia) {
    stream.getTracks().forEach((track) => {
      track.stop();
      stream.removeTrack(track);
      track.dispatchEvent(new Event('ended'));
    });
  }

  async _createRecvTransport() {
    if (
      this.media.recvTransport &&
      !this.media.recvTransport.closed &&
      this.media.recvTransport.connectionState !== 'closed' &&
      this.media.recvTransport.connectionState !== 'failed'
    ) {
      // this.logger.debug('recvTransport status : %s, connectionState: %s',
      //   this.media.recvTransport.closed,
      //   this.media.recvTransport.connectionState);

      return;
    }

    const transportInfo = await this.signaling.createWebRtcTransport({
      forceTcp: this.profile.forceTcp,
      producing: false,
      consuming: true,
    });
    //this.logger.debug('recv transportInfo: %o', transportInfo);

    const transport = this.media.createRecvTransport(transportInfo);
    //this.logger.debug('recv transport id %s, close: %o, direction: %s, connectState: %s',
    //   transport.id, transport.closed, transport.direction, transport.connectionState
    // );

    transport.on('connect', ({dtlsParameters}, callback, errback) => {
      //this.logger.debug('recv transport connect event, dtlsParameters: %o', dtlsParameters);
      this.signaling
        .connectWebRtcTransport({
          transportId: transport.id,
          dtlsParameters,
        })
        .then(callback)
        .catch(errback);
    });

    this.media.setRecvTransport(transport);
  }

  async getLocalCameraAsync() {
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: this.profile.videoDeviceConfigs,
          width: VIDEORESOLUTION[this.profile.mainVideoResolution].width,
          height: VIDEORESOLUTION[this.profile.mainVideoResolution].height,
          ...this.config.mediasoup.videoConstraints,
        },
      });
    } catch (e) {
      this.logger.error(e);
      throw new Error('Open Camera Error!');
    }

    //this.logger.debug('Local video stream', stream);
    this.localCam = stream;
    this.localCam.peer = this.profile.userPeer;
    return this.localCam;
  }

  async getLocalMic() {
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: this.profile.audioDeviceConfigs,
          ...this.config.mediasoup.audioConstraints,
        },
      });
    } catch (e) {
      this.logger.error(e);
      throw new Error('Open Mic Error!');
    }

    this.localMic = stream;
    this.setupVolumeDetect(this.localMic);
    this.localMic.peer = this.profile.userPeer;
    return this.localMic;
  }

  async roomUpdate() {
    const roomInfo = (await this.signaling.getShowroomInfo()) as Showroom;

    this.eventbus.show$.next({
      type: EventType.show_roomUpdate,
      data: roomInfo,
    });
  }

  async setAsPresenter() {
    await this.signaling.sendChangeRole(ROLE.HOST);
    this.profile.setRole(ROLE.HOST);
  }

  private peerProducersClosed(data: any) {
    this.stopLocalMic();
    this.stopLocalCamera();
  }
}
