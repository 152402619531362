import { Injectable } from '@angular/core';

/**
 * Extending PermissionName because somehow it does not has camera and microphone inside but it does works
 */
export type ExtendedPermissionName =
  | PermissionName
  | 'camera'
  | 'microphone'
  | 'midi';

@Injectable({
  providedIn: 'root',
})
export class NavigatorPermissionsService {
  constructor() {}

  permissionStatus: PermissionStatus;
  public async monitorPermissionAsync(
    name: ExtendedPermissionName,
    callback: (status: PermissionStatus | string) => void
  ) {
    const permission = await this.checkPermissionAsync(name);
    callback(permission);
  }

  private async checkPermissionAsync(
    name: ExtendedPermissionName
  ): Promise<PermissionState> {
    switch (name) {
      case 'camera':
        try {
          await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          return 'granted';
        } catch (error) {
          return 'denied' as PermissionState;
        }

      case 'microphone':
        try {
          await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          return 'granted';
        } catch (error) {
          return 'denied';
        }
    }
  }
}
