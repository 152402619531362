import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {IAsset} from 'src/app/models/defines';
import {AssetsComponent} from '../../../pages/private/dashboard/assets/assets.component';
import {ConfigurationService} from 'src/app/services/configuration.service';

@Component({
    selector: 'app-image-selector',
    templateUrl: './image-selector.component.html',
    styleUrls: ['./image-selector.component.scss'],
})
export class ImageSelectorComponent implements OnInit, OnChanges {
    @Input()
    selectedImage: string;

    imagePathSubject = new BehaviorSubject<string>(null);

    @Output()
    onImageSelected = new BehaviorSubject(null);

    // Output prop name must be Input prop name + 'Change'
    // Use in your component to write an updated value back out to the parent
    @Output()
    selectorOpen = new EventEmitter<boolean>(false);

    constructor(
        public dialog: MatDialog,
        private config: ConfigurationService,
    ) {
    }

    updateImagePath() {
        if (this.selectedImage) {
            const assetsBasePath = this.config.baseCdnUrl;
            const fullPath = assetsBasePath + this.selectedImage;
            this.imagePathSubject.next(fullPath);
        }
    }

    ngOnInit(): void {
        this.updateImagePath();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedImage) {
            // also add this check
            this.updateImagePath();
        }
    }

    openImageSelectorDialog() {
        const imageSelectorDialog = this.dialog.open(AssetsComponent, {
            disableClose: false,
            panelClass: 'padding-modal',
            minWidth: '90vw',
            data: {
                hosted: true,
                dialogType: 'image',
            },
        });

        imageSelectorDialog.afterClosed().subscribe((result: IAsset) => {
            this.selectorOpen.next(false);
            if (result?.uploadPath) {
                this.selectedImage = result.uploadPath;
                this.onImageSelected.next(result.uploadPath);
            }
        });

        imageSelectorDialog.afterOpened().subscribe(() => {
            this.selectorOpen.next(true);
        });
    }
}
