<div class="icon-image-wrapper" *ngIf="!(authentication.isAuthenticated$ | async)">
    <img src="/assets/images/icons/video-conference.svg">
</div>
<h1 mat-dialog-title> Join {{data.host}}'s Production</h1>
<div mat-dialog-content>

    <div *ngIf="authentication.isAuthenticated$ | async">
        <div id="sub-title">
            Get ready and setup your camera & mic.
        </div>
        <app-user-media-selector></app-user-media-selector>
    </div>


    <p [hidden]="authentication.isAuthenticated$ | async">
        You’re about to ask {{data.host}} to join the general.<br>
        You will get notified when {{data.host}} accepts your join request.<br>
        Please login to continue.<br>
    </p>

</div>
<div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">{{(authentication.isAuthenticated$ | async) ? 'No Thanks' : 'Cancel'}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial
            *ngIf="authentication.isAuthenticated$ | async">{{this.profileService?.userPeer?.participationToken ? 'Join' : 'Ask To Join'}}
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial
            *ngIf="!(authentication.isAuthenticated$ | async)" (click)="authentication.goToLogin({join:true})">Login
    </button>
</div>
