<div class="page-wrapper">
    <ng-container *ngIf="(plansService.productsAndPrices$ | async)?.length > 0; else loading">
        <h3>Great videos start with a plan</h3>
        <plans></plans>

    </ng-container>


    <ng-template #loading>
        <loading-indicator size="large"></loading-indicator>
    </ng-template>
</div>
