<div id="wrapper">
  <div class="title">
    <h3>{{ scene.name }}</h3>
    <mat-slide-toggle color="primary" [(ngModel)]="scene.isHidden">Exclude Scene</mat-slide-toggle>

  </div>

  <div *ngIf="lottieConfigOnDisplay">
    <div
      class="lottie-wrapper"
      [style.aspect-ratio]="design.aspectRatio ?? '16/9'">
      <dynamic-lottie
        *ngIf="lottieConfigOnDisplay"
        [config]="lottieConfigOnDisplay"></dynamic-lottie>

      <studio-scene-chips [showIsHiddenChip]="scene.isHidden"></studio-scene-chips>

      <!--        <ng-lottie-->
      <!--                *ngIf="this.mainLottieOptions$ | async as options"-->
      <!--                [options]="options"-->
      <!--                (animationCreated)="animationCreated($event)"-->
      <!--        ></ng-lottie>-->
    </div>

    <mat-tab-group class="padded-tabs">
      <mat-tab
        *ngIf="dynamicTexts?.length > 0"
        [label]="'Texts (' + (dynamicTexts?.length ?? 0) + ')'">
        <div *ngFor="let dynamicText of dynamicTexts">
          <mat-form-field class="flex-stretch" appearance="fill">
            <!--      <mat-label>{{dynamicText.}}</mat-label>-->
            <input
              [placeholder]="dynamicText.placeholder"
              matInput
              [(ngModel)]="dynamicText.value"/>
          </mat-form-field>
        </div>
      </mat-tab>

      <mat-tab
        *ngIf="dynamicImages?.length > 0"
        [label]="'Images (' + (dynamicImages?.length ?? 0) + ')'">
        <div class="image-grid">
          <div *ngFor="let dynamicImage of dynamicImages">
            <!--    <input matInput [value]="dynamicText.id">-->
            <app-image-selector
              [selectedImage]="dynamicImage?.value"
              (onImageSelected)="imageSelected($event, dynamicImage)"
              (selectorOpen)="selectorDialogOpenEvent($event)">
            </app-image-selector>
          </div>
        </div>
      </mat-tab>

      <!--        <mat-tab [label]="'Videos'">-->
      <!--            <div class="video-grid">-->
      <!--                <app-video-selector-->
      <!--                        [dummy]="true"-->
      <!--                        (selectorOpen)="selectorDialogOpenEvent($event)"-->
      <!--                        (onVideosSelected)="insertNewVideo($event)"-->
      <!--                ></app-video-selector>-->
      <!--                <ng-container *ngIf="this.tempVideos">-->
      <!--                    <div *ngFor="let video of this.tempVideos">-->
      <!--                        <app-video-selector-->
      <!--                                [dummy]="false"-->
      <!--                                [selectedVideoPath]="video.videoAsset"-->
      <!--                                (selectorOpen)="selectorDialogOpenEvent($event)"-->
      <!--                                (onVideosSelected)="switchExistedVideo($event, video.videoAsset)"-->
      <!--                        ></app-video-selector>-->
      <!--                        <mat-slide-toggle-->
      <!--                                (change)="toggle($event, video)"-->
      <!--                                color="primary"-->
      <!--                                [(ngModel)]="video.autoPlay"-->
      <!--                        >Auto Play</mat-slide-toggle-->
      <!--                        >-->
      <!--                    </div>-->
      <!--                </ng-container>-->
      <!--            </div>-->
      <!--        </mat-tab>-->

      <mat-tab
        *ngIf="dynamicColors?.length > 0 || dynamicGradients?.length"
        [label]="
          'Colors (' +
          ((dynamicColors?.length ?? 0) + (dynamicGradients?.length ?? 0)) +
          ')'
        ">
        <h5 *ngIf="dynamicColors?.length > 0" class="small-title">Colors</h5>
        <div class="color-grid">
          <div *ngFor="let dynamicColor of this.dynamicColors; let i = index" class="color-wrap">


            <div *ngIf="!dynamicColor[HIDDEN_PROPERTY_NAME]">
              <h4>Color {{ i + 1 }}</h4>
              <div
                *ngIf="dynamicColor[TEMP_PROPERTY_NAME]"
                class="color-picker-container dynamic-color"
                [(colorPicker)]="dynamicColor[TEMP_PROPERTY_NAME]"
                [cpEyeDropper]="true"
                [style.background]="dynamicColor[TEMP_PROPERTY_NAME]"
                [cpDialogDisplay]="'popup'"
                [colorPickerRgba]="dynamicColor[TEMP_PROPERTY_NAME]"
                [dynamicColor]="dynamicColor"
                (colorPickerRgbaChange)="
                applyColorToElements($event, dynamicColor?.position)
              "></div>
            </div>
          </div>
        </div>

        <h5 *ngIf="dynamicGradients?.length > 0" class="small-title">Gradients</h5>

        <div *ngFor="let dynamicText of dynamicGradients; let i = index">

          <div>
            <h4>Gradient {{ i + 1 }}</h4>
            <app-gradient-picker
              [gradients]="dynamicText.value"
              [(gradientString)]="dynamicText.value"></app-gradient-picker>
          </div>
        </div>
      </mat-tab>

      <!-- <mat-tab
                *ngIf="tempImages?.length > 0"
                [label]="'Videos (' + (tempImages?.length ?? 0) + ')'"
              >
                <div class="image-grid">
                  <div *ngFor="let dynamicText of tempImages">
                        <input matInput [value]="dynamicText.id">-->
      <!-- <app-image-selector
                      [selectedImage]="dynamicText?.newText"
                      (onImageSelected)="imageSelected($event, dynamicText)"
                      (selectorOpen)="imageSelectorOpenEvent($event)"
                    >
                    </app-image-selector>
                  </div>
                </div>
              </mat-tab> -->
    </mat-tab-group>

    <div class="buttons-area" mat-dialog-actions>
      <button mat-dialog-close mat-raised-button>Cancel</button>
      <div>
        <button mat-raised-button (click)="preview()">Preview</button>
        <button (click)="save()" color="primary" mat-raised-button>Save</button>
      </div>
    </div>
  </div>

  <div *ngIf="!this.lottieConfigOnDisplay" class="spinner-wrapper">
    <mat-spinner></mat-spinner>
    <h4>Loading...</h4>
  </div>
</div>
