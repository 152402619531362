import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'archivefilter',
  pure: false

})
export class ArchiveFilterPipe implements PipeTransform {
  transform(items: any[], field: string, active: boolean): any[] {
    if (!items) return [];
    if (!active) return items;
    return items.filter(it => {
      return it[field] !== true
    })
  }
}
