<h2 mat-dialog-title>{{title??'Are you sure?'}}</h2>
<div mat-dialog-content>
  {{message??''}}
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null" cdkFocusInitial>Cancel</button>
  <button mat-button mat-raised-button color="primary"
          [mat-dialog-close]="{confirmed: true}">Confirm
  </button>
</div>


