import {Component, OnInit} from '@angular/core';
import {VoiceRecognitionService} from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-ai-prompter',
  templateUrl: './ai-prompter.component.html',
  styleUrls: ['./ai-prompter.component.scss']
})
export class AiPrompterComponent implements OnInit {

  constructor(public voiceRecognition: VoiceRecognitionService) {

  }

  ngOnInit(): void {
  }

  getText() {
     return Array.from(this.voiceRecognition.splitText.values());
  }

}
