<h1 mat-dialog-title>Feedback Time!</h1>
<div class="questions-contaiener" mat-dialog-content>
  <div
    class="question"
    *ngFor="let question of this.currentFeedbackForm?.questions">
    <span class="question">
      {{ question.text }}
    </span>

    <mat-chip-listbox
      *ngIf="question.type === questionTypeEnum.SELECT"
      [(ngModel)]="question.value">
      <mat-chip-option *ngFor="let option of question.options">{{
        option
      }}</mat-chip-option>
    </mat-chip-listbox>

    <textarea
      *ngIf="question.type === questionTypeEnum.TEXT"
      style="width: 100%; height: 300px"
      matInput
      type="text"
      [(ngModel)]="question.value"
      cdkTextareaAutosize
      placeholder="any bugs or features you'd like to tell us about?"></textarea>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog(false)">
    Remind me later
  </button>
  <button mat-raised-button color="primary" (click)="saveUserAnswers()">
    Send
  </button>
</div>
