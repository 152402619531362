import { Injectable } from '@angular/core';

export interface IColorPalette {
  name: string;
  value: {
    colorPrimary: string;
    colorSecondary: string;
    colorExtra: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ColorPalettesService {
  public palettes: IColorPalette[] = [
    {
      name: 'Shuffll',
      value: {
        colorPrimary: '#9d8af9',
        colorSecondary: '#141414',
        colorExtra: '#98ce00',
      },
    },
    {
      name: 'Soft Pinks',
      value: {
        colorPrimary: '#f67280',
        colorSecondary: '#f8b195',
        colorExtra: '#c06c84',
      },
    },
    {
      name: 'Pastel',
      value: {
        colorPrimary: '#99b898',
        colorSecondary: '#feceab',
        colorExtra: '#ff847c',
      },
    },
    {
      name: 'Arctic',
      value: {
        colorPrimary: '#6eb5c0',
        colorSecondary: '#006c84',
        colorExtra: '#e2e8e4',
      },
    },
    {
      name: 'Exotic',
      value: {
        colorPrimary: '#f55449',
        colorSecondary: '#0f1f38',
        colorExtra: '#1b4b5a',
      },
    },
    {
      name: 'Blues',
      value: {
        colorPrimary: '#003b46',
        colorSecondary: '#07575b',
        colorExtra: '#66a5ad',
      },
    },
    {
      name: 'Dramatic',
      value: {
        colorPrimary: '#262f34',
        colorSecondary: '#f34a4a',
        colorExtra: '#f1d3bc',
      },
    },
  ];

  constructor() {}

  // colors={colorPrimary:}
}
