<div
  mat-icon-button
  class="profile-image-wrapper"
  [class.muted]="participant?.stageOptions.stageMicEnabled === false"
>
  <video
    class="participant-video"
    *ngIf="participant.peer.screenStream"
    [muted]="
      participant.stageOptions.stageMicEnabled === false ||
      participant.peer.id === profile.userPeer.id
        ? 'muted'
        : false
    "
    [srcObject]="participant?.peer.screenStream"
    autoplay
    playsinline
  ></video>
</div>
