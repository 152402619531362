<h2 mat-dialog-title>New prompter text published</h2>
<div mat-dialog-content>
  Got a propmter text update from the host. Do you want to preview it?
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Apply</button>
</div>


