export class MissingMediaDeviceIdError extends Error {
    constructor(message: string) {
      super(message);
      this.name = 'MissingMediaDeviceIdError';
    }
  }


  export class NoDevicePermissionsError extends Error {
    constructor(message: string) {
      super(message);
      this.name = 'NoDevicePermissionsError';
    }
  }