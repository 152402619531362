<div class="video-container">
  <vg-player #vgPlayer (onPlayerReady)="onPlayerReady($event)">
    <video #media controls preload="auto" [vgDash]="baseCdnUrl + dynamicUrl" [vgMedia]="media"></video>
  </vg-player>
</div>

<div class="thumbnails-container">
  <button (click)="generateThumbnailsInterval(2, 300, 150)">Generate Thumbnails Every 5 Seconds</button>

  <div *ngFor="let thumbnailDataURL of thumbnailDataURLs" class="thumbnail"
       [style.background-image]="'url(' + thumbnailDataURL + ')'">
  </div>
</div>
