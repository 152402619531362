<div
  mat-button
  *ngIf="!dummy && (this.videoPath$ | async) as videoPath"
  (click)="openVideoSelectorDialog()"
  class="full-width-height video-wrapper"
>
  <app-video-player
    [videoSources]="videoPath"
    [videoId]="'singleVideo'"
    (vgApiEmitter)="onPlayerReady($event)"
  >
  </app-video-player>
  <mat-icon class="library-icon-small">video_library</mat-icon>
</div>

<div
  *ngIf="dummy"
  mat-button
  (click)="openVideoSelectorDialog()"
  class="full-width-height selected-video"
>
  <mat-icon class="library-icon">video_library</mat-icon>
</div>
