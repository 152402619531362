import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigatorDevicesService } from 'src/app/services/recording/navigator-devices.service';
import {
  SnackBarService,
  SnackbarActionEnum,
} from '../../../services/utils/snack-bar.service';
import {
  MediaDevicesService,
  CustomMediaDeviceInfo,
} from '../../../services/recording/media-devices.service';
import { Subject, takeUntil, combineLatest } from 'rxjs';
import { MissingMediaDeviceIdError } from 'src/app/models/errors/media-devices.errors';
import { ProfileService } from '../../../services/show/profile.service';
import { RecordingManagerService } from '../../../services/studio/recording-manager.service';

/**
 * A component that allows the user to select a camera and microphone for media recording.
 */
@Component({
  selector: 'app-user-media-selector',
  templateUrl: './user-media-selector.component.html',
  styleUrls: ['./user-media-selector.component.scss'],
})
export class UserMediaSelectorComponent implements OnInit, OnDestroy {
  /**
   * The currently selected video device.
   */
  selectedVideoDevice: CustomMediaDeviceInfo;

  /**
   * The currently selected audio device.
   */
  selectedAudioDevice: CustomMediaDeviceInfo;

  /**
   * The local camera stream for preview.
   */
  previewLocalCamera: MediaStream;

  /**
   * Observable stream of available cameras.
   */
  cameras$ = this.mediaDevicesService.cameras$;

  /**
   * Observable stream of available microphones.
   */
  microphones$ = this.mediaDevicesService.microphones$;

  /**
   * Subject used for unsubscribing from observables.
   */
  private destroy$ = new Subject<void>();

  /**
   * Observable stream indicating whether device permissions are granted.
   */
  public devicesPermissions$ = this.navigatorDevicesService.devicesPermissions$;

  constructor(
    private navigatorDevicesService: NavigatorDevicesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserMediaSelectorComponent>,
    private snackBarService: SnackBarService,
    public mediaDevicesService: MediaDevicesService,
    private cdr: ChangeDetectorRef,
    public profileService: ProfileService,
    public recordingManager: RecordingManagerService
  ) {
    this.dialogRef.disableClose = true;
  }

  /**
   * Initializes the component and subscribes to device permission changes and selected device changes.
   */
  ngOnInit() {
    combineLatest([
      this.navigatorDevicesService.devicesPermissions$,
      this.mediaDevicesService.selectedCameraId$,
      this.mediaDevicesService.selectedMicrophoneId$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        async ([hasPermissions, selectedCameraId, selectedMicrophoneId]) => {
          if (
            !hasPermissions ||
            (selectedCameraId === null && selectedMicrophoneId === null)
          ) {
            this.selectedVideoDevice = null;
            this.selectedAudioDevice = null;
            return;
          }
          console.log('selectedCameraId', this.selectedVideoDevice?.deviceId);
          console.log(
            'selectedMicrophoneId',
            this.selectedAudioDevice?.deviceId
          );

          if (
            selectedCameraId &&
            selectedCameraId !== this.selectedVideoDevice?.deviceId
          ) {
            this.selectedVideoDevice =
              this.mediaDevicesService.findCameraById(selectedCameraId);
            await this.updatePreviewStreamAsync();
          }
          if (
            selectedMicrophoneId &&
            selectedMicrophoneId !== this.selectedAudioDevice?.deviceId
          ) {
            this.selectedAudioDevice =
              this.mediaDevicesService.findMicrophoneById(selectedMicrophoneId);
            await this.updatePreviewStreamAsync();
          }
        }
      );
  }

  /**
   * Cleans up resources when the component is destroyed.
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.stopTracks();
  }

  /**
   * Handles the selection of a video device.
   * @param ev The selected device information.
   */
  selectVideoDevice(ev: MediaDeviceInfo) {
    console.log(ev);
    const deviceId = ev.deviceId;
    this.mediaDevicesService.selectCamera(deviceId);
  }

  /**
   * Handles the selection of an audio device.
   * @param ev The selected device information.
   */
  selectAudioDevice(ev: MediaDeviceInfo) {
    const deviceId = ev.deviceId;
    this.mediaDevicesService.selectMicrophone(deviceId);
  }

  /**
   * Closes the dialog if a camera and microphone are selected.
   */
  closeDialog() {
    if (!this.selectedVideoDevice || !this.selectedAudioDevice) {
      this.snackBarService.openMessage(
        'Please select a camera and microphone before closing',
        SnackbarActionEnum.Close,
        5000
      );
      return;
    }

    this.dialogRef.close();
  }

  /**
   * Displays an error message to the user.
   * @param message The error message to display.
   */
  private handleErrorMessage(message: string) {
    this.snackBarService.openMessage(message, SnackbarActionEnum.Close, 5000);
  }

  /**
   * Updates the preview stream by stopping tracks of the current local camera and microphone,
   * then retrieves a new media stream using the mediaDevicesService.
   * Subscribes to the media stream and assigns the stream to previewLocalCamera.
   */
  async updatePreviewStreamAsync() {
    this.stopTracks();

    try {
      // const stream = await this.mediaDevicesService.getMediaStreamAsync();
      // this.previewLocalCamera = stream;
    } catch (error) {
      let errorMessage =
        "Oops! Something went wrong with your camera and microphone. But don't worry, we'll get it fixed! 🛠️";

      if (error.name === 'SecurityError') {
        errorMessage =
          "Hey there, it looks like you're playing hard to get! 😉 Our app needs access to your camera and microphone to make the magic happen. Could you please give us a hand with that?";
      } else if (error.name === 'AbortError') {
        errorMessage =
          "Whoa, that was close! It seems like you got cold feet when we asked for camera and microphone access. No worries, we'll give you another chance to show us your best smile! 😁";
      } else if (error instanceof MissingMediaDeviceIdError) {
        errorMessage = 'Please select devices first. Then, try again. 😁';
      }
      this.snackBarService.openMessage(
        errorMessage,
        SnackbarActionEnum.Close,
        5000
      );
    }
  }

  /**
   * Stops the tracks of the local camera and microphone.
   */
  stopTracks() {
    if (this.previewLocalCamera) {
      this.previewLocalCamera.getTracks().forEach((track) => track.stop());
    }
  }

  protected readonly event = event;
}
