import { Injectable } from '@angular/core';
import { TakeEventsService } from '../../../../socket/project/take/take-events.service';
import { ITakeEventData } from '../../../../../models/socket-events/project/take/socket-take-events';

@Injectable()
export class TakeSocketEventEmitterService {
    constructor(private takeEventService: TakeEventsService) {}

    public notifyTakeEnded(data: ITakeEventData) {
        return this.takeEventService.notifyEndedTake(data);
    }
}
