import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  GET_ALL_WALKTHROUGH_TASKS,
  UPDATE_COMPLETED_TASK,
} from '../constants/private/urls/configs.urls';
import {
  ITask,
  ITaskConfigs,
  IWalkthorugh,
  taskLinksMap,
  TaskTypeEnum,
} from '../models/walkthrough';
import { AnalyticsNotifierService } from './utils/analytics-notifier.service';

@Injectable({
  providedIn: 'root',
})
export class WalkthroughPanelService {
  public static LOCALSTORAGE_KEY = 'hide-walkthrough-panel';
  public tasksCompleted$ = new BehaviorSubject<TaskTypeEnum>(null);

  //This subject for "SETUP_DEVICES" & "PRODUCTION" tasks, as the "STUDIO" button task depeneds on them
  public specialTaskCompleted$ = new BehaviorSubject<TaskTypeEnum>(null);
  public prodAndDeviceTaskMap = new Map<TaskTypeEnum, ITaskConfigs>([
    [TaskTypeEnum.SETUP_DEVICES, taskLinksMap.get(TaskTypeEnum.SETUP_DEVICES)],
    [TaskTypeEnum.PRODUCTION, taskLinksMap.get(TaskTypeEnum.PRODUCTION)],
  ]);
  public prodAndDeviceTasksCompleted$ = new BehaviorSubject<boolean>(false);
  taskLinksMap: Map<TaskTypeEnum, ITaskConfigs> = taskLinksMap;
  private walkthroughHidden = new BehaviorSubject<boolean>(true);
  public walkthroughHidden$ = this.walkthroughHidden.asObservable();

  constructor(
    private http: HttpClient,
    private gtmService: AnalyticsNotifierService
  ) {}

  public getWalkthroughData(): Observable<IWalkthorugh> {
    return this.http.get<IWalkthorugh>(GET_ALL_WALKTHROUGH_TASKS);
  }

  public updateCompletedTask(taskId: string, isCompletedTask: boolean) {
    return this.http.put(UPDATE_COMPLETED_TASK, {
      taskId: taskId,
      isTaskCompleted: isCompletedTask,
    });
  }

  public closeWalkthrough(toClose: boolean): void {
    if (toClose) {
      localStorage.setItem(WalkthroughPanelService.LOCALSTORAGE_KEY, 'true');
    }
    this.walkthroughHidden.next(true);
  }

  public openWalkthrough(toOpen: boolean): void {
    if (toOpen) {
      localStorage.setItem(WalkthroughPanelService.LOCALSTORAGE_KEY, 'false');
    }
    this.gtmService.pushTag({
      event: 'Open Walkthrough',
    });
    this.walkthroughHidden.next(false);
  }

  public setTaskState(task: ITask, isCompleted: boolean) {
    task.isCompleted = isCompleted;
    task.configs.isCompleted = isCompleted;
  }

  public checkIfTaskCompleted(taskCategory: TaskTypeEnum): boolean {
    const task = this.taskLinksMap.get(taskCategory);
    if (task.isCompleted) return true;
    else return false;
  }

  public completeDeviceOrProductionTask() {
    let canDisplayRecordText: boolean = true;
    for (const value of this.prodAndDeviceTaskMap.values()) {
      if (!value) {
        canDisplayRecordText = false;
        break;
      }
    }
    if (canDisplayRecordText) {
      this.prodAndDeviceTasksCompleted$.next(true);
    }
  }
}
