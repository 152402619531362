import {Directive, OnInit, OnDestroy, ViewContainerRef, EventEmitter, Input} from '@angular/core';

/**
 * Lifecycle directive that properly destroys lottie animations upon destruction of lottie-animation-view components
 */
@Directive({selector: '[tiLottieCleanup]'})
export class LottieCleanupDirective implements OnInit, OnDestroy {

  lottieAnimations = [];

  @Input()
  lottieName: string = '';

  constructor(private vcRef: ViewContainerRef) {}

  ngOnInit() {
    if (this.vcRef['_data'] && this.vcRef['_data'].componentView) {
      const component = this.vcRef['_data'].componentView.component;
      if (component && component.animCreated instanceof EventEmitter) {
        component.animCreated.subscribe((lottieAnimation) => {
          this.lottieAnimations.push(lottieAnimation);
        });
      }
    }
  }

  ngOnDestroy() {
    console.log(`About to destroy ${this.lottieAnimations.length} lottie animation(s) ...`);

    for (const lottieAnimation of this.lottieAnimations) {
      try {
        lottieAnimation.destroy();
      } catch (err) {
        console.log('Failed destroying lottie animation', err);
      }
    }
    this.lottieAnimations.length = 0;
  }
}