import { Injectable, NgZone } from '@angular/core';
import JASSUB, { JassubOptions } from 'jassub';

@Injectable({
  providedIn: 'root',
})
export class JassubService {
  private jassubInstance: JASSUB;
  private WORKER_URL = '/assets/js/jassub/jassub-worker.js';
  private testRegularFont =
    'https://content.shuffll.com/files/test/test-regular.ttf';
  private testFont = 'https://content.shuffll.com/files/test/test-font.ttf';

  /// Doing a map of jassub instances to render at the same time few subtitles with different text / options
  private jassubMap = new Map<string, JASSUB>();
  constructor(private ngZone: NgZone) {}
  availableFonts: Record<string, string> = {
    'Edu VIC WA NT Beginner': this.testFont,
  };
  initializeCanvasJassub(
    canvasElement: HTMLCanvasElement,
    videoElement: HTMLVideoElement,
    uniqueId: string,
    fonts: string[],
    scaleFactor: number,
    subtitles?: string,
    subtitleMode: 'url' | 'content' = 'url'
  ) {
    const options: JassubOptions = {
      canvas: canvasElement,
      video: videoElement,
      workerUrl: this.WORKER_URL,
      fonts: fonts ?? [],
      prescaleFactor: scaleFactor,
      // fonts: [this.testFont, this.testRegularFont],
      // availableFonts: this.availableFonts,
    };

    if (subtitleMode === 'content') {
      options.subContent = subtitles ?? '';
    } else if (subtitleMode === 'url' && subtitles) {
      options.subUrl = subtitles;
    }

    try {
      this.ngZone.runOutsideAngular(() => {
        const jassubInstance = new JASSUB(options);
        this.jassubMap.set(uniqueId, jassubInstance);
      });
      console.log('JASSUB initialized.');
    } catch (error) {
      console.error('Error initializing JASSUB:', error);
    }
  }

  updateSubtitlesWithContent(newSubContent: string, uniqueId: string): void {
    const jassub = this.jassubMap.get(uniqueId);
    if (!jassub) {
      console.error(
        `Could not update subtitles because no jassub instance was found`
      );
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      jassub.setTrack(newSubContent);
    });
  }

  addFontToJassub(fontUrls: string[], uniqueId: string) {
    const jassub = this.jassubMap.get(uniqueId);
    if (!jassub) {
      console.error(
        `Could not update fonts because no jassub instance was found`
      );
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      for (const font of fontUrls) {
        jassub.addFont(font);
      }
    });
  }

  async setTrackByUrl(url: string): Promise<void> {
    if (this.jassubInstance) {
      try {
        console.log(`Setting track by URL: ${url}`);
        this.ngZone.runOutsideAngular(() =>
          this.jassubInstance.setTrackByUrl(url)
        );
        console.log('Track set successfully.');
      } catch (error) {
        console.error('Failed to set track by URL:', error);
      }
    } else {
      console.error('JASSUB instance is not initialized.');
    }
  }

  destroyJassubtInstance(id: string) {
    if (!this.jassubMap.has(id)) return;

    this.jassubMap.get(id).destroy();
    this.jassubMap.delete(id);
  }

  async changePlayPause(isPaused: boolean): Promise<void> {
    if (!this.jassubInstance)
      this.ngZone.runOutsideAngular(() => {
        this.jassubInstance.setIsPaused(isPaused);
      });
  }

  async setTrackByContent(content: string): Promise<void> {
    if (this.jassubInstance) {
      try {
        console.log(`Setting track by content: ${content}`);
        this.ngZone.runOutsideAngular(() => {
          this.jassubInstance.setTrack(content);
        });
        console.log('Track set successfully.');
      } catch (error) {
        console.error('Failed to set track by URL:', error);
      }
    } else {
      console.error('JASSUB instance is not initialized.');
    }
  }

  destroy(): void {
    if (this.jassubInstance) {
      this.ngZone.runOutsideAngular(() => {
        this.jassubInstance.destroy();
      });
      console.log('JASSUB instance destroyed.');
    }
  }
}
