<div class="gradient-component">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div
          class="gradient-preview"
          [style.background]="getGradientStyle()"
          mat-button
        ></div>
      </mat-panel-title>
      <!--      <mat-panel-description>-->
      <!--        This is a summary of the content-->
      <!--      </mat-panel-description>-->
    </mat-expansion-panel-header>

    <div class="pickers">
      <div
        class="color-picker-wrapper"
        *ngFor="let color of colors; let i = index"
      >
        <div
          class="color-picker-container"
          [(colorPicker)]="color.color"
          [style.background]="color.color"
          (colorPickerChange)="onColorChange($event)"
          [cpOutputFormat]="'rgba'"
          [cpEyeDropper]="true"
          [cpDialogDisplay]="'popup'"
          >
          <!-- [colorPickerRgba]="color.color"
          [dynamicColor]="color" -->
      </div>
        <input
          type="number"
          id="numberInput"
          [(ngModel)]="color.position"
          (change)="validatePosition(color)"
          min="0"
          max="100"
        />
        <button
          mat-icon-button
          color="primary"
          (click)="$event.stopPropagation(); removeColor(i)"
          [disabled]="colors?.length <= 1"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </div>
    </div>
    <button mat-icon-button color="primary" (click)="addColor()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-expansion-panel>
</div>
