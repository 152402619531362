<div class="icon-image-wrapper">
  <img src="/assets/images/icons/mobile-cute.svg">
</div>
<h1>It's not your phone, it's us!</h1>
<div>
  <p style="text-align: center">
    You are using a demo version of Shuffll.<br>
    The demo version is optimized for large screens.<br>
    <br>
    To watch the full version contact us: <br>
    <a href="mailto:info@shuffll.com">info&#64;shuffll.com</a>
  </p>
</div>

