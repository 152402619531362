import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage-wp',
  templateUrl: './homepage-wp.component.html',
  styleUrls: ['./homepage-wp.component.scss']
})
export class HomepageWpComponent implements OnInit {

  constructor(private router:Router ) { }

  ngOnInit(): void {
      this.router.navigate(['dashboard'])
  }

}
