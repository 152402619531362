import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { IPathAndDash } from 'src/app/models/defines';
import { ConfigurationService } from 'src/app/services/configuration.service';

enum SourceTypeEnum {
  MP4 = 'video/mp4',
  DASH = 'application/dash+xml',
}

interface ISourceAndType {
  source: string;
  type: SourceTypeEnum;
}

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnChanges {
  @Input() videoSources: IPathAndDash;
  @Input() videoId: string;
  @Output() vgApiEmitter = new EventEmitter<VgApiService>();
  @Input() displayControls: boolean = false;

  vgApi: VgApiService;
  sourceAndType: ISourceAndType = null;
  constructor(private config: ConfigurationService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.videoSources) {
      this.sourceAndType = this.getSourceAndType();
    }
  }

  getSourceAndType(): ISourceAndType {
    let sourceAndType: ISourceAndType = null;
    if (this.videoSources.dashPath) {
      sourceAndType = {
        source: this.videoSources.dashPath,
        type: SourceTypeEnum.DASH,
      };
    } else if (this.videoSources.uploadPath) {
      sourceAndType = {
        source: this.videoSources.uploadPath,
        type: SourceTypeEnum.MP4,
      };
    }

    if (sourceAndType) {
      sourceAndType.source =
        this.config.baseCdnUrl + decodeURIComponent(sourceAndType.source);
    }
    return sourceAndType;
  }

  onPlayerReady(vgApi: VgApiService) {
    this.vgApi = vgApi;
    this.vgApiEmitter.emit(vgApi);
  }
}
